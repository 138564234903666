import { titleize } from '../../../utils/stringUtils'
import { statesInfoNoTerritories } from '../../../utils/constants'

const lowerCaseStateCodes = () => Object.keys(statesInfoNoTerritories).map((stateCode) => stateCode.toLowerCase())

export const SUPPORTED_FILE_FORMATS = ['.csv', 'text/x-csv', 'application/csv', 'application/x-csv', 'text/csv', 'text/comma-separated-values', 'text/x-comma-separated-values']

const validOptions = {
  sufficient_internet: ['yes', 'no', 'true', 'false', ''],
  device: ['yes', 'no', 'true', 'false', ''],
  state: lowerCaseStateCodes()
}

function hasMissingFields (error, student) {
  const notNullFields = ['street_address', 'primary_id', 'city', 'state', 'zip']
  notNullFields.forEach((field) => {
    if (!student[field]) {
      const formattedField = titleize(field)
      error.push(`${formattedField}: field missing`)
    }
  })
}

function invalidOptions (error, student) {
  const invalidFields = Object.keys(validOptions)
  invalidFields.forEach((field) => {
    if (student[field] && !validOptions[field].includes(student[field].toString().trim().toLowerCase())) {
      const formattedField = titleize(field)
      error.push(`${formattedField}: '${student[field]}' is not a valid option`)
    }
  })
}

function matchZip (error, student) {
  if (!student.zip) return
  if (!student.zip.toString().trim().match(/^\d{5}(?:[-\s]\d{4})?$/)) {
    error.push(`ZIP: '${student.zip}' doesn't match pattern 00000 or 00000-0000 or 00000 0000`)
  }
}

function matchStreetAddress (error, student) {
  if (!student.street_address) return
  if (!student.street_address.toString().trim().match(/^\d.*\s\w.*$/)) {
    error.push(`Street Address: '${student.street_address}' doesn't match pattern [Number] [Name]`)
  }
}

export const findErrors = ({ student }) => {
  const error = []
  hasMissingFields(error, student)
  invalidOptions(error, student)
  matchZip(error, student)
  matchStreetAddress(error, student)
  return error.join(', ')
}
