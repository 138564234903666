import React from 'react'
import Typography from '@material-ui/core/Typography'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import useMediaQuery from '@material-ui/core/useMediaQuery'

export default function CSVUploadProcessingPage () {
  document.title = 'Upload Processing'
  const theme = useTheme()
  const belowMdBreakPoint = useMediaQuery(theme.breakpoints.down('md'))
  const aboveLgBreakpoint = useMediaQuery(theme.breakpoints.up('xl'))
  const useStyles = makeStyles((theme) => ({
    root: {
      margin: theme.spacing(5),
      height: (aboveLgBreakpoint || belowMdBreakPoint) ? '80vh' : '50vh'
    },
    title: {
      marginBottom: theme.spacing(3),
      marginTop: theme.spacing(3),
      paddingTop: theme.spacing(5)
    }
  }))
  const classes = useStyles()
  return (
    <div style={{ 'minHeight': '75vh' }}>
      <Box className={classes.root}>
        <Typography variant={belowMdBreakPoint ? 'h5' : 'h4'} className={classes.title}>Thank you. Your data is being processed.</Typography>
        <Typography variant="body1">
          Your data has been uploaded and we are processing the addresses, and generating the list of service providers who can serve each home according to publicly available FCC data.
        </Typography>
        <br />
        <Typography variant="body1">
          We&apos;ll email you for clarifications or once all the processing is complete. This usually takes up to 2 hours (depending on the size of the file).
        </Typography>
        <br />
        <Typography variant="body1">
          Feel free to navigate away from this page or close the browser.
        </Typography>
      </Box>
    </div>
  )
}
