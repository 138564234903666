import React from 'react'
import { useLocation } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import {
  Typography,
  Dialog,
  IconButton,
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent
} from '@material-ui/core'
import { useSelector } from 'react-redux'
import EditDataTabs from '../pageDecorations/editDataTabs'
import { selectUserInfo } from '../../redux/selectors'
import { isDemo } from '../../utils/routes'

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    width: 800
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
})

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    width: 800
  }
}))(MuiDialogContent)

export default function EditDataDialog (props) {
  const { setOpen, open, entityName, entityNumStudents } = props
  const userInfo = useSelector(selectUserInfo)
  const location = useLocation()
  const demo = isDemo(location)

  if (userInfo.isStateExceptionUser || !userInfo.accessToken || demo) return null
  return (
    <Dialog maxWidth={false} onClose={() => setOpen(false)} aria-labelledby="modal-to-edit-student-information" open={open}>
      <DialogTitle id="modal-to-edit-student-information" onClose={() => setOpen(false)}>
        Edit Student Data:
        {' '}
        {entityName}
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1">
          Use
          {' '}
          <strong>Bulk Edit</strong>
          {' '}
          or
          {' '}
          <strong>Direct Edit</strong>
          {' '}
          to update the connectivity and
          device status for your students. As you edit the data, it will be reflected in the map.
        </Typography>
        <br />
        <EditDataTabs entityName={entityName} accessToken={userInfo.accessToken} entityNumStudents={entityNumStudents} />
      </DialogContent>
    </Dialog>
  )
}
