import React, { useState } from 'react'
import { Box, Typography } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Grid from '@material-ui/core/Grid'
import UnconnectedToggle from './unconnectedToggle'
import ClearAllButton from './ClearAllButton'
import StudentsServed from './studentsServed'
import ProvidersDropdown from './ProvidersDropdown'

const setAllMapLayerIds = ({ providers }) => {
  if (!providers) return null
  const layerIDS = ['clusters', 'cluster-count', 'unclustered-point']
  const providerLayerIDS = providers.flatMap((provider) => {
    const serviceProviderID = provider.service_provider_id
    return [`clusters-${serviceProviderID}`, `cluster-count-${serviceProviderID}`, `unclustered-point-${serviceProviderID}`]
  })
  return [...layerIDS, ...providerLayerIDS]
}

export default function Filters ({ providers, map, studentCounts, dataExchange }) {
  const [selectedProvider, onSelectedProvider] = useState()
  const [unconnectedNoInternetChecked, setUnconnectedNoInternetChecked] = useState(false)
  const layerIds = setAllMapLayerIds({ providers })
  const unconnectedNoInternetStudents = parseInt(studentCounts?.num_unconnected_students, 10)
  const theme = useTheme()
  const aboveSmallBreakpoint = useMediaQuery(theme.breakpoints.up('sm'))
  const useStyles = makeStyles((theme) => ({
    filter: {
      background: theme.palette.primary.main,
      position: 'absolute',
      zIndex: theme.zIndex.mobileStepper,
      maxWidth: 350,
      padding: theme.spacing(1),
      top: theme.spacing(1),
      left: aboveSmallBreakpoint ? 'auto' : theme.spacing(1),
      right: theme.spacing(1),
      borderRadius: 8,
      boxShadow: '0 1px 4px rgb(0 0 0 / 20%)'
    },
    mapTitle: {
      fontSize: '0.8rem',
      margin: '12px',
      fontWeight: '600'
    }
  }))
  const classes = useStyles()

  return (
    <Box className={classes.filter}>
      { dataExchange && (
      <Grid container spacing={2} justifyContent="center">
        <Typography className={classes.mapTitle}>
          MAP SHOWS UNCONNECTED STUDENTS ONLY
        </Typography>
      </Grid>
      )}
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <ProvidersDropdown layerIds={layerIds} providers={providers} selectedProvider={selectedProvider} onSelectedProvider={onSelectedProvider} map={map} setUnconnectedNoInternetChecked={setUnconnectedNoInternetChecked} />
        </Grid>
        <StudentsServed selectedProvider={selectedProvider} studentCounts={studentCounts} />
        { dataExchange && (
        <Grid item>
          <UnconnectedToggle
            map={map}
            selectedProvider={selectedProvider}
            checked={unconnectedNoInternetChecked}
            setChecked={setUnconnectedNoInternetChecked}
            unconnectedNoInternetStudents={unconnectedNoInternetStudents}
          />
        </Grid>
        )}
        <Grid item>
          <ClearAllButton
            layerIds={layerIds}
            map={map}
            onSelectedProvider={onSelectedProvider}
            selectedProvider={selectedProvider}
          />
        </Grid>
      </Grid>
    </Box>
  )
}
