import React, { useState, useEffect, useRef } from 'react'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import MapboxSetup from './mapboxSetup'
import Filters from '../filters'

const useStyles = makeStyles((theme) => ({
  map: {
    margin: theme.spacing(5, 0)
  }
}))

export default function Map ({ entity, program }) {
  const [map, setMap] = useState(null)
  const mapContainer = useRef(null)
  useEffect(() => {
    if (entity) {
      const { center, defaultZoom, geojsonURL, geojsonUnconnectedURL, providers, studentCounts } = entity
      const studentAddressCount = studentCounts?.[0]?.num_students
      MapboxSetup({ mapContainer, center, defaultZoom, providers, geojsonURL, geojsonUnconnectedURL, studentAddressCount, setMap })
    }
  }, [entity])

  const classes = useStyles()
  if (!entity) return null
  /* eslint no-return-assign: "off" */
  const dataExchange = program === 'data_exchange'
  const providersInFilter = (dataExchange ? entity.providers.filter((provider) => !provider.form477) : entity.providers)
  return (
    <Grid className={classes.map} item xs={12}>
      <Paper ref={(el) => (mapContainer.current = el)} style={{ height: 900 }}>
        <Filters
          map={map}
          providers={providersInFilter}
          studentCounts={entity.studentCounts[0]}
          dataExchange={dataExchange}
        />
      </Paper>
    </Grid>
  )
}
