import React from 'react'
import { Route, Switch } from 'react-router-dom'
import AdminDashboardPage from '../pages/adminDashboardPage'
import EntityPage from '../pages/entityPage'
import UserLandingPageConnected from '../pages/userLandingPage'
import ProfilePagePageConnected from '../pages/profilePage'
import DemoPage from '../pages/demoPage'
import CSVUploadProcessingPage from '../pages/csvUploadProcessingPage'
import PageNotFound404 from '../pages/pageNotFound404'
import PrivateRoute from './privateRoute'
import CSVUploader from '../csv/csvUploader'
import DataExchangeLandingPageConnected from '../pages/dataExchangePages'
import Step1 from '../pages/dataExchangePages/Step1'
import Step2 from '../pages/dataExchangePages/Step2'
import Step3 from '../pages/dataExchangePages/Step3'

export default function Routes () {
  return (
    <Switch>
      <Route exact path="/">
        <UserLandingPageConnected />
      </Route>
      <Route exact path="/demo">
        <DemoPage />
      </Route>
      <PrivateRoute exact path="/upload">
        <CSVUploader />
      </PrivateRoute>
      <PrivateRoute exact path="/profile">
        <ProfilePagePageConnected />
      </PrivateRoute>
      <PrivateRoute exact path="/dataExchange">
        <DataExchangeLandingPageConnected />
      </PrivateRoute>
      <PrivateRoute exact path="/dataExchange/step1">
        <Step1 />
      </PrivateRoute>
      <PrivateRoute exact path="/dataExchange/step2">
        <Step2 />
      </PrivateRoute>
      <PrivateRoute exact path="/dataExchange/step3">
        <Step3 />
      </PrivateRoute>
      <PrivateRoute exact path="/uploadProcessing">
        <CSVUploadProcessingPage />
      </PrivateRoute>
      <PrivateRoute exact path="/entities/:entityUID">
        <EntityPage />
      </PrivateRoute>
      <PrivateRoute exact path="/admin">
        <AdminDashboardPage />
      </PrivateRoute>
      <PrivateRoute exact path="/dataExchange/signUp" />
      <PrivateRoute exact path="/dataExchange/login" />
      <Route exact path="*" render={() => <PageNotFound404 />} />
    </Switch>
  )
}
