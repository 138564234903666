import React from 'react'
import { FormGroup, FormControlLabel, Switch, Typography, Box } from '@material-ui/core'
import { formatNumberForDisplay } from '../../utils/stringUtils'

export default function UnconnectedToggle ({ map, selectedProvider, checked, setChecked, unconnectedNoInternetStudents }) {
  const handleChange = (event) => {
    const layerIds = ['clusters', 'cluster-count', 'unclustered-point']
    map.setLayoutProperty('unconnected-point', 'visibility', checked ? 'none' : 'visible')
    layerIds.forEach((layerID) => {
      map.setLayoutProperty(
        layerID,
        'visibility',
        checked ? 'visible' : 'none'
      )
    })
    setChecked(event.target.checked)
  };
  // only show toggle when no provider has been selected
  if (!selectedProvider) {
    return (
      <FormGroup>
        <FormControlLabel
          control={(
            <Switch
              checked={checked}
              onChange={handleChange}
            />
          )}
          label={(
            <Typography variant="body1" color="inherit">
              Show
              <Box component="strong">
                {' '}
                {formatNumberForDisplay(unconnectedNoInternetStudents)}
                {' '}
              </Box>
              Unconnected No Internet Available Students
            </Typography>
          )}
        />
      </FormGroup>
    )
  }
  return null
}
