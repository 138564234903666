import React from 'react'
import pluralize from 'pluralize'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import { formatNumber, formatNumberForDisplay } from '../../../utils/stringUtils'

export default function StudentsServed ({ selectedProvider, studentCounts }) {
  if (!studentCounts) return null
  const { survey_insufficient_internet: insufficientValue, survey_no_device: deviceValue, num_unconnected_students: numUnconnectedStudents } = studentCounts
  const outofValue = studentCounts.num_internet_available_students ? (
    <>
      {' '}
      out of
      <Box component="strong">
        {' '}
        {formatNumberForDisplay(studentCounts.num_internet_available_students)}
        {' '}
      </Box>
    </>
  ) : ' '
  return (
    <Grid container item spacing={1} direction="column">
      <Grid item>
        {selectedProvider
          ? (
            <Typography variant="body1" color="inherit">
              <Box component="strong">
                {' '}
                {formatNumber(selectedProvider.number_of_students_served)}
                {' '}
              </Box>
              {pluralize(`${numUnconnectedStudents ? 'unconnected ' : ''}student`, selectedProvider.number_of_students_served)}
              {' '}
              can be served by
              {' '}
              <Box component="strong">
                {selectedProvider.doing_business_as}
                .
                {' '}
              </Box>
            </Typography>
          )
          : (
            <Typography variant="body1" color="inherit">
              Select a provider to see how many students
              {outofValue}
              can be served.
            </Typography>
          )}
      </Grid>
      {insufficientValue > 0 && (
      <Grid item container>
        <Grid item xs={1}>
          <FiberManualRecordIcon style={{ color: '#d63447' }} />
        </Grid>
        <Grid>
          <Typography xs={11} variant="body1" color="inherit">
            Insufficient Internet:
            {' '}
            <strong>{`${formatNumberForDisplay(insufficientValue)}`}</strong>
            {' '}
            <span style={{ fontSize: '0.9rem' }}><strong>students</strong></span>
          </Typography>
        </Grid>
      </Grid>
      )}
      {deviceValue > 0 && (
      <Grid item container>
        <Grid item xs={1}>
          <FiberManualRecordIcon style={{ color: '#158acf' }} />
        </Grid>
        <Grid>
          <Typography xs={11} variant="body1" color="inherit">
            No Device:
            {' '}
            <strong>{`${formatNumberForDisplay(deviceValue)}`}</strong>
            {' '}
            <span style={{ fontSize: '0.9rem' }}><strong>students</strong></span>
          </Typography>
        </Grid>
      </Grid>
      )}
    </Grid>
  )
}
