/* eslint-disable max-depth */
import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { statesInfoNoTerritories } from '../../../utils/constants'
import StateSelectionAndTermsDialog from '../../dialogs/StateSelectionAndTermsDialog'

const useStyles = makeStyles((theme) => ({
  button: {
    background: theme.palette.secondary.main,
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    marginTop: theme.spacing(3),
    borderRadius: '5px',
    display: 'block',
    '&:hover': {
      backgroundColor: theme.palette.info.active,
    }
  }
}))

const displayUserState = (usersStateCode) => (
  <span>
    {usersStateCode ? statesInfoNoTerritories[usersStateCode] : 'Not set. We need to know your state to configure some access settings.'}
  </span>
)

export default function StateSelection ({ usersStateCode, userId, isModalOpen, accessToken, agreedToTerms }) {
  const [open, setOpen] = useState(false)
  const classes = useStyles()

  useEffect(() => {
    setOpen(isModalOpen)
  }, [isModalOpen])

  return (
    <>
      <Typography variant="body1">
        <strong>State:</strong>
        <span>
          {' '}
          {displayUserState(usersStateCode)}
        </span>
        {usersStateCode && (
        <Button
          size="large"
          variant="contained"
          className={classes.button}
          onClick={() => setOpen(!open)}
        >
          CHANGE STATE
        </Button>
        )}
      </Typography>
      <StateSelectionAndTermsDialog open={open} usersStateCode={usersStateCode} userId={userId} setOpen={setOpen} accessToken={accessToken} agreedToTerms={agreedToTerms} />
    </>
  )
}
