import React from 'react'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import { useParams } from 'react-router-dom'
import { Typography, Box } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2)
  },
  button: {
    background: theme.palette.secondary.light
  }
}))

export default function BulkEdits () {
  const classes = useStyles()
  const { entityUID } = useParams()
  return (
    <Box className={classes.root}>
      <Typography variant="body1">
        To use
        {' '}
        <strong>Bulk Edit</strong>
        , make changes to the original csv file you uploaded, then upload the updated file using the Upload button.
        You can modify students&apos; device or connection status (and school/grade/teacher info).
      </Typography>
      <br />
      <Button
        href={`/upload?entityUID=${entityUID}`}
        size="large"
        variant="contained"
        className={classes.button}
      >
        Upload Updated File...
      </Button>
    </Box>
  )
}
