import React, { useState, useEffect } from 'react'
import LogRocket from 'logrocket'
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import _ from 'lodash'
import { searchEntitiesForTypeahead, upsertUserEntities } from '../../../data/fetchData'

export default function EntitiesTypeahead ({ entities, user, accessToken }) {
  const [entityOptions, setEntityOptions] = useState([])
  const classes = useStyles()
  const onChange = async (_event, selected) => {
    const entityUIDs = _.uniq(selected.filter((s) => (s && s.entity_uid) && s.entity_uid !== demoEntity.entity_uid).map((s) => s.entity_uid))
    if (!entityUIDs.length) return
    await upsertUserEntities(user.email, entityUIDs, accessToken)
  }
  useEffect(() => {
    const fetchData = async () => {
      try {
        const entities = await searchEntitiesForTypeahead(accessToken)
        setEntityOptions(entities)
      } catch (e) {
        if (process.env.REACT_APP_ENV === 'production') {
          LogRocket.captureException(e)
        }
      }
    }
    fetchData()
  }, [accessToken, setEntityOptions])
  if (!entityOptions.length) return <div>Loading entities...</div>
  const limitOfDisplayedOptions = 20
  const filterOptions = createFilterOptions({ limit: limitOfDisplayedOptions })

  return (
    <div className={classes.root}>
      <Autocomplete
        multiple
        id={`entity-tags-${user.user_id}`}
        options={entityOptions}
        filterOptions={filterOptions}
        getOptionLabel={(option) => (option.name ? `${option.name} (${option.state_code})` : '')}
        getOptionSelected={(option, value) => option && option.entity_uid === value.entity_uid}
        defaultValue={getDefaultValue(entities)}
        onChange={onChange}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            label="Associated entities"
            placeholder="Entity"
          />
        )}
      />
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: 600,
    '& > * + *': {
      marginTop: theme.spacing(3)
    }
  }
}))

const demoEntity = { entity_uid: '3c935b72-8a9a-45b1-b1c3-17868868858a', state_code: 'UK', name: 'Hogwarts' }

const getDefaultValue = (entities) => (entities && entities[0] && entities[0].entity_uid ? entities : [demoEntity])
