/* eslint-disable max-lines */
import React, { useState } from 'react'
import IconButton from '@material-ui/core/IconButton'
import Grid from '@material-ui/core/Grid'
import Avatar from '@material-ui/core/Avatar'
import Box from '@material-ui/core/Box'
import { ExpandMore as ExpandMoreIcon, ExpandLess as ExpandLessIcon } from '@material-ui/icons'
import { useSelector } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import Menu from '@material-ui/core/Menu'
import { Link } from 'react-router-dom'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemText from '@material-ui/core/ListItemText'
import isEmpty from 'lodash.isempty'
import isAdminOrStateAdmin from '../../../utils/adminHelpers'
import { selectUserInfo } from '../../../redux/selectors'
import { useAuth0 } from '../../../react-auth0-spa'

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
    color: 'black'
  }
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center'
    }}
    {...props}
  />
))

const StyledListItemText = withStyles((theme) => ({
  root: {
    color: theme.palette.primary.dark
  }
}))((props) => (
  <ListItemText {...props} />
))

export default function AccountDropdown () {
  const userInfo = useSelector(selectUserInfo)
  const { user, logout } = useAuth0()
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  if (isEmpty(user)) return null

  return (
    <Grid item>
      <Box onClick={handleClick}>
        <Grid container>
          <Grid>
            <Avatar alt="Remy Sharp" src={userInfo.picture} style={{ 'marginLeft': 16, 'cursor': 'pointer' }} />
          </Grid>
          <Grid>
            <IconButton aria-label="close">
              { anchorEl ? <ExpandLessIcon /> : <ExpandMoreIcon /> }
            </IconButton>
          </Grid>
        </Grid>
      </Box>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <Link to="/">
          <MenuItem onClick={handleClose}>
            <StyledListItemText primary="Home" />
          </MenuItem>
        </Link>
        <Link to="/profile" onClick={handleClose}>
          <MenuItem>
            <StyledListItemText primary="Profile Page" />
          </MenuItem>
        </Link>
        <a target="_blank" rel="noopener noreferrer" href="https://www.educationsuperhighway.org/terms-of-service/" onClick={handleClose}>
          <MenuItem>
            <StyledListItemText primary="Terms and Conditions" />
          </MenuItem>
        </a>
        <a target="_blank" rel="noopener noreferrer" href="https://educationsuperhighway.zendesk.com" onClick={handleClose}>
          <MenuItem>
            <StyledListItemText primary="Request Help" />
          </MenuItem>
        </a>
        {userInfo && isAdminOrStateAdmin(userInfo.role) && (
          <Link to="/admin" onClick={handleClose}>
            <MenuItem>
              <StyledListItemText primary="Admin Dashboard" />
            </MenuItem>
          </Link>
        )}
        <Link
          to="/"
          onClick={() => {
            handleClose()
            logout({ returnTo: window.location.origin })
          }}
        >
          <MenuItem>
            <StyledListItemText primary="Logout" />
          </MenuItem>
        </Link>
      </StyledMenu>
    </Grid>
  )
}
