import mapboxgl from 'mapbox-gl'
import { addPopupOnClick } from './AddPopupBehaviour'

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN

const AddLayersUnconnected = ({ map, disguiseClusters }) => {
  const visibility = 'none'
  const unconnectedId = 'unconnected-point'
  addUnconnectedPoint({ map, unconnectedId, visibility, circleColorUnclustered: '#5E083E' })
  addPopupOnClick({ map, layerId: unconnectedId, disguiseClusters })
}

const addUnconnectedPoint = ({ map, unconnectedId, visibility, circleColorUnclustered }) => {
  if (!map.getLayer(unconnectedId)) {
    map.addLayer({
      id: unconnectedId,
      type: 'circle',
      source: 'studentsUnconnected',
      layout: { 'visibility': visibility },
      paint: {
        'circle-color': circleColorUnclustered,
        'circle-opacity': 0.6,
        'circle-radius': 4,
        'circle-stroke-width': 1,
        'circle-stroke-color': '#5E083E'
      }
    })
  }
}

export default AddLayersUnconnected
