/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import isEmpty from 'lodash.isempty'
import { Redirect, useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import LoggedOutLandingPage from '../loggedOutLandingPage'
import { selectUserInfo } from '../../../redux/selectors'
import LoggedInLandingPageConnected from './loggedInLandingPage'

export default function UserLandingPageConnected () {
  const userInfo = useSelector(selectUserInfo)
  const history = useHistory()
  return (
    <UserLandingPage
      userInfo={userInfo}
      history={history}
    />
  )
}

export function UserLandingPage ({ userInfo, history }) {
  document.title = userInfo.num_data_exchange_entities > 0 ? 'K12 Bridge to Broadband: Home' : 'Home Digital Access Map: Home'
  const isUserValidated = checkIsUserValidated(userInfo.validation_status)
  if (!userInfo || isEmpty(userInfo) || !userInfo.email) {
    return <LoggedOutLandingPage />
  }
  if (checkShouldRedirectToProfile({ userInfo, isUserValidated })) {
    return <Redirect to="/profile" />
  }
  if ((userInfo.role === 'admin') || (userInfo.role === 'eshAdmin') || !(Number(userInfo.num_data_exchange_entities)) || history.location.search === '?from=dataExchange') {
    return <LoggedInLandingPageConnected userInfo={userInfo} isUserValidated={isUserValidated} isStateExceptionUser={userInfo.isStateExceptionUser} />
  }
  return <Redirect to="/dataExchange" />
}

export const checkIsUserValidated = (validationStatus) => validationStatus === 'validated'

const userHasNoStateCodeAndIsNotAdmin = ({ userInfo }) => {
  if (!userInfo) {
    return false
  }
  return (!userInfo.state_code && userInfo.role !== 'admin')
}

export const checkIsUserFirstTime = ({ stateCode, agreedToTerms }) => (
  !stateCode || !agreedToTerms
)

export const checkShouldRedirectToProfile = ({ userInfo, isUserValidated }) => {
  const isFirstTimeUser = checkIsUserFirstTime({ stateCode: userInfo.state_code, agreedToTerms: userInfo.agreed_to_terms })
  return !isUserValidated || !userInfo.email_verified || userHasNoStateCodeAndIsNotAdmin({ userInfo }) || isFirstTimeUser
}
