import React from 'react'
import Grid from '@material-ui/core/Grid'
import { EntitySubHeader } from '../../pageDecorations/entityBanner'
import { transformEntityName } from '../../../utils/constants'

export default function EntityPageBanner ({ entity }) {
  const entityName = `${transformEntityName(entity.name)} (${entity.stateCode})`
  return (
    <Grid container spacing={3} justify="space-between">
      <Grid item xs={12} sm={7}>
        <EntitySubHeader name={entityName} metrics={entity.studentCounts[0]} adminOrOwnerScoped={entity.adminOrOwnerScoped} />
      </Grid>
    </Grid>
  )
}
