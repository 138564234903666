/* eslint-disable consistent-return */
import { putRequest, postRequest, deleteRequest, handleErrorLogRocket } from './helpers'

export async function requestData (url, accessToken = null) {
  let response
  if (accessToken) {
    response = await fetch(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Access-Control-Request-Method': 'GET',
        'Access-Control-Request-Headers': 'Authorization'
      },
      mode: 'cors'
    })
  } else {
    response = await fetch(url)
  }

  if (response.status > 299) {
    const error = new Error(response.statusText)
    error.status = response.status
    handleErrorLogRocket(error)
    throw error
  }
  return response.json()
}

export async function searchAddress (query) {
  const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${query}.json?access_token=${process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}&country=US`
  return requestData(url)
}

export const fetchEntityDemo = async (isMountedRef, setEntity) => {
  try {
    const url = `${process.env.REACT_APP_API_URL}/demo`
    const entityDataFromAPI = await requestData(url)
    if (isMountedRef.current) setEntity(entityDataFromAPI)
  } catch (e) {
    return handleErrorLogRocket(e)
  }
}

export const fetchEntityDataDemo = async (isMountedRef, setEntityDataDigtialEquity) => {
  try {
    const url = `${process.env.REACT_APP_API_URL}/demo/digital_equity`
    const entityDataFromAPI = await requestData(url)
    if (isMountedRef.current) setEntityDataDigtialEquity(entityDataFromAPI)
  } catch (e) {
    return handleErrorLogRocket(e)
  }
}

export const fetchEntity = async ({ entityUID, accessToken, isMountedRef, setEntity }) => {
  try {
    const url = `${process.env.REACT_APP_API_URL}/entities/${entityUID}`
    const entity = await requestData(url, accessToken)
    if (isMountedRef.current) setEntity(entity)
    return entity
  } catch (e) {
    handleErrorLogRocket(e)
    throw e
  }
}

export const fetchEntityData = async ({ entityUID, accessToken, isMountedRef, setEntityData, program }) => {
  try {
    const url = `${process.env.REACT_APP_API_URL}/entities/${entityUID}/${program}`
    const entity = await requestData(url, accessToken)
    if (isMountedRef.current) setEntityData(entity)
    return entity
  } catch (e) {
    handleErrorLogRocket(e)
    return null
  }
}

export const fetchEntityDownloadLink = async ({ entityUID, accessToken, setCsvDataLink, program }) => {
  try {
    const url = `${process.env.REACT_APP_API_URL}/entities/${entityUID}/${program}/download`
    const { csvURL } = await requestData(url, accessToken)
    return setCsvDataLink(csvURL)
  } catch (e) {
    return handleErrorLogRocket(e)
  }
}

export const fetchEntityUploadLink = async ({ entityUID, accessToken }) => {
  try {
    const url = `${process.env.REACT_APP_API_URL}/entities/${entityUID}/upload`
    const { csvURL } = await requestData(url, accessToken)
    return csvURL
  } catch (e) {
    return handleErrorLogRocket(e)
  }
}

export const handleUserUploadIngestion = async ({ entityUID, accessToken, districtName }) => {
  try {
    const url = `${process.env.REACT_APP_API_URL}/entities/${entityUID}/upload`
    const body = { legalAuthorities: true, districtName }
    return await fetch(url, postRequest(body, accessToken))
  } catch (e) {
    return handleErrorLogRocket(e)
  }
}

export const uploadCSVToAWS = async (csvUploadLink, file) => {
  try {
    return await fetch(csvUploadLink, {
      method: 'PUT',
      body: file,
      headers: {
        'Content-Type': 'csv'
      }
    })
  } catch (err) {
    return handleErrorLogRocket(err)
  }
}

export const fetchEntities = async ({ accessToken, setEntites }) => {
  try {
    const url = `${process.env.REACT_APP_API_URL}/entities`
    const entities = await requestData(url, accessToken)
    return setEntites(entities)
  } catch (e) {
    return handleErrorLogRocket(e)
  }
}

export async function fetchUser (accessToken) {
  const url = `${process.env.REACT_APP_API_URL}/users`
  return requestData(url, accessToken)
}

export async function fetchUsers (accessToken) {
  const url = `${process.env.REACT_APP_API_URL}/users/all`
  return requestData(url, accessToken)
}

export const updateUser = async (userId, body, accessToken) => {
  try {
    return await fetch(`${process.env.REACT_APP_API_URL}/users/${userId}`, putRequest(body, accessToken))
  } catch (err) {
    return handleErrorLogRocket(err)
  }
}

export const deleteUser = async (userId, accessToken) => {
  try {
    return await fetch(`${process.env.REACT_APP_API_URL}/users/${userId}`, deleteRequest(accessToken))
  } catch (err) {
    return handleErrorLogRocket(err)
  }
}

export const upsertUserEntities = async (email, entityUIDs, accessToken) => {
  try {
    const body = { email, entities: entityUIDs }
    await fetch(`${process.env.REACT_APP_API_URL}/user_entities?email=${email}`, deleteRequest(accessToken))
    await fetch(`${process.env.REACT_APP_API_URL}/user_entities`, postRequest(body, accessToken))
    // eslint-disable-next-line no-console
    console.log('updated user_entity associations for ', email)
  } catch (err) {
    return handleErrorLogRocket(err)
  }
}

export const addUserEntities = async (body, accessToken) => {
  try {
    return await fetch(`${process.env.REACT_APP_API_URL}/user_entities/share`, postRequest(body, accessToken))
  } catch (err) {
    return handleErrorLogRocket(err)
  }
}

export async function searchEntitiesForTypeahead (accessToken) {
  const url = `${process.env.REACT_APP_API_URL}/entities?typeahead=true`
  return requestData(url, accessToken)
}

export const addEntity = async (entity, accessToken) => {
  try {
    return await fetch(`${process.env.REACT_APP_API_URL}/entities`, postRequest(entity, accessToken))
  } catch (err) {
    return handleErrorLogRocket(err)
  }
}

export const fetchEntityToEdit = async ({ entityUID, accessToken }) => {
  try {
    const url = `${process.env.REACT_APP_API_URL}/entities/${entityUID}?entityForEdit=true`
    return requestData(url, accessToken)
  } catch (e) {
    return handleErrorLogRocket(e)
  }
}

export const editEntity = async (entity, accessToken) => {
  try {
    return await fetch(`${process.env.REACT_APP_API_URL}/entities/${entity.entityUID}`, putRequest(entity, accessToken))
  } catch (err) {
    return handleErrorLogRocket(err)
  }
}

export const editStudents = async (dataForUpload, accessToken) => {
  try {
    return await fetch(`${process.env.REACT_APP_API_URL}/students`, putRequest(dataForUpload, accessToken))
  } catch (err) {
    return handleErrorLogRocket(err)
  }
}

export const fetchStudents = async ({ accessToken, entityUID, setStudents }) => {
  try {
    const url = `${process.env.REACT_APP_API_URL}/students/${entityUID}`
    const students = await requestData(url, accessToken)
    setStudents(students)
  } catch (e) {
    handleErrorLogRocket(e)
  }
}
