/* eslint-disable max-lines */
import React from 'react'
import TextareaAutosize from '@material-ui/core/TextareaAutosize'
import {
  Typography,
  Box
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  legalAgreementText: {
    width: '100%',
    marginTop: theme.spacing(2),
    backgroundColor: theme.palette.secondary.light,
    padding: theme.spacing(2),
    marginLeft: 0,
    marginRight: 0
  }
}))

export const dataSharingAgreement = `
Data Sharing Agreement

This DATA SHARING AGREEMENT (the "Agreement”) is entered into by and between PARTNER LOCAL EDUCATION AGENCY (the “LEA”) and EducationSuperHighway, a California nonprofit corporation, having an address at 6 Presidio Terrace, San Francisco, CA, 94118, (each a “Party”), effective immediately. The Parties agree to the terms as stated herein.

If you are agreeing to these terms on behalf of an LEA then you represent and warrant that you are an authorized representative of LEA with authority to bind such organization to these terms, and that you agree to these terms on behalf of such organization.

1. DEFINITIONS.
a. “Services” shall include, but are not limited to, the use of EducationSuperHighway’s Home Digital Access Map (https://map.educationsuperhighway.org) that allows LEA users to upload addresses of student households and visualize which internet service providers can potentially deliver connectivity to those addresses
b. Personally Identifiable Information (“PII”) is defined as contemplated by the Family Educational Rights and Privacy Act ("FERPA") and includes information that, alone or in combination, is linked or linkable to a specific student so as to allow a reasonable person in the school community who does not have knowledge of the relevant circumstances to identify the student with reasonable certainty, such as a student's identification numbers, residential address, teacher, grade level, and demographic data.

2. OWNERSHIP.
a. The LEA owns and retains all rights, title and interest to, or has appropriate possessory rights in, PII. EducationSuperHighway makes no claim of license, title or ownership to or in PII except as provided in this Agreement.

3. TREATMENT OF PII.
a. All PII accessed or used by the EducationSuperHighway shall at all times be treated as confidential by EducationSuperHighway and shall not be copied, used or disclosed by EducationSuperHighway for any purpose not related to providing Services to the LEA. As outlined in more detail below, EducationSuperHighway recognizes that personally identifiable information is protected against disclosure by federal and state statutes and regulations, and EducationSuperHighway agrees to comply with said restrictions.

4. PURPOSE AND DURATION.
a. For EducationSuperHighway to provide Services to the LEA it may become necessary for the LEA to share certain PII related to the LEA’s students.
b. This agreement becomes effective immediately upon the date of execution and shall remain in effect during the time that EducationSuperHighway provides Services to the LEA or until terminated as provided in this Agreement.

5. PII COLLECTION AND USE.
a. EducationSuperHighway will only collect and use PII necessary to provide Services as outlined in this Agreement.

6. PRIVACY COMPLIANCE
a. EducationSuperHighway shall comply with all state and federal laws and regulations related to privacy and security and applicable to the LEA and/or EducationSuperHighway in providing the Services to the LEA.
b. The Parties acknowledge that the LEA is subject to the Family Educational Rights and Privacy Act (20 U.S.C. 1232g) (“FERPA”), which law and supporting regulations generally address certain obligations of an educational agency or institution that receives federal funds regarding disclosure of personally identifiable information in education records. To the extent that EducationSuperHighway receives personally identifiable information from education records from the LEA, the Parties agree that EducationSuperHighway is a “school official” under FERPA and has a legitimate educational interest in personally identifiable information from education records because EducationSuperHighway: (1) provides a service or function for which the LEA would otherwise use employees; (2) is under the direct control of the LEA with respect to the use and maintenance of education records; and (3) is subject to the requirements of FERPA governing the use and redisclosure of personally identifiable information from education records.  Personally identifiable information will be used only for furthering educational interests.
c. The parties expect and anticipate that EducationSuperHighway may receive PII from the LEA only as an incident of service or training that EducationSuperHighway provides to the LEA pursuant to this Agreement. EducationSuperHighway shall be permitted to use and further disclose, including to employees, subcontractors, service providers and agents as stated in Section 8 of this agreement (“PII SHARING”), any such PII in education records as a function of providing Services. EducationSuperHighway represents that it shall not use or further disclose any personally identifiable information in education records other than as a function of providing Services.

7. MARKETING AND ADVERTISING PROHIBITED.
a. EducationSuperHighway shall not use any PII to advertise or market to students, their parents, or LEA employees or officials.

8. PII SHARING.
a. EducationSuperHighway shall not share, disclose, or sell PII with or to any additional parties without prior written consent of the LEA, except as provided in this Agreement.
b. LEA acknowledges and agrees that EducationSuperHighway may permit its employees, subcontractors, service providers and agents, including but not limited to Amazon Web Services, the Federal Communications Commission, the US Census Bureau, Mapbox, and SmartyStreets, to access PII provided that they have a legitimate need to access such information in connection with their responsibilities in providing services to EducationSuperHighway. EducationSuperHighway will require all subcontractors, service providers, or agents involved in the handling, transmittal, and processing of PII to enter into written agreements to protect PII in a manner no less stringent than the terms of this Agreement. EducationSuperHighway will maintain access log(s) that record all disclosures of or third-party access to PII within its possession and will provide copies of such access log(s) to LEA upon request.
c. In the event any third person(s) seeks to access any PII beyond the access that is provided to EducationSuperHighway’s employees for purposes of providing Services to the LEA under this Agreement, EducationSuperHighway will inform the LEA of such request in writing within a reasonable period of time unless expressly prohibited by law or judicial order. The LEA will respond to all requests for PII received by EducationSuperHighway; EducationSuperHighway will not respond in any way to such third party requests for PII, except to notify the person seeking access that the request has been forwarded to the LEA.
d. Notwithstanding any other provision in this Agreement, should EducationSuperHighway receive a court order or lawfully issued subpoena seeking the release of such PII, EducationSuperHighway shall provide notification in writing to the LEA within a reasonable period of time of its receipt of such court order or lawfully issued subpoena and shall immediately provide the LEA with a copy of such court order or lawfully issued subpoena prior to releasing the requested PII.

9. PII DATA DESTRUCTION.
a. EducationSuperHighway will ensure that all PII in its possession and in the possession of any employees, subcontractors, service providers and agents to which EducationSuperHighway may have transferred PII are destroyed or transferred to the LEA at the written request or direction of the LEA within a commercially reasonable time not to exceed thirty days, when this Agreement is terminated, or, with at least ten business days’ notice in writing to the LEA, when the PII are no longer needed for the specified purpose.  PII data destruction will be achieved through deletion of PII from databases where it is stored.

10. RIGHTS AND LICENSE IN AND TO PII.
a. Parties agree that all rights, including all intellectual property rights, to PII shall remain the exclusive property of the LEA, and EducationSuperHighway has a limited, nonexclusive license solely for the purpose of performing its obligations as outlined in the Agreement. This Agreement does not give EducationSuperHighway any rights, implied or otherwise, to PII, content, or intellectual property, except as expressly stated in the Agreement.

11. ACCESS.
a. Any PII held by EducationSuperHighway will be made available to the LEA upon request in writing by the LEA within a commercially reasonable time not to exceed ten business days.

12. SECURITY CONTROLS.
a. EducationSuperHighway will implement commercially reasonable administrative, physical and technical safeguards designed to secure PII from unauthorized access, disclosure, or use, which could include data encryption, firewalls, and password protection. EducationSuperHighway will require employees, subcontractors, service providers and agents to adhere to data confidentiality terms providing for the protection of PII in a manner consistent with the terms of this Agreement. Access to PII and EducationSuperHighway’s systems will be limited to only those employees, subcontractors, service providers and agents that have a need-to-know basis based on specific job function or role.

13. NOTIFICATION OF DATA BREACH.
a. When EducationSuperHighway becomes aware of a disclosure or security breach concerning any PII covered by this Agreement, EducationSuperHighway shall notify the LEA within a reasonable period of time and take immediate steps to limit and mitigate the damage of such security breach to the greatest extent possible.

14. TERMINATION.
a. In the event that either Party seeks to terminate this Agreement, they may do so by submitting written notification ten business days in advance of the desired date of termination to the other Party.
b. If the Agreement is terminated, EducationSuperHighway shall dispose of all of LEA’s PII in accordance with Section 9 of this Agreement (“PII DATA DESTRUCTION”)

15. MODIFICATIONS.
a. This Agreement may be modified only by a written amendment signed by both Parties, except to the extent EducationSuperHighway is otherwise expressly permitted by this Agreement. In the event that the LEA refuses to accept such changes, EducationSuperHighway will have the right to terminate this Agreement in accordance with Section 14 hereof (“TERMINATION”).

16. SEVERABILITY.
a. The provisions of this Agreement are severable. If a court of competent jurisdiction determines that any portion of this Agreement is invalid or unenforceable, the court’s ruling will not affect the validity or enforceability of the other provisions of the Agreement.

17. NOTICES.
a. Unless stated otherwise in this Agreement, all notices required by or relating to this Agreement shall be in writing and shall be communicated by electronic mail to EducationSuperHighway at legal@educationsuperhighway.org and to the LEA at the email address associated with the LEA’s primary account.
b. Any notices, communications, or consents required by or relating to this Agreement from EducationSuperHighway to the LEA will be sent to the attention of the person listed on the primary account (the “Principal Contact Person”). The Principal Contact Person shall be authorized to act on behalf of the LEA and to make decisions for the LEA.

18. INDEMNIFICATION
a. To the extent permissible under applicable state law, each Party (the "Indemnifying Party") shall defend, indemnify, and hold harmless the other Party (the "Indemnitee), its officers, employees, and agents, assignees and successors-in-interest from and against third party claims and will pay the resulting non-appealable judgments, fines, settlements, court costs and reasonable attorneys' fees ("Indemnified Loss") resulting from or arising out of (a) the Indemnifying Party's performance under this Agreement, but only in proportion to and to the extent such Indemnified Loss is caused by the negligence, recklessness, willful misconduct, or intentional acts or omissions of the Indemnifying Party, its officers, employees, or agents; and (b) allegations that the Indemnifying Party has infringed a third party's intellectual property rights. Such indemnification shall be subject to the limitation of liability in Section 19 of this Agreement “(LIMITATION ON LIABILITY”).

19. LIMITATION ON LIABILITY
a. IN NO EVENT SHALL EITHER PARTY BE LIABLE TO THE OTHER FOR (A) CONSEQUENTIAL, INDIRECT, SPECIAL, OR PUNITIVE DAMAGES ARISING FROM ANY CLAIM OR ACTION BASED ON CONTRACT, TORT OR OTHER LEGAL THEORY; OR (B) DAMAGES IN EXCESS OF $100 IN THE AGGREGATE. EDUCATIONSUPERHIGHWAY SHALL NOT BE LIABLE FOR LEA'S INABILITY TO USE THE SERVICES DUE TO (I) SCHEDULED DOWNTIME; (II) UNAVAILABILITY DUE TO FACTORS OUTSIDE OF EDUCATIONSUPERHIGHWAY'S REASONABLE CONTROL, INCLUDING WITHOUT LIMITATION, ACTS OF GOD, ACTS OF GOVERNMENT, FLOOD, FIRE, EARTHQUAKES, CIVIL UNREST, ACTS OF TERROR, STRIKES OR OTHER LABOR PROBLEMS; (III) UNAVAILABILITY THAT RESULTS FROM EQUIPMENT AND/OR SOFTWARE OF THIRD PARTIES WHERE SUCH EQUIPMENT AND/OR SOFTWARE IS NOT WITHIN THE REASONABLE CONTROL OF EDUCATIONSUPERHIGHWAY OR; (IV) UNAVAILABILITY CAUSED BY ABUSE OR MISUSE OF THE SERVICES OR PROGRAM (OR ANY COMPONENT THEREOF) BY THE LEA OR THEIR RESPECTIVE USERS. DISCLAIMERS, EXCLUSIONS, AND LIMITATIONS OF LIABILITY WILL NOT APPLY TO THE EXTENT PROHIBITED BY APPLICABLE LAW.

20. ENTIRE AGREEMENT AND CONFLICTS.
a. This Agreement, together with EducationSuperHighway’s Terms of Service and Privacy Policy is the entire agreement between the LEA and EducationSuperHighway.
b. This Agreement shall govern the treatment of PII. If there is a conflict between this Agreement and the Terms of Use, Privacy Policy, or any other agreement between the LEA and EducationSuperHighway (collectively, "Other Documents"), the terms of this Agreement shall apply and take precedence unless specifically provided otherwise in this Agreement or the Other Documents.
`

export default function DataSharingAgreement () {
  const classes = useStyles()
  return (
    <Box mt={2}>
      <Typography variant="body1"><strong>EDUCATIONSUPERHIGHWAY HOME DIGITAL ACCESS DATA SHARING AGREEMENT</strong></Typography>
      <TextareaAutosize
        className={classes.legalAgreementText}
        readOnly
        rowsMax={12}
        aria-label="data sharing agreement"
        defaultValue={dataSharingAgreement}
      />
    </Box>
  )
}
