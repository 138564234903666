import React, { useState } from 'react'
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete'
import { TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { searchAddress } from '../../../data/fetchData'

export default function AddressSearchTypeahead ({ setFieldValue }) {
  /* eslint camelcase: "off" */
  const [options, setOptions] = useState([])
  const classes = useStyles()

  const limitOfDisplayedOptions = 10
  const filterOptions = createFilterOptions({ limit: limitOfDisplayedOptions })

  return (
    <Autocomplete
      options={options}
      freeSolo
      filterOptions={filterOptions}
      getOptionLabel={(option) => (option.address ? option.address : '')}
      className={classes.entityNameTypeahead}
      onInputChange={async (event, value) => {
        if (value) {
          const items = await searchAddress(value)
          const options = items.features.map((i) => ({
            address: i.place_name,
            coordinates: i.geometry.coordinates
          }))
          setOptions(options)
        }
      }}
      onChange={(_event, value) => {
        if (value) {
          setFieldValue('coordinates', value.coordinates)
        } else {
          setFieldValue('coordinates', [])
          setOptions([])
        }
      }}
      renderInput={(params) => (<TextField {...params} label="Address" margin="normal" variant="outlined" />)}
    />
  )
}

const useStyles = makeStyles((theme) => ({
  typeahead: {
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(1),
    width: '560px'
  },
  labelText: {
    color: 'rgba(0, 0, 0, 0.54)',
    margin: 0,
    padding: 0
  },
  entityNameTypeahead: {
    marginBottom: theme.spacing(2)
  }
}))
