import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import * as Yup from 'yup'
import { Formik } from 'formik'
import { Box, Button, Card, CardContent, CardHeader, CircularProgress, Divider, Grid, TextField, MenuItem, FormHelperText } from '@material-ui/core'
import { statesInfoNoTerritories, entityTypes } from '../../../../utils/constants'
import AddressSearchTypeahead from '../../../typeaheads/addressTypeahead'
import { addEntity } from '../../../../data/fetchData'

export default function AddEntities ({ accessToken }) {
  const classes = useStyles()
  return (
    <Formik
      initialValues={{ name: '', ncesCode: '', stateCode: '', entityType: '', optInExchange: false, digitalEquity: false, coordinates: [] }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required('An entity name is required'),
        ncesCode: Yup.string().test('len', 'NCES code must be exactly 7 characters', (val) => {
          if (!val) return true
          return val.length === 7
        }),
        stateCode: Yup.string().required('A state code is required'),
        entityType: Yup.string().required('An entity type is required'),
        coordinates: Yup.array().when('entityType', {
          is: 'district',
          then: Yup.array().length(2).required('A valid entity address is required')
        })
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting, resetForm }) => {
        try {
          const entity = {
            name: values.name,
            ncesCode: values.ncesCode,
            coordinates: values.coordinates,
            stateCode: values.stateCode,
            entityType: values.entityType,
            optInExchange: values.optInExchange,
            digitalEquity: values.digitalEquity
          }
          await addEntity(entity, accessToken)
          setStatus({ success: true })
          setSubmitting(false)
          resetForm()
        } catch (err) {
          /* eslint no-console: "off" */
          console.error(err);
          setStatus({ success: false });
          setErrors({ submit: err.message });
          setSubmitting(false);
        }
      }}
    >
      {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, setFieldValue, touched, values }) => (
        <Card raised className={classes.addEntityCard}>
          <CardHeader title="Add a new entity" />
          <Divider />
          <CardContent>
            {isSubmitting ? (
              <Box
                display="flex"
                justifyContent="center"
                my={5}
              >
                <CircularProgress className={classes.circularProgress} />
              </Box>
            ) : (
              <form onSubmit={handleSubmit}>

                <Grid container spacing={4}>
                  <Grid item md={2} xs={2}>
                    <TextField
                      id="stateCode"
                      select
                      label="State Code"
                      className={classes.textField}
                      error={Boolean(touched.stateCode && errors.stateCode)}
                      value={values.stateCode}
                      onChange={handleChange('stateCode')}
                      margin="normal"
                      fullWidth
                      variant="filled"
                    >
                      {Object.keys(statesInfoNoTerritories).map((stateCode) => (
                        <MenuItem key={stateCode} value={stateCode}>
                          {stateCode}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item md={2} xs={2}>
                    <TextField
                      id="entityType"
                      select
                      label="Entity Type"
                      error={Boolean(touched.entityType && errors.entityType)}
                      className={classes.textField}
                      value={values.entityType}
                      onChange={handleChange('entityType')}
                      margin="normal"
                      fullWidth
                      variant="filled"
                    >
                      {entityTypes.map((entityType) => (
                        <MenuItem key={entityType.label} value={entityType.value}>
                          {entityType.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item md={2} xs={2}>
                    <TextField
                      id="optInExchange"
                      select
                      label="Opt Into Data Exchange"
                      className={classes.textField}
                      value={values.optInExchange}
                      onChange={handleChange('optInExchange')}
                      margin="normal"
                      fullWidth
                      variant="filled"
                    >
                      {[{ label: 'TRUE', value: true }, { label: 'FALSE', value: false }].map((optInExchange) => (
                        <MenuItem key={optInExchange.label} value={optInExchange.value}>
                          {optInExchange.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item md={2} xs={2}>
                    <TextField
                      id="digitalEquity"
                      select
                      label="Opt Into Digital Equity"
                      className={classes.textField}
                      value={values.digitalEquity}
                      onChange={handleChange('digitalEquity')}
                      margin="normal"
                      fullWidth
                      variant="filled"
                    >
                      {[{ label: 'TRUE', value: true }, { label: 'FALSE', value: false }].map((digitalEquity) => (
                        <MenuItem key={digitalEquity.label} value={digitalEquity.value}>
                          {digitalEquity.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={2}
                >
                  <Grid
                    item
                    md={6}
                    xs={12}
                  >
                    <TextField
                      fullWidth
                      error={Boolean(touched.name && errors.name)}
                      helperText={touched.name && errors.name}
                      label="Entity Name"
                      name="name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.name}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid
                    item
                    md={6}
                    xs={12}
                  >
                    {values.entityType === 'district' && (
                    <TextField
                      error={Boolean(touched.ncesCode && errors.ncesCode)}
                      fullWidth
                      helperText={touched.ncesCode && errors.ncesCode}
                      label="NCES Code"
                      name="ncesCode"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.ncesCode}
                      variant="outlined"
                    />
                    )}
                  </Grid>
                </Grid>
                <Box mt={2}>
                  {values.entityType === 'district' && <AddressSearchTypeahead setFieldValue={setFieldValue} disabled />}
                  {errors.coordinates && touched.coordinates && <FormHelperText error={Boolean(errors.coordinates)} id="my-helper-text">Must select an address</FormHelperText>}
                </Box>

                <Box mt={2}>
                  <Button
                    className={classes.submitButton}
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    ADD ENTITY
                  </Button>
                </Box>
              </form>
            )}
          </CardContent>
        </Card>
      )}
    </Formik>
  )
}

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(3),
    paddingTop: theme.spacing(5)
  },
  formField: {
    fontSize: '18px',
    marginBottom: theme.spacing(2)
  },
  textField: {
    fontSize: '18px'
  },
  labelText: {
    color: 'rgba(0, 0, 0, 0.54)',
    margin: 0,
    padding: 0
  },
  error: {
    color: theme.palette.error.main
  },
  submitButton: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.success.active,
    }
  },
  circularProgress: {
    color: theme.palette.success.main
  },
  addEntityCard: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3)
  }
}))
