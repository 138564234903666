import React, { useState, useEffect } from 'react'
import LogRocket from 'logrocket'
import { useSelector } from 'react-redux'
import { AppBar, Tab, Tabs, makeStyles } from '@material-ui/core'
import { fetchUsers, fetchEntities } from '../../../data/fetchData'
import UsersTable from './UsersTable'
import AddOrEditEntities from './AddOrEditEntities'
import EntityLinkTable from '../userLandingPage/loggedInLandingPage/EntityLinkTable'
import { TabPanel, idProps } from '../../pageDecorations/editDataTabs'
import { selectUserInfo } from '../../../redux/selectors'
import ForbiddenErrorPage from '../errorPages/forbiddenError'

const userIsMapAdminOrMapUser = (role) => role === 'mapAdmin' || role === 'mapUser' || role === 'eshAdmin'

export default function AdminDashboardPage () {
  const [currentTab, setCurrentTab] = useState(0)
  const [users, setUsers] = useState(null)
  const [errored, setErrored] = useState(false)
  const [entities, setEntites] = useState()
  const classes = useStyles()
  const userInfo = useSelector(selectUserInfo)

  useEffect(() => {
    const fetchUsersAndViews = async (setUsers) => {
      try {
        const usersFromAPI = await fetchUsers(userInfo.accessToken)
        setUsers(usersFromAPI)
      } catch (e) {
        if (process.env.REACT_APP_ENV === 'production') {
          LogRocket.captureException(e)
        }
        setErrored(true)
        setUsers('error')
      }
    }
    fetchUsersAndViews(setUsers)
    fetchEntities({ setEntites, accessToken: userInfo.accessToken })
  }, [userInfo, setUsers, setErrored, setEntites])

  if (errored) {
    return <ForbiddenErrorPage />
  }

  if (!userInfo || userIsMapAdminOrMapUser(userInfo.role)) return null

  const handleTabsChange = (_, value) => {
    setCurrentTab(value)
  }
  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Tabs value={currentTab} onChange={handleTabsChange} aria-label="admin-tabs">
          <Tab label="Users" {...idProps(0)} />
          <Tab label="Add/Edit Entities" {...idProps(1)} />
          <Tab label="Entities and Users" {...idProps(2)} />
        </Tabs>
      </AppBar>
      <TabPanel value={currentTab} index={0}>
        <UsersTable userInfo={userInfo} users={users} />
      </TabPanel>
      <TabPanel value={currentTab} index={1}>
        <AddOrEditEntities userInfo={userInfo} />
      </TabPanel>
      <TabPanel value={currentTab} index={2}>
        { entities && <EntityLinkTable entities={entities} showUsers /> }
      </TabPanel>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper
  }
}))
