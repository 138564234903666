import { createSlice } from '@reduxjs/toolkit'

const slice = createSlice({
  name: 'mapTab',
  initialState: {
    dataExchange: {
      open: true
    },
    digitalEquity: {
      open: false
    }
  },
  reducers: {
    setMapTab (state, action) {
      const { value } = action.payload
      const oppositeValue = !value
      const name = extractProgramNameFromValue(value)
      const oppositeName = extractProgramNameFromValue(oppositeValue)
      state[name].open = true
      state[oppositeName].open = false
    }
  }
})

const extractProgramNameFromValue = (value) => (!value ? 'dataExchange' : 'digitalEquity')

export const { setMapTab } = slice.actions

export default slice.reducer
