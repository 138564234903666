import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import * as Yup from 'yup'
import { Formik } from 'formik'
import { Box, Button, Card, CardContent, CardHeader, FormHelperText, Divider, Grid, TextField, List, ListItem, } from '@material-ui/core'
import { isEmpty } from 'lodash'
import { selectUserInfo } from '../../../redux/selectors'
import CSVDropZone from './CSVDropZone'
import EntitytNameTypeahead from '../../typeaheads/entityNameTypeahead'
import LegalAuthoritiesDialog from '../../dialogs/LegalAuthoritiesDialog'
import UploadAnywayMessage from './UploadAnywayMessage'

/* eslint-disable max-lines */
const displayCSVError = (csvError) => (
  <ListItem key={csvError}>
    {csvError}
  </ListItem>
)

export default function CSVUploadForm ({ program }) {
  const userInfo = useSelector(selectUserInfo)
  const [legalAuthoritiesOpen, setLegalAuthoritiesOpen] = useState(false)
  const [uploadAnywayOpen, setUploadAnywayOpen] = useState(false)
  const [agreedToFixErrors, setAgreedToFixErrors] = useState(false)
  const [typeahedEntity, setTypeaheadEntity] = useState({})
  const [csvErrors, setCsvErrors] = useState([])
  const classes = useStyles()

  if (!userInfo || userInfo.validation_status !== 'validated' || userInfo.isStateExceptionUser) return null

  const onSelectEntity = async (entity, setFieldValue) => {
    setTypeaheadEntity(entity)
    if (isEmpty(entity)) {
      setFieldValue('entityUID', '')
      setFieldValue('name', '')
    } else {
      setFieldValue('entityUID', entity.entity_uid)
      setFieldValue('name', entity.name)
    }
  }

  return (
    <Formik
      initialValues={{
        email: userInfo.email,
        /* eslint-disable no-extra-boolean-cast */
        firstName: !!userInfo.name ? userInfo.name.split(' ')[0] : '',
        lastName: !!userInfo.name ? userInfo.name.split(' ')[1] : '',
        entityUID: userInfo.associatedentities.length === 1 ? userInfo.associatedentities[0].entityUID : '',
        name: '',
        csvFile: null,
        submit: null,
        onlyOneEntityName: userInfo.associatedentities.length === 1 ? userInfo.associatedentities[0].entityName : null
      }}
      validationSchema={Yup.object().shape({
        firstName: Yup.string().required('Required'),
        lastName: Yup.string().required('Required'),
        csvFile: Yup
          .mixed()
          .required('A csv file is required')
          .test(
            'fileFormat',
            'Unsupported Format, please make sure you have saved your file as a CSV. Try adding .csv to the end of your file name',
            (value) => {
              if (value) {
                const { name } = value
                return name.toLowerCase().endsWith('.csv')
              }
              return null
            }
          ),
        name: Yup.string(),
        entityUID: Yup.string().uuid().required('Required')
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          setLegalAuthoritiesOpen(true)
          setStatus({ success: true })
          setSubmitting(false)
        } catch (err) {
          /* eslint no-console: "off" */
          console.error(err)
          setStatus({ success: false })
          setErrors({ submit: err.message })
          setSubmitting(false)
        }
      }}
    >
      {({ errors, handleBlur, handleSubmit, handleChange, isSubmitting, touched, submitCount, setFieldError, setFieldValue, submitForm, values }) => (
        <Card>
          <CardHeader title="Data Upload" />
          <Divider />
          <CardContent>
            <form onSubmit={handleSubmit}>
              {values.onlyOneEntityName ? (
                <Box my={2}>
                  <TextField
                    fullWidth
                    label="Entity Name"
                    name="entityName"
                    value={values.onlyOneEntityName}
                    variant="outlined"
                    disabled
                  />
                </Box>
              )
                : (
                  <Box mt={2}>
                    <EntitytNameTypeahead
                      setValue={onSelectEntity}
                      value={typeahedEntity}
                      handleBlur={handleBlur}
                      setFieldValue={setFieldValue}
                      touched={touched}
                      userInfo={userInfo}
                      errors={errors}
                      values={values}
                      submitCount={submitCount}
                    />
                  </Box>
                )}
              <Grid
                container
                spacing={2}
              >
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    error={Boolean(touched.firstName && errors.firstName)}
                    helperText={touched.firstName && errors.firstName}
                    label="First Name"
                    name="firstName"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.firstName}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    error={Boolean(touched.lastName && errors.lastName)}
                    helperText={touched.lastName && errors.lastName}
                    label="Last Name"
                    name="lastName"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.lastName}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
              <Box mt={2}>
                <TextField
                  fullWidth
                  label="Email Address"
                  name="email"
                  type="email"
                  value={values.email}
                  variant="outlined"
                  disabled
                />
              </Box>
              <Box mt={2}>
                <Card>
                  <CardHeader title="Upload CSV" />
                  <Divider />
                  <CardContent>
                    <CSVDropZone setFieldValue={setFieldValue} setFieldError={setFieldError} setCsvErrors={setCsvErrors} />
                    {errors.csvFile && submitCount > 0 && <FormHelperText error={Boolean(errors.csvFile)} id="my-helper-text">{errors.csvFile}</FormHelperText>}
                  </CardContent>
                  {(csvErrors.length) && (!agreedToFixErrors) ? setUploadAnywayOpen(true) : null}
                  <UploadAnywayMessage open={uploadAnywayOpen} setOpen={setUploadAnywayOpen} setCsvErrors={setCsvErrors} setAgreedToFixErrors={setAgreedToFixErrors} />
                  <List dense className={classes.error}>
                    {csvErrors.map((csvError) => displayCSVError(csvError))}
                  </List>
                </Card>
              </Box>
              <Box mt={2}>
                <Button
                  className={classes.submitButton}
                  disabled={(isSubmitting || (csvErrors.length))}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  Continue
                </Button>
              </Box>
              <LegalAuthoritiesDialog submitParentForm={submitForm} open={legalAuthoritiesOpen} setOpen={setLegalAuthoritiesOpen} csvUploadFormValues={values} program={program} />
            </form>
          </CardContent>
        </Card>
      )}
    </Formik>
  )
}

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(3),
    paddingTop: theme.spacing(5)
  },
  formField: {
    fontSize: '18px',
    marginBottom: theme.spacing(2)
  },
  textField: {
    fontSize: '18px'
  },
  labelText: {
    color: 'rgba(0, 0, 0, 0.54)',
    margin: 0,
    padding: 0
  },
  error: {
    color: theme.palette.error.main
  },
  submitButton: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.success.active,
    }
  },
  circularProgress: {
    color: theme.palette.success.main
  }
}))
