/* eslint-disable max-lines */
import React from 'react'
import TextareaAutosize from '@material-ui/core/TextareaAutosize'
import {
  Typography,
  Box
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  termsAndConditions: {
    width: '100%',
    marginTop: theme.spacing(1),
    backgroundColor: theme.palette.secondary.light,
    padding: theme.spacing(2),
    marginLeft: 0,
    marginRight: 0
  },
  termsAndConditionsBox: {
    marginBottom: theme.spacing(2)
  }
}))

export const termsAndConditionsText = `
TERMS OF SERVICE

Effective Date:  August 5, 2014
Last Updated Date:  June 11, 2021

IMPORTANT – PLEASE READ THIS AGREEMENT!


I. Introduction and Eligibility

Please read these Terms of Service (this “Agreement”) carefully before accessing or using any ESH Sites (as defined below). Your use of the ESH Sites constitutes your consent to this Agreement.

This Agreement includes our Privacy Policy located at www.educationsuperhighway.org/privacy/, which is incorporated by reference into this Agreement.

THIS AGREEMENT CONTAINS A MANDATORY ARBITRATION PROVISION THAT, AS FURTHER SET FORTH IN SECTION 11 BELOW, REQUIRES THE USE OF ARBITRATION ON AN INDIVIDUAL BASIS TO RESOLVE DISPUTES, RATHER THAN JURY TRIALS OR ANY OTHER COURT PROCEEDINGS, OR CLASS ACTIONS OF ANY KIND.

This agreement is a legally binding contract. It may change, and you agree you will review it for any updates regularly.

Binding Agreement. This Agreement constitutes a binding agreement between you and EducationSuperHighway and its affiliates and subsidiaries (“ESH,” “we,” “us”) concerning your use of (including any access to) the ESH sites located at educationsuperhighway.org, map.educationsuperhighway.org and digitalbridgek12.org (together with any materials and services available therein, and successor site(s) thereto, the “ESH Sites”). “You” and “users” will mean end users of ESH Sites. You accept this Agreement each time you access and use ESH Sites. If you do not accept this Agreement, you must not use ESH Sites. This Agreement hereby incorporates by reference any additional terms and conditions posted by ESH through the ESH Sites, or otherwise made available to you by ESH.
Revisions to Agreement. We may revise this Agreement at any time by posting an updated version to this web page. You should visit this page periodically to review the most current Agreement, because you are bound by them. Your continued use of the ESH Sites after a change to this Agreement constitutes your binding acceptance of this Agreement.
Children. We do not knowingly collect personal information online from children under the age of 13. If we learn that we have collected any personal information online from children under 13, we will promptly take steps to delete such information. ESH Sites are not directed to persons under the age of 13.
By using the ESH Sites, you affirm that you are of legal age to enter into this Agreement. IF YOU ARE UNDER 13 YEARS OF AGE, PLEASE DO NOT USE OR ACCESS ESH SITES AT ANY TIME OR IN ANY MANNER.

Agent of a Company, Entity, or Organization. If you are using ESH Sites on behalf of a company, entity, or organization (collectively “Organization”), then you represent and warrant that you:
are an authorized representative of that Organization;
have the legal authority to bind that Organization to this Agreement; and
agree to be bound by this Agreement on behalf of that Organization.
References to “you” and “your” in this Agreement will refer to both the individual using the ESH Sites and to any such Organization.

The terms “post” and “posting” as used in this Agreement will mean the act of submitting, uploading, publishing, displaying, or similar action on ESH Sites.


II. Your Account

You are responsible for your log-in credentials and for keeping your information accurate.

You are responsible for any activity resulting from the use of your log-in credentials on any ESH Sites.

You represent and warrant that the information you provide to ESH upon the licensing of any ESH Sites and at all other times will be true, accurate, current, and complete. Additionally, you represent and warrant that the information you provide in connection with the ESH Sites will be true, accurate, current, and complete, and that you will maintain and update such information as needed.

1. Your Log-In Credentials. To use ESH Sites, you may have log-in information, including a username and password. Your account is personal to you, and you may not share your account information with, or allow access to your account by, any third party. As you will be responsible for all activity that occurs under your access credentials, you agree to use reasonable efforts to prevent unauthorized access to or use of ESH Sites and to preserve the confidentiality of your username and password, and any device that you use to access ESH Sites. You agree to notify us immediately of any breach in secrecy of your log-in information. If you have any reason to believe that your account information has been compromised or that your account has been accessed by a third party, you agree to immediately notify ESH by e-mail to legal@educationsuperhighway.org. You will be solely responsible for the losses incurred by the ESH and others due to any unauthorized use of your account.


III. Communications

ESH may communicate with you by email.

You agree to receive email from us at the email address you provided to us for service-related purposes and to communicate with you about reports, analysis results, and related information.


IV. ESH’s Content Ownership and Use

ESH owns all of the content we create, but you may use it while you use ESH Sites.

You cannot use our logo without our written permission.

We and our suppliers own the ESH Sites, which are protected by proprietary rights and laws. Our trade names, trademarks and service marks include “EducationSuperHighway,” “ESH,” the ESH logo, other ESH logos and product and service names and any associated logos.  All trade names, trademarks, service marks and logos on the ESH Sites not owned by us are the property of their respective owners. You may not use our trade names, trademarks, service marks or logos in connection with any product or service that is not ours, or in any manner that is likely to cause confusion. Nothing contained on the ESH Sites should be construed as granting any right to use any trade names, trademarks, service marks or logos without the express prior written consent of the owner.

The contents of the ESH Services include: designs, text, graphics, images, video, information, logos, button icons, software, audio files, computer code, and other ESH content (collectively, “ESH Content”). All ESH Content and the compilation (meaning the collection, arrangement, and assembly) of all ESH Content are the property of ESH or its licensors and are protected under copyright, trademark, and other laws.

We authorize you, subject to this Agreement and Service-specific additional terms below, to access and use our Services and ESH Content solely for the use of our Services, at our discretion. Any other use is expressly prohibited. This license is revocable at any time without notice and with or without cause.


V. Your Limited Rights

Your Limited Rights.  Subject to your compliance with this Agreement, and solely for so long as you are permitted by ESH to use the ESH Sites, you may view one (1) copy of any portion of the ESH Sites to which we provide you access under this Agreement, on any single device, solely for your personal, non-commercial use.


VI. Service-Specific Additional Terms

This Agreement applies to the ESH services listed below, including K-12 Bridge to Broadband (https://www.educationsuperhighway.org/bridge-to-broadband, https://map.educationsuperhighway.org/), and any related web-based platforms, products, mobile applications or Internet services under ESH’s control, whether partial or otherwise, in connection with providing such services (each, a “Service”).

1. K-12 Bridge to Broadband Additional Terms of Service

“K-12 Bridge to Broadband” means ESH’s online, web-based data exchange platform relating to programs for connecting student households for remote learning that is accessible through https://www.educationsuperhighway.org/bridge-to-broadband.  To use K-12 Bridge to Broadband, you must accept the Agreement and these K-12 Bridge to Broadband Additional Terms of Service (together, the “K-12 Bridge to Broadband Terms”).

1. License. Subject to your compliance with these K-12 Bridge to Broadband Terms, the Agreement gives you a license to use K-12 Bridge to Broadband.

2. Prohibited Conduct. Your compliance with this section is a condition of your license to use K-12 Bridge to Broadband. When using K-12 Bridge to Broadband, you may not (or allow those acting on your behalf to): (a) remove, disable, circumvent, or otherwise interfere with the security of K-12 Bridge to Broadband; (b) interfere or attempt to interfere with the proper working of K-12 Bridge to Broadband; (c) gain unauthorized access to K-12 Bridge to Broadband or computers linked to K-12 Bridge to Broadband; (d) attempt to or submit a virus to K-12 Bridge to Broadband; (e) take any action imposing an unreasonable or disproportionately large load on ESH’s infrastructure; (f) attempt to or obtain unauthorized access to other users’ accounts; (g) use K-12 Bridge to Broadband in a manner that results in or may result in complaints, disputes, claims, fines, penalties, and other liability to ESH or others; (h) submit incomplete, false, or misleading information; (i) disclose personal information about another person; or (j) use K-12 Bridge to Broadband in an illegal way or to commit an illegal act.


2. Data Mapping Tool Additional Terms of Service

“Data Mapping Tool” means ESH’s service to allow users to visualize homes of students that lack connectivity and potential broadband solutions for those homes that is accessible through map.educationsuperhighway.org. To use the Data Mapping Tool, you must accept the Agreement and these Data Mapping Tool Additional Terms of Service (together, the “Data Mapping Tool Terms”).

1. License. Subject to your compliance with these Data Mapping Tool Terms, the Agreement gives you a license to use the Data Mapping Tool.

2. Prohibited Conduct. Your compliance with this section is a condition of your license to use the Data Mapping Tool. When using the Data Mapping Tool, you may not (or allow those acting on your behalf to): (a) remove, disable, circumvent, or otherwise interfere with the security of the Data Mapping Tool; (b) interfere or attempt to interfere with the proper working of the Data Mapping Tool; (c) gain unauthorized access to the Data Mapping Tool or computers linked to the Data Mapping Tool; (d) attempt to or submit a virus to the Data Mapping Tool; (e) take any action imposing an unreasonable or disproportionately large load on ESH’s infrastructure; (f) attempt to or obtain unauthorized access to other users’ accounts; (g) use the Data Mapping Tool in a manner that results in or may result in complaints, disputes, claims, fines, penalties, and other liability to ESH or others; (h) submit incomplete, false, or misleading information; (i) disclose personal information about another person; (j) use the Data Mapping Tool in an illegal way or to commit an illegal act; or (k) use the Data Mapping Tool in violation of the Mapbox Terms of Service at https://www.mapbox.com/legal/tos or Service Terms at https://www.mapbox.com/legal/service-terms.

3. Government End User Rights. You agree that all software and documentation included in the Data Mapping Tool and any derivatives thereof are commercial items, as defined in 48 C.F.R. § 2.101 and any similar provisions in the applicable jurisdiction, and constitute commercial computer software and commercial computer software documentation, as defined in 48 C.F.R. § 252.227-7014(a)(1) and as used in 48 C.F.R. §§ 12.212 and 227.7202, or similar provisions in the applicable jurisdiction. You further acknowledge and agree that all software and documentation included in the Data Mapping Tool were developed entirely at private expense and that no part of the Data Mapping Tool was first produced in the performance of a contract with any governmental authority. If you are a governmental authority or an employee thereof (acting in capacity as such), this commercial computer software and commercial computer software documentation is provided for use with only those rights as are granted to all other end users pursuant to the terms and conditions of this Agreement, consistent with 48 C.F.R. §§ 12.212, 227.7202-1, and 227.7202-3, and their substantial equivalents in other jurisdictions. The terms and conditions of this Agreement apply to you except to the extent you, as a governmental authority or employee thereof, is prohibited by the laws of your jurisdiction from accepting such terms and conditions. If and to the extent any provision of this Agreement is so prohibited, that provision shall be deemed modified only to the extent reasonably necessary to conform to applicable law but to give maximum effect to the Agreement as written.


VII. Feedback

We appreciate your comments, but if you send us your creative ideas, we can use them without compensating you.

We appreciate hearing from our users and welcome your comments regarding ESH Sites. Please be advised, however, that if you provide to us any ideas, proposals, suggestions or other materials (“Feedback”), whether related to the ESH Sites or otherwise, you hereby acknowledge and agree that such Feedback is not confidential, and that your provision of such Feedback is gratuitous, unsolicited and without restriction, and does not place Company under any fiduciary or other obligation. For each Feedback, you hereby grant to us a worldwide, royalty-free, fully paid-up, non-exclusive, perpetual, irrevocable, transferable and fully sublicensable (through multiple tiers) license, without additional consideration to you or any third party, to reproduce, distribute, perform and display (publicly or otherwise), create derivative works of, adapt, modify and otherwise use, analyze and exploit such Feedback, in any format or media now known or hereafter developed, and for any purpose (including promotional purposes, such as testimonials).


VIII. Disclaimers, Limitations, and Prohibitions

Prohibited Uses of ESH Sites

Do not do inappropriate things with ESH Sites, try to break it, or steal our hard work.

You agree to use ESH Sites only for its intended purpose. You must use the ESH Sites in compliance with all privacy, data protection, intellectual property, and other applicable laws. The following uses of the ESH Sites are prohibited.

In connection with the ESH Sites, you must not:

1. Post, transmit or otherwise make available through or in connection with the ESH Sites any materials that are or may be: (a) threatening, harassing, degrading, hateful or intimidating, or otherwise fail to respect the rights and dignity of others; (b) defamatory, libelous, fraudulent or otherwise tortious; (c) obscene, indecent, pornographic or otherwise objectionable; or (d) protected by copyright, trademark, trade secret, right of publicity or privacy or any other proprietary right, without the express prior written consent of the applicable owner.
2. Post, transmit or otherwise make available through or in connection with the ESH Sites any virus, worm, Trojan horse, Easter egg, time bomb, spyware or other computer code, file or program that is or is potentially harmful or invasive or intended to damage or hijack the operation of, or to monitor the use of, any hardware, software or equipment (each, a “Virus”).
3. Use the ESH Sites for any commercial purpose, or for any purpose that is fraudulent or otherwise tortious or unlawful.
4. Harvest or collect information about users of the ESH Sites.
5. Interfere with or disrupt the operation of the ESH Sites or the servers or networks used to make the ESH Sites available, including by hacking or defacing any portion of the ESH Sites; or violate any requirement, procedure or policy of such servers or networks.
6. Restrict or inhibit any other person from using the ESH Sites.
7. Reproduce, modify, adapt, translate, create derivative works of, sell, rent, lease, loan, timeshare, distribute or otherwise exploit any portion of (or any use of) the ESH Sites except as expressly authorized herein, without our express prior written consent.
8. Reverse engineer, decompile or disassemble any portion of the ESH Sites, except where such restriction is expressly prohibited by applicable law.
9. Remove any copyright, trademark or other proprietary rights notice from the ESH Sites.
10 Frame or mirror any portion of the ESH Sites, or otherwise incorporate any portion of the ESH Sites into any product or service, without our express prior written consent.
11. Systematically download and store ESH Sites content.
12. Use any robot, spider, site search/retrieval application or other manual or automatic device to retrieve, index, “scrape,” “data mine” or otherwise gather ESH Sites content, or reproduce or circumvent the navigational structure or presentation of the ESH Sites, without our express prior written consent.
13. You are responsible for obtaining, maintaining and paying for all hardware and all telecommunications and other services needed to use the ESH Sites.


IX. Consequences of Violating This Agreement

If you don’t act acceptably, we may refuse to provide ESH Sites to you, or terminate or suspend your use of the ESH Sites.

We reserve the right to prevent access to ESH Products and Services for any reason, at our discretion. We reserve the right to refuse to provide the ESH Sites to you in the future. Perpetual licenses granted by you to ESH remain in full force and effect.

ESH may terminate or suspend your use of the ESH Sites at any time and without prior notice, for any or no reason, including if ESH believes that you have violated or acted inconsistently with this Agreement. Upon any such termination or suspension, your right to use the ESH Sites will immediately cease, and ESH may, without liability to you or any third party, immediately deactivate or delete your user name, password and account, and all associated materials, without any obligation to provide any further access to such materials.

We may review and remove data we collect from the ESH Sites at any time for any reason, including activity which, in our sole judgment: violates this Agreement; violates applicable laws, rules, or regulations; is abusive, disruptive, offensive or illegal; or violates the rights of, or harms or threatens the safety of, users of ESH Sites.

You are responsible for any claims, fees, fines, penalties, and other liability incurred by us or others caused by or arising out of your breach of this Agreement and your use of ESH Sites.


X. ESH’s Liability

We are not liable for anything our users do when using ESH Sites. Although we will try to let you know ahead of time, we may change ESH Sites and are not liable for how this may affect you. Also, while we cannot guarantee perfect accuracy, we are doing our best to make content available on ESH Sites as accurate as possible.

1. Changes to ESH Sites. We may change, suspend, or discontinue any aspect of ESH Sites at any time, including hours of operation or availability of the ESH Sites or any feature, without notice or liability.

2. User Disputes. We are not responsible for any disputes or disagreements between you and any third party you interact with using ESH Sites. You assume all risk associated with dealing with third parties. You agree to resolve disputes directly with the other party. You release ESH of all claims, demands, and damages in disputes among users of ESH Sites. You also agree not to involve us in such disputes. Use caution and common sense when using ESH Sites.

3. Content Accuracy. We make no representations about accuracy, reliability, completeness, or timeliness of any contents of ESH Sites. Similarly, we make no representations about accuracy, reliability, completeness, or timeliness of any data from a third-party service provider or the quality or nature of third-party products or services obtained through ESH Sites. Use the ESH Sites at your own risk.

4. Third-Party Websites. ESH Sites may include links to third party websites and applications. You are responsible for evaluating whether you want to access or use them. We are not responsible for and do not endorse any features, content, advertising, products, or other materials on other websites or applications. You assume all risk and we disclaim all liability arising from your use of them.


1. DISCLAIMER OF WARRANTIES

We make no promises and disclaim all liability of specific results from the use of ESH Sites.

Released Parties Defined. “Released Parties” include ESH and its affiliates, officers, employees, agents, partners, and licensors.

Your use of ESH Sites is at your own risk.

YOU EXPRESSLY UNDERSTAND AND AGREE THAT: (A) YOUR USE OF ESH PRODUCTS AND SERVICES IS AT YOUR SOLE RISK, AND ESH PRODUCTS AND SERVICES ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS AND THE RELEASED PARTIES EXPRESSLY DISCLAIM ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, WARRANTIES AS TO PRODUCTS OR SERVICES OFFERED BY BUSINESSES LISTED ON ESH PRODUCTS AND SERVICES, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT; (B) THE RELEASED PARTIES MAKE NO WARRANTY THAT (i) ESH PRODUCTS AND SERVICES WILL MEET YOUR REQUIREMENTS, (ii) ESH PRODUCTS AND SERVICES WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE, (iii) THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF ESH PRODUCTS AND SERVICES WILL BE ACCURATE OR RELIABLE, (iv) THE QUALITY OF ANY GOODS OR SERVICE AVAILABLE ON ESH PRODUCTS AND SERVICES WILL MEET YOUR EXPECTATIONS AND, AND (v) ANY ERRORS IN THE SERVICE WILL BE CORRECTED; AND (C) ANY MATERIAL DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF ESH PRODUCTS AND SERVICES IS ACCESSED AT YOUR OWN DISCRETION AND RISK, AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR MOBILE DEVICE OR LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OR USE OF ANY SUCH MATERIAL.


2. LIMITATION OF LIABILITY AND INDEMNIFICATION

Unexpected things happen sometimes. We are not liable for anything that happens to you that somehow may be connected to your use of ESH Sites. Also, if you use ESH Sites in a way that causes us to get sued, you agree to pay all of our legal expenses.

YOU EXPRESSLY UNDERSTAND AND AGREE THAT THE RELEASED PARTIES WILL NOT BE LIABLE TO YOU FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES, INCLUDING, BUT NOT LIMITED TO, DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, DATA OR OTHER INTANGIBLE LOSSES (EVEN IF ESH HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES), RESULTING FROM: (i) THE USE OR THE INABILITY TO USE ESH PRODUCTS AND SERVICES; (ii) THE COST OF PROCUREMENT OF SUBSTITUTE GOODS AND SERVICES RESULTING FROM ANY GOODS, DATA, INFORMATION OR SERVICES OBTAINED OR TRANSACTIONS ENTERED INTO THROUGH, FROM, OR AS A RESULT OF ESH PRODUCTS AND SERVICES; (iii) UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR TRANSMISSIONS OR DATA; (iv) STATEMENTS OR CONDUCT OF ANY USER OR THIRD PARTY ON ESH PRODUCTS AND SERVICES; (v) YOUR RELIANCE ON CONTENT MADE AVAILABLE BY US; OR (vi) ANY OTHER MATTER RELATING TO ESH PRODUCTS AND SERVICES. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES OR THE LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES. ACCORDINGLY, SOME OF THE ABOVE LIMITATIONS IN THIS PARAGRAPH MAY NOT APPLY TO YOU.

TO THE FULLEST EXTENT POSSIBLE BY LAW, OUR MAXIMUM LIABILITY ARISING OUT OF OR IN CONNECTION WITH ESH PRODUCTS AND SERVICES OR YOUR USE OF ESH CONTENT, REGARDLESS OF THE CAUSE OF ACTION (WHETHER IN CONTRACT, TORT, BREACH OF WARRANTY, OR OTHERWISE), WILL NOT EXCEED $100.

You agree to defend, indemnify, and hold harmless the Released Parties from and against any claims, actions, or demands, including without limitation reasonable legal and accounting fees, alleging or resulting from (i) your use of or reliance on any third-party content, (ii) your use of or reliance on any ESH Content, or (iii) your breach of this Agreement. We will provide notice to you promptly of any such claim, suit, or proceeding.


XI. General Terms

This Agreement constitutes the entire agreement between you and ESH concerning your use of ESH Sites. Our failure to exercise or enforce any right or provision of this Agreement will not constitute a waiver of such right or provision. If any provision of this Agreement is found by a court of competent jurisdiction to be invalid, the parties nevertheless agree that the court should endeavor to give effect to the parties’ intentions as reflected in the provision, and the other provisions of this Agreement remain in full force and effect.

The section titles and annotations in this Agreement are for convenience only and have no legal or contractual effect.


XII. Arbitration, Class Waiver, and Waiver of Jury Trial

The terms of this Agreement are governed by the laws of the United States (including federal arbitration law) and the State of California, U.S.A., without regard to its principles of conflicts of law, and regardless of your location. EXCEPT FOR DISPUTES THAT QUALIFY FOR SMALL CLAIMS COURT, ALL DISPUTES ARISING OUT OF OR RELATED TO THIS AGREEMENT OR ANY ASPECT OF THE RELATIONSHIP BETWEEN YOU AND ESH, WHETHER BASED IN CONTRACT, TORT, STATUTE, FRAUD, MISREPRESENTATION OR ANY OTHER LEGAL THEORY, WILL BE RESOLVED THROUGH FINAL AND BINDING ARBITRATION BEFORE A NEUTRAL ARBITRATOR INSTEAD OF IN A COURT BY A JUDGE OR JURY AND YOU AGREE THAT ESH AND YOU ARE EACH WAIVING THE RIGHT TO TRIAL BY A JURY. SUCH DISPUTES INCLUDE, WITHOUT LIMITATION, DISPUTES ARISING OUT OF OR RELATING TO INTERPRETATION OR APPLICATION OF THIS ARBITRATION PROVISION, INCLUDING THE ENFORCEABILITY, REVOCABILITY OR VALIDITY OF THE ARBITRATION PROVISION OR ANY PORTION OF THE ARBITRATION PROVISION. ALL SUCH MATTERS WILL BE DECIDED BY AN ARBITRATOR AND NOT BY A COURT OR JUDGE.

YOU AGREE THAT ANY ARBITRATION UNDER THIS AGREEMENT WILL TAKE PLACE ON AN INDIVIDUAL BASIS; CLASS ARBITRATIONS AND CLASS ACTIONS ARE NOT PERMITTED AND YOU ARE AGREEING TO GIVE UP THE ABILITY TO PARTICIPATE IN A CLASS ACTION.

The arbitration will be administered by the American Arbitration Association under its Consumer Arbitration Rules, as amended by this Agreement. The Consumer Arbitration Rules are available online at https://www.adr.org/sites/default/files/Consumer%20Rules.pdf. The arbitrator will conduct hearings, if any, by teleconference or videoconference, rather than by personal appearances, unless the arbitrator determines upon request by you or by us that an in-person hearing is appropriate. Any in-person appearances will be held at a location which is reasonably convenient to both parties with due consideration of their ability to travel and other pertinent circumstances. If the parties are unable to agree on a location, such determination should be made by the AAA or by the arbitrator. The arbitrator’s decision will follow the terms of this Agreement and will be final and binding. The arbitrator will have authority to award temporary, interim or permanent injunctive relief or relief providing for specific performance of this Agreement, but only to the extent necessary to provide relief warranted by the individual claim before the arbitrator. The award rendered by the arbitrator may be confirmed and enforced in any court having jurisdiction thereof. Notwithstanding any of the foregoing, nothing in this Agreement will preclude you from bringing issues to the attention of federal, state or local agencies and, if the law allows, they can seek relief against us for you. 


XIII. Jurisdictional Issues

The ESH Sites are controlled and operated from the United States, and is not intended to subject ESH to any non-U.S. jurisdiction or law. The ESH Sites may not be appropriate or available for use in some non-U.S. jurisdictions. Any use of the ESH Sites is at your own risk, and you must comply with all applicable laws, rules and regulations in doing so. We may limit the ESH Sites’ availability at any time, in whole or in part, to any person, geographic area or jurisdiction that we choose.


XIV. Contact Information

If you have a question or comment regarding the ESH Sites, please send an email to legal@educationsuperhighway.org. You may also contact us by writing at the below address.

EducationSuperHighway
6 Presidio Terrace
San Francisco, CA 94118

Please note that e-mail communications will not necessarily be secure; accordingly you should not include credit card information or other sensitive information in your e-mail correspondence with us.`

export default function TermsAndConditionsAgreement () {
  const classes = useStyles()
  return (
    <Box mt={2} className={classes.termsAndConditionsBox}>
      <Typography variant="body1"><strong>TERMS AND CONDITIONS</strong></Typography>
      <TextareaAutosize
        className={classes.termsAndConditions}
        readOnly
        rowsMax={12}
        aria-label="terms and conditions"
        defaultValue={termsAndConditionsText}
      />
    </Box>
  )
}
