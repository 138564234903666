import React from 'react'
import {
  Typography,
  Paper,
  makeStyles,
  Button
} from '@material-ui/core'

export default function UploadAnywayMessage ({ open, setOpen, setCsvErrors, setAgreedToFixErrors }) {
  const useStyles = makeStyles((theme) => ({
    message: {
      display: open ? 'block' : 'none',
      padding: theme.spacing(2),
      background: 'rgba(251, 187, 11, .2)',
    },
    button: {
      backgroundColor: theme.palette.success.main,
      color: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.success.active,
      },
      fontWeight: 'bold',
      margin: theme.spacing(1),
      textTransform: 'none',
    }
  }))
  const classes = useStyles()
  const handleOnErrorFix = () => {
    setAgreedToFixErrors(true)
    setOpen(false)
  }
  const handleOnIgnore = () => {
    setCsvErrors([])
    setOpen(false)
  }
  return (
    <Paper variant="outlined" elevation={2} className={classes.message}>
      <Typography variant="body1">
        This file contains errors (noted below in red).
        <br />
        You can either:
        <br />
        <li>fix those errors before trying your upload again</li>
        <li>choose to ignore the errors and upload the file as is</li>
        <em>Any rows in the file with errors will be ignored by our process and those addresses will not be included in your data exchange.</em>
      </Typography>
      <Button className={classes.button} onClick={handleOnErrorFix}>
        I&apos;ll fix the errors
      </Button>
      <Button className={classes.button} onClick={handleOnIgnore}>
        Ignore the errors and upload the file as is
      </Button>
    </Paper>
  )
}
