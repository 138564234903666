import React from 'react'
import Grid from '@material-ui/core/Grid'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import EditDataButton from '../editDataButton'
import { formatNumberForDisplay } from '../../../utils/stringUtils'
import TopProviders from './topProviders'

export default function EntityBanner ({ entityName, adminOrOwnerScoped, accessToken, studentCounts, providers }) {
  return (
    <Header
      name={entityName}
      metrics={studentCounts}
      providers={providers}
      adminOrOwnerScoped={adminOrOwnerScoped}
      accessToken={accessToken}
    />
  )
}

export function Header ({ name, metrics, providers, adminOrOwnerScoped, accessToken }) {
  return (
    <Grid container direction="row" justify="space-between" alignItems="center" spacing={4}>
      <Grid item xs={12} sm={6}>
        <EntitySubHeader name={name} metrics={metrics} adminOrOwnerScoped={adminOrOwnerScoped} accessToken={accessToken} mapBanner />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TopProviders providers={providers} accessToken={accessToken} />
      </Grid>
    </Grid>
  )
}

export function EntitySubHeader ({ name, metrics, adminOrOwnerScoped, accessToken, mapBanner }) {
  const useStyles = makeStyles((theme) => ({
    divider: {
      borderLeft: '2px solid lightgray',
      height: 24,
      margin: '6px 12px'
    },
    center: {
      textAlign: 'center'
    },
    entityNameHeader: {
      fontSize: 40,
      padding: theme.spacing(2),
      paddingLeft: 0
    },
    dateUploaded: {
      color: theme.palette.text.tertiary,
      fontSize: '12px',
      marginBottom: '-1rem',
      display: 'block'
    }
  }))
  const classes = useStyles()
  if (!metrics) return null
  return (
    <Grid>
      <Typography className={classes.entityNameHeader}>
        {name}
      </Typography>
      <Typography className={classes.dateUploaded}>
        Date uploaded:
        {' '}
        {new Date(metrics.data_upload_date).toLocaleDateString()}
      </Typography>
      <br />
      <Grid container justify="flex-start">
        <Grid item>
          <Typography variant="h6" className={classes.center}>
            <strong>{`${formatNumberForDisplay(metrics.num_students)}`}</strong>
          </Typography>
          <Typography variant="body2" className={classes.center}>
            Students
          </Typography>
        </Grid>
        <Grid item><div className={classes.divider} /></Grid>
        <Grid item>
          <Typography variant="h6" className={classes.center}>
            <strong>{`${formatNumberForDisplay(metrics.num_addresses)}`}</strong>
          </Typography>
          <Typography variant="body2" className={classes.center}>
            Households
          </Typography>
        </Grid>
        { Number(metrics.num_households_not_found) > 0 && (
        <>
          <Grid item><div className={classes.divider} /></Grid>
          <Tooltip title={<span style={{ fontSize: 14 }}>{`The addresses uploaded contained ${metrics.num_households_not_found} students for whom we could not find a geolocation. We have provided the addresses to the person who uploaded the data for research and review. Reach out to us if you need assistance clarifying the addresses so that they can be added to the data set.`}</span>} placement="top-start">
            <div>
              <Typography variant="h6" className={classes.center}>
                <strong>{`${formatNumberForDisplay(metrics.num_households_not_found)}`}</strong>
              </Typography>
              <Typography variant="body2" className={classes.center}>
                Not found
              </Typography>
            </div>
          </Tooltip>
        </>
        )}
      </Grid>
      <br />
      {adminOrOwnerScoped && mapBanner && <EditDataButton entityName={name} entityNumStudents={metrics.num_students} accessToken={accessToken} />}
    </Grid>
  )
}
