import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import SaveAltIcon from '@material-ui/icons/SaveAlt'
import { selectUserInfo } from '../../../redux/selectors'
import { fetchEntityDownloadLink } from '../../../data/fetchData'
import CSVDownloadDialog from '../../dialogs/CSVDownloadDialog'

const useStyles = makeStyles((theme) => ({
  button: {
    color: theme.palette.primary.main,
    background: theme.palette.info.main,
    marginTop: theme.spacing(2),
    width: 320,
    '&:hover': {
      backgroundColor: theme.palette.info.active,
    }
  }
}))

export default function CSVDownload ({ entityUID, accessToken, csvDataLink, setCsvDataLink, program }) {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const userInfo = useSelector(selectUserInfo)

  const handleButtonClick = async () => {
    if (entityUID && accessToken) {
      setOpen(true)
      await fetchEntityDownloadLink({ entityUID, accessToken, setCsvDataLink, program })
    }
  }
  if (userInfo.role === 'eshAdmin') return null
  return (
    <>
      <Button
        size="medium"
        variant="contained"
        className={classes.button}
        endIcon={<SaveAltIcon />}
        onClick={handleButtonClick}
      >
        Download Connectivity Data
      </Button>
      <br />
      <CSVDownloadDialog csvDataLink={csvDataLink} open={open} setOpen={setOpen} program={program} />
    </>
  )
}
