import React, { useState, useCallback } from 'react'
import clsx from 'clsx'
import { useDropzone } from 'react-dropzone'
import { Box, Button, ListItem, ListItemIcon, ListItemText, Typography, makeStyles } from '@material-ui/core'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import { parse } from 'papaparse'
import bytesToSize from '../../../utils/unitHelpers'
import fileDropImage from '../../../assets/undraw_add_file2_gvbb.svg'
import { findCsvErrors } from './handlers'
import { SUPPORTED_FILE_FORMATS } from './validators'
import SmallLoadingScreen from '../../pageDecorations/loadingScreen/SmallLoadingScreen'

const parseCSV = ({ csvString }) => parse(csvString, parserOptions)

export default function CSVDropZone ({ setFieldValue, setCsvErrors }) {
  const classes = useStyles();
  const [file, setFile] = useState();
  const [isFileProcessing, setIsFileProcessing] = useState(false)

  const handleDrop = useCallback(async (acceptedFiles) => {
    setIsFileProcessing(true)
    setCsvErrors([])
    const acceptedFile = acceptedFiles[0]
    setFieldValue('csvFile', acceptedFile)
    setFieldValue('csvFileName', acceptedFile.name)
    const csvString = await acceptedFile.text()
    const csvData = await parseCSV({ csvString })
    const invalidRows = findCsvErrors(csvData.data)
    if (invalidRows.length) setCsvErrors(invalidRows)
    setFile(acceptedFile)
    setIsFileProcessing(false)
  }, [setCsvErrors, setFieldValue])

  const handleRemoveAll = () => {
    setFieldValue('csvFileName', '')
    setFieldValue('csvFile', null)
    setFile()
    setCsvErrors([])
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleDrop,
    accept: SUPPORTED_FILE_FORMATS
  })

  if (isFileProcessing) {
    return (
      <div>
        <SmallLoadingScreen />
      </div>
    )
  }

  return (
    <div className={clsx(classes.root)}>
      <div className={clsx({ [classes.dropZone]: true, [classes.dragActive]: isDragActive })} {...getRootProps()}>
        <input name="csvFile" {...getInputProps()} />
        <div>
          <img alt="Select file" className={classes.image} src={fileDropImage} />
        </div>
        <div>
          <Typography gutterBottom variant="h5">
            Select file
          </Typography>
          <Box mt={2}>
            <Typography color="textPrimary" variant="body1">
              Drop csv file here or click
              <Button className={classes.browseLink}>browse</Button>
              through your machine.
              <br />
              Please make sure that your filename ends with .csv
            </Typography>
          </Box>
        </div>
      </div>
      {file && (
        <>
          <ListItem>
            <ListItemIcon>
              <FileCopyIcon />
            </ListItemIcon>
            <ListItemText primary={file.name} primaryTypographyProps={{ variant: 'h5' }} secondary={bytesToSize(file.size)} />
          </ListItem>
          <div className={classes.actions}>
            <Button onClick={handleRemoveAll} size="small" color="secondary">
              Remove CSV
            </Button>
          </div>
        </>
      )}
    </div>
  )
}

const parserOptions = {
  header: true,
  dynamicTyping: false,
  skipEmptyLines: true,
  transformHeader: (header) => header.trim().toLowerCase().replace(/\W/g, '_')
}

const useStyles = makeStyles((theme) => ({
  root: {},
  dropZone: {
    border: `1px dashed ${theme.palette.divider}`,
    padding: theme.spacing(6),
    outline: 'none',
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
      opacity: 0.5,
      cursor: 'pointer'
    }
  },
  dragActive: {
    backgroundColor: theme.palette.action.active,
    opacity: 0.5
  },
  image: {
    width: 130
  },
  info: {
    marginTop: theme.spacing(1)
  },
  list: {
    maxHeight: 320
  },
  browseLink: {
    margin: 0,
    paddingBottom: theme.spacing(1.3),
    fontSize: '1rem',
    color: theme.palette.secondary.main,
    textTransform: 'lowercase',
    textDecoration: 'underline'
  },
  actions: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'flex-end',
    '& > * + *': {
      marginLeft: theme.spacing(2)
    }
  }
}))
