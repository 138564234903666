import React from 'react'

export default function PageNotFound404 () {
  return (
    <div className="PageNotFound404" style={{ height: '80vh' }}>
      <h3>Page Not Found</h3>
      <p>We cannot find a page for the URL you have entered.</p>
    </div>
  )
}
