import ReactDOMServer from 'react-dom/server'
import React from 'react'
import { Chart, Series, CommonSeriesSettings, Legend, ValueAxis, Label, Grid, Tick, Tooltip } from 'devextreme-react/chart'
import { Tooltip as MuiTooltip, Typography, Box } from '@material-ui/core'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { formatNumber, formatPercent } from '../../../../utils/stringUtils'

export default function TopProvidersChart ({ providers, entityName, numUnconnectedInternetAvailableStudents }) {
  if (!providers) return null
  const data = transformProvidersForChart({ providers, numUnconnectedInternetAvailableStudents })
  const topProvidersData = data[0]
  const otherProvidersData = data[1]
  const useStyles = makeStyles((theme) => ({
    purpleText: {
      color: theme.palette.secondary.main
    },
    otherProvidersText: {
      '&:hover': {
        cursor: 'pointer'
      }
    }
  }))
  const StyledTooltip = withStyles({
    arrow: {
      '&:before': {
        border: '1px solid #E6E8ED'
      },
      color: '#fff'
    },
    tooltip: {
      color: '#222',
      backgroundColor: '#fff',
      border: '1px solid #E6E8ED',
      fontSize: '1em',
      fontFamily: 'Lato',
      whiteSpace: 'pre-wrap'
    }
  })(MuiTooltip)
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const classes = useStyles()
  return (
    <>
      <Chart
        dataSource={topProvidersData}
        palette={['#FBBB0B', '#494949']}
      >
        <CommonSeriesSettings argumentField="name" type="bar" />
        <ValueAxis visible={false}>
          <Label visible={false} />
          <Grid visible={false} />
          <Tick visible={false} />
        </ValueAxis>
        <Series
          name="Unconnected Students: Internet Available"
          valueField="Unconnected Students: Internet Available"
        >
          <Label
            visible
            customizeText={(arg) => customizeText(arg, topProvidersData)}
            font={{
              family: 'Lato',
              weight: 600,
              size: 16
            }}
          />
        </Series>
        <Legend visible={false} />
        <Tooltip
          enabled
          zIndex={1}
          customizeTooltip={(arg) => customizeTooltip(arg, topProvidersData, entityName)}
        />
      </Chart>
      <br />
      <br />
      {Array.isArray(otherProvidersData) && otherProvidersData.length >= 1 && (
      <StyledTooltip
        placement="top-start"
        arrow
        title={(
          <>
            <Typography fontWeight="bold">
              Other Participating Providers
            </Typography>
            <ul>
              {
                otherProvidersData.map((provider, i) => (
                  <li key={`other-provider-${i}`}>
                    {`${provider.fullName} can serve ${formatNumber(provider['Unconnected Students: Internet Available'])} students.`}
                  </li>
                ))
              }
            </ul>
          </>
        )}
      >
        <div>
          <Box>
            <Typography className={classes.otherProvidersText}>
              <strong className={classes.purpleText}>
                {' '}
                {otherProvidersData.length}
                {' '}
                other participating
                {' '}
                {otherProvidersData.length === 1 ? 'provider' : 'providers'}
                {' '}
              </strong>
              can serve fewer than
              {' '}
              {topProvidersData[4].percentText.replace('< ', '')}
              {' '}
              of unconnected students apiece.
            </Typography>
          </Box>
        </div>
      </StyledTooltip>
      )}
    </>
  )
}

const transformProvidersForChart = ({ providers, numUnconnectedInternetAvailableStudents }) => {
  const providersArray = providers.filter((provider) => !provider.form477)
    .map((provider) => ({
      name: provider.doing_business_as.length > 7 ? `${provider.doing_business_as.substring(0, 7)}...` : provider.doing_business_as,
      fullName: provider.doing_business_as,
      'Unconnected Students: Internet Available': parseInt(provider.number_of_students_served, 10),
      numberOfAddressesServed: parseInt(provider.number_of_addresses_served, 10),
      percentText: formatPercent(Number(provider.number_of_students_served / numUnconnectedInternetAvailableStudents))
    }))
  if (providersArray.length <= 5) {
    return [providersArray]
  }
  return [providersArray.slice(0, 5), providersArray.slice(5)]
}

function customizeTooltip (arg, data, entityName) {
  return {
    html: ReactDOMServer.renderToString(CustomizeTooltip(arg, data, entityName))
  }
}

function CustomizeTooltip (arg, data, entityName) {
  const { argumentText } = arg
  const dataForProvider = data.find((coverageMetric) => coverageMetric.name === argumentText)
  const smallTextStyle = { fontFamily: 'Lato', fontSize: '1em' }
  return (
    <>
      <h6 style={{ fontFamily: 'Lato' }}><strong>{dataForProvider.fullName}</strong></h6>
      <pre style={smallTextStyle}>
        {
        `can serve ${formatNumber(dataForProvider['Unconnected Students: Internet Available'])} students (in ${formatNumber(dataForProvider.numberOfAddressesServed)} households), representing ${dataForProvider.percentText} of the
unconnected students with Internet Available in ${entityName}`
        }
      </pre>
    </>
  );
}

function customizeText (arg, data) {
  const { argumentText } = arg
  const dataForProvider = data.find((coverageMetric) => coverageMetric.name === argumentText)
  return dataForProvider.percentText
}
