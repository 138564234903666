import { createSlice } from '@reduxjs/toolkit'

export const initialState = {}

const exceptionStates = ['WI', 'NE']
// List of districts within exceptionStates that should not result in isStateExceptionUser = true
const allowedDistricts = ['madison metro school district']

const slice = createSlice({
  name: 'userInfo',
  initialState,
  reducers: {
    setInitial: (state, action) => {
      const { userInfoData } = action.payload
      Object.assign(state, userInfoData)
      const assignedEntityNames = state.associatedentities?.map((entity) => {
        const response = entity?.entityName?.toLowerCase()?.trim()
        // eslint-disable-next-line no-console
        return response || console.log(`Entity error! data missing for entity: ${JSON.stringify(entity, null, 4)}`)
      })
        .filter(Boolean)
      state.isStateExceptionUser = exceptionStates.includes(state.state_code) && !allowedDistricts.some((district) => assignedEntityNames?.includes(district))
    }
  }
})

export const {
  setInitial
} = slice.actions

export default slice.reducer
