import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import { selectUserInfo } from '../../../redux/selectors'
import EditDataDialog from '../../dialogs/EditDataDialog'

const useStyles = makeStyles((theme) => ({
  button: {
    background: theme.palette.secondary.light
  }
}))

function EditDataButton ({ entityName, entityNumStudents }) {
  const [open, setOpen] = useState(false)
  const classes = useStyles()
  const userInfo = useSelector(selectUserInfo)
  if (!userInfo || userInfo.isStateExceptionUser) return null
  return (
    <>
      <Button
        size="large"
        variant="contained"
        className={classes.button}
        onClick={() => setOpen(!open)}
      >
        &#9998; EDIT STUDENT DATA
      </Button>
      <EditDataDialog open={open} setOpen={setOpen} entityName={entityName} entityNumStudents={entityNumStudents} />
    </>
  )
}

EditDataButton.defaultProps = {
  entity: {}
}

export default EditDataButton
