import React, { useState } from 'react'
import { Button
} from '@material-ui/core'
import { Share as ShareIcon } from '@material-ui/icons'
import EntityShareDialog from '../../../dialogs/EntityShareDialog'

function EntityShareButton ({ entity }) {
  const [open, setOpen] = useState(false)

  const handleOpen = (event) => {
    event.preventDefault()
    setOpen(true)
  }

  return (
    <div>
      <Button
        onClick={handleOpen}
        variant="text"
        size="small"
        startIcon={<ShareIcon />}
      >
        share
      </Button>
      <EntityShareDialog entity={entity} open={open} setOpen={setOpen} />
    </div>
  )
}

EntityShareButton.defaultProps = {
  entity: {}
}

export default EntityShareButton
