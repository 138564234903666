import React from 'react'
import {
  Typography,
  TextField,
  Dialog,
  IconButton,
  makeStyles,
  DialogTitle as MuiDialogTitle,
  DialogActions as MuiDialogActions,
  DialogContent as MuiDialogContent,
  Button,
  ButtonGroup,
  Box
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import LogRocket from 'logrocket'
import EntitiesTypeahead from '../../typeaheads/entitiesTypeahead'
import DropDownMenu from './DropDownMenu'
import { updateUser } from '../../../data/fetchData'

export default function UserDialog ({ subjectUserId, subjectUserRole, accessToken, dialogUser, setDialogUser, open, setOpen }) {
  const classes = useStyles()
  const handleOnSave = () => {
    try {
      const body = dialogUser
      updateUser(dialogUser.user_id, body, accessToken)
      window.location.reload()
    } catch (error) {
      LogRocket.captureException(error)
    }
  }
  const handleOnClose = () => {
    setOpen(false)
    setDialogUser(null)
  }
  return (
    <Dialog onClose={() => setOpen(false)} aria-labelledby="modal-to-update-user-admin" open={open}>
      <DialogTitle id="modal-to-update-user-admin" onClose={handleOnClose}>
        User:
        {' '}
        {dialogUser.user_name || dialogUser.email}
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1">{displayUneditableUserAttributes(dialogUser)}</Typography>
        <Box key="user_name">
          <span className={classes.labelText}>
            <strong>
              user_name
              :
            </strong>
            {' '}
          </span>
          <TextField
            multiline
            defaultValue={dialogUser.user_name}
            onChange={(e) => setDialogUser({ ...dialogUser, user_name: e.target.value })}
          />
        </Box>
        {['validation_status', 'role', 'state_code'].map((attribute) => (
          <Box key={`${attribute}`}>
            <span className={classes.labelText}>
              <strong>
                {`${attribute}`}
                :
              </strong>
              {' '}
            </span>
            <DropDownMenu
              subjectUserId={subjectUserId}
              subjectUserRole={subjectUserRole}
              userToUpdate={dialogUser}
              setUserToUpdate={setDialogUser}
              accessToken={accessToken}
              attribute={attribute}
            />
          </Box>
        ))}
        <span className={classes.labelText}>
          <strong>entities:</strong>
          {' '}
        </span>
        <EntitiesTypeahead entities={dialogUser.entities} user={dialogUser} accessToken={accessToken} />
      </DialogContent>
      <DialogActions>
        <ButtonGroup className={classes.buttonGroup} aria-label="save">
          <Button onClick={handleOnClose}>
            Cancel
          </Button>
          <Button onClick={handleOnSave}>
            Save
          </Button>
        </ButtonGroup>
      </DialogActions>
    </Dialog>
  )
}

const displayUneditableUserAttributes = (user) => {
  if (!user) return
  const uneditableAttributes = ['email', 'email_verified']
  // eslint-disable-next-line consistent-return
  return Object.entries(user).map((properties) => {
    if (!uneditableAttributes.includes(properties[0])) return null
    return (
      <Box key={`user-attributes-${properties[0]}`}>
        <strong>
          <span>{properties[0]}</span>
          :
        </strong>
        {' '}
        <span>{properties[1] && properties[1].toString()}</span>
      </Box>
    )
  })
}

const titleStyles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
})

const DialogTitle = withStyles(titleStyles)((props) => {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 'auto',
    fontSize: theme.typography.h6.fontSize,
    color: theme.palette.success.main,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions)

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent)

const useStyles = makeStyles((theme) => ({
  form: {
    marginTop: theme.spacing(4)
  },
  buttonGroup: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4)
  },
  labelText: {
    fontSize: '1rem'
  }
}))
