import { useRef, useEffect } from 'react'

export default function useIsMountedRef () {
  const isMountedRef = useRef(null)
  useEffect(() => {
    isMountedRef.current = true
    return function () {
      isMountedRef.current = false
      return isMountedRef.current
    }
  })
  return isMountedRef
}
