import React from 'react';
import _ from 'lodash'
import { withStyles, Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { formatPercent } from '../../../../utils/stringUtils'

const StyledTableCell = withStyles((theme: Theme) => createStyles({
  head: {
    backgroundColor: '#494949',
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}),)(TableCell);

const StyledTableRow = withStyles((theme: Theme) => createStyles({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}),)(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

interface ServiceProviderTableProps {
  providers: Providers[];
  numStudentsInDistrict: number;
}

interface Providers {
  doing_business_as: string;
  service_types: Array<string>;
  number_of_students_served: string;
  form477: boolean;
}

export default function ServiceProviderTable ({ providers, numStudentsInDistrict }: ServiceProviderTableProps) {
  const classes = useStyles()

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="customized table" size="small">
        <TableHead>
          <TableRow>
            <StyledTableCell><strong>PROVIDER</strong></StyledTableCell>
            <StyledTableCell align="left"><strong>TECHNOLOGY</strong></StyledTableCell>
            <StyledTableCell align="right"><strong>POTENTIAL STUDENTS SERVED</strong></StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {providers.map((row) => (
            <StyledTableRow key={`${row.doing_business_as}-${row.service_types}-${row.number_of_students_served}`}>
              <StyledTableCell component="th" scope="row">
                {row.doing_business_as}
              </StyledTableCell>
              <StyledTableCell align="left">{_.uniq(row.service_types).join(', ')}</StyledTableCell>
              <StyledTableCell align="right">{formatPercent(Number(row.number_of_students_served) / numStudentsInDistrict)}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
