import React from 'react'
import AddEntities from './AddEntities'
import EditEntities from './EditEntities'

export default function AddOrEditEntities ({ userInfo }) {
  return (
    <>
      <AddEntities accessToken={userInfo.accessToken} />
      <EditEntities accessToken={userInfo.accessToken} />
    </>
  )
}
