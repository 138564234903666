import React from 'react'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import SignIn from '../../pageDecorations/signIn'
import { useAuth0 } from '../../../react-auth0-spa'

export default function LoggedOutLandingPage () {
  const { isAuthenticated } = useAuth0()
  const theme = useTheme()
  const [isImgLoaded, setIsImgLoaded] = React.useState(false)
  const belowLgBreakPoint = useMediaQuery(theme.breakpoints.down('lg'))
  const aboveMdBreakPoint = useMediaQuery(theme.breakpoints.up('md'))
  const belowMdBreakPoint = useMediaQuery(theme.breakpoints.down('md'))
  const belowSmBreakPoint = useMediaQuery(theme.breakpoints.down('sm'))
  const belowXsBreakPoint = useMediaQuery(theme.breakpoints.down('xs'))
  const useStyles = makeStyles((theme) => ({
    container: {
      marginTop: theme.spacing(3)
    },
    header: {
      marginBottom: theme.spacing(3)
    },
    button: {
      color: theme.palette.primary.main,
      fontWeight: 'bold',
      background: theme.palette.success.main,
      margin: belowLgBreakPoint ? theme.spacing(0, 3) : 0,
      '&:hover': {
        backgroundColor: theme.palette.success.active,
        color: theme.palette.primary.main,
      }
    },
    link: {
      fontWeight: 'bold',
      color: theme.palette.success.main
    },
    banner: {
      display: (belowXsBreakPoint || !isImgLoaded) ? 'none' : 'block',
      width: '100vw',
      margin: '0 auto',
      marginTop: belowMdBreakPoint ? theme.spacing(-6) : theme.spacing(-3),
      opacity: isImgLoaded ? 1 : 0
    },
    placeholderBanner: {
      background: '#FFE7AE',
      visibility: isImgLoaded ? 'hidden' : 'visible',
      height: isImgLoaded ? 0 : 240,
      display: belowXsBreakPoint ? 'none' : 'block',
      width: '100vw',
      margin: '0 auto',
      marginTop: belowMdBreakPoint ? theme.spacing(-6) : theme.spacing(-1)
    },
    overview: {
      margin: '0 auto',
      marginTop: aboveMdBreakPoint ? theme.spacing(4) : 0,
      flexWrap: belowMdBreakPoint ? 'wrap' : 'nowrap',
      minHeight: '45vh'
    },
    video: {
      height: belowXsBreakPoint ? 200 : 300,
      width: belowXsBreakPoint ? 300 : 550,
      margin: '24px 0px'
    },
    footerText: {
      fontStyle: 'italic',
      textAlign: 'center'
    },
    smScreenAndBelow: {
      margin: belowXsBreakPoint ? '0px 12px' : '0% 25%'
    }
  }))
  const classes = useStyles()
  return (
    <>
      <Grid container justify="center" alignItems="center">
        <div className={classes.placeholderBanner}>{' '}</div>
        <img onLoad={() => { setIsImgLoaded(true) }} src="https://digital-bridge-assets.s3.us-west-1.amazonaws.com/LoggedOutBanner.jpg" alt="banner" className={classes.banner} />
      </Grid>
      <Grid container direction="row" justify="space-between" alignItems="start" spacing={6} className={classes.overview}>
        <Grid item sm={12} md={7} lg={8}>
          <Typography variant="body1">
            <br />
            If your school district has already identified students who lack home broadband, our mapping tool lets you visualize student connectivity data and find Internet service provider options for unconnected households.
            <br />
            <br />
            The tool gives you access to a map and a data file with Internet Service Provider coverage options for every student home in your district according to publicly available FCC data.
          </Typography>
          <br />
          <br />
          <Grid container direction="row" className={classes.overview}>
            <Grid item>
              <Typography variant="h4">How does it work?</Typography>
              <Typography variant="body1">
                <br />
                <ul>
                  <li>Upload your data file that includes student addresses and digital equity data that you have collected.</li>
                  <li>View an overlay of residential broadband coverage, based on publicly available FCC data.</li>
                  <li>Download a comprehensive list of provider coverage available for each address.</li>
                </ul>
              </Typography>
              <Button
                href="/demo"
                size="large"
                variant="contained"
                className={classes.button}
              >
                VISIT DEMO
              </Button>
              <Typography variant="body1" className={classes.footerText}>
                <br />
                <br />
                <br />
                If you have not identified your unconnected students,
                {' '}
                <a className={classes.link} href="https://www.educationsuperhighway.org/needs-assessment-playbook/">use our tools and resources</a>
                {' '}
                to support your data collection, or explore how Internet Service Providers can provide this data to your school district using our
                {' '}
                <a className={classes.link} href="https://www.educationsuperhighway.org/bridge-to-broadband/">K-12 Bridge to Broadband</a>
                {' '}
                program.
                <br />
                <br />
                If you would like assistance,
                {' '}
                <a className={classes.link} href="https://educationsuperhighway.zendesk.com">contact us.</a>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={12} md={5} lg={4} className={belowSmBreakPoint ? classes.smScreenAndBelow : null}>
          <SignIn isAuthenticated={isAuthenticated} />
          <br />
        </Grid>
      </Grid>
    </>
  )
}
