import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import PerfectScrollbar from 'react-perfect-scrollbar'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'
import {
  Box,
  Card,
  Divider,
  InputAdornment,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  makeStyles,
  Typography
} from '@material-ui/core'
import { Search as SearchIcon } from '@material-ui/icons'
import EntityShareButton from './EntityShareButton'
import { transformEntityName } from '../../../../utils/constants'

/* eslint-disable max-lines */
/* eslint-disable radix */
const sortOptions = [
  {
    value: 'name|asc',
    label: 'Name (ASC)'
  },
  {
    value: 'name|desc',
    label: 'Name (DESC)'
  }
]

const applyFilters = (entities, query) => entities.filter((entity) => {
  let matches = true

  if (query) {
    const properties = ['name']
    let containsQuery = false

    properties.forEach((property) => {
      if (entity[property].toLowerCase().includes(query.toLowerCase())) {
        containsQuery = true
      }
    })

    if (!containsQuery) {
      matches = false
    }
  }

  return matches
})

const applyPagination = (entities, page, limit) => entities.slice(page * limit, page * limit + limit)

const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }

  if (b[orderBy] > a[orderBy]) {
    return 1
  }

  return 0
}

const getComparator = (order, orderBy) => (order === 'desc'
  ? (a, b) => descendingComparator(a, b, orderBy)
  : (a, b) => -descendingComparator(a, b, orderBy))

const applySort = (entities, sort) => {
  const [orderBy, order] = sort.split('|')
  const comparator = getComparator(order, orderBy)
  const stabilizedThis = entities.map((el, index) => [el, index])

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])

    if (order !== 0) return order

    return a[1] - b[1]
  })

  return stabilizedThis.map((el) => el[0])
}

const useStyles = makeStyles(() => ({
  root: {},
  queryField: {
    width: 500
  }
}))

function EntityLinkTable ({ entities, showUsers }) {
  const classes = useStyles()
  const [page, setPage] = useState(0)
  const [limit, setLimit] = useState(10)
  const [query, setQuery] = useState('')
  const [sort, setSort] = useState(sortOptions[0].value)
  const theme = useTheme()
  const aboveSmallBreakpoint = useMediaQuery(theme.breakpoints.up('sm'))
  if (!entities[0].entity_uid) return null

  const handleQueryChange = (event) => {
    event.persist()
    setQuery(event.target.value)
  }

  const handleSortChange = (event) => {
    event.persist()
    setSort(event.target.value)
  }

  const handlePageChange = (event, newPage) => {
    setPage(newPage)
  }

  const handleLimitChange = (event) => {
    setLimit(parseInt(event.target.value))
  }

  const filteredEntities = applyFilters(entities, query)
  const sortedEntities = applySort(filteredEntities, sort)
  const paginatedEntities = applyPagination(sortedEntities, page, limit).filter(Boolean)
  return (
    <Card>
      <Divider />
      <Box
        p={2}
        minHeight={56}
        display="flex"
        alignItems="center"
      >
        <TextField
          className={classes.queryField}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SvgIcon
                  fontSize="small"
                  color="action"
                >
                  <SearchIcon />
                </SvgIcon>
              </InputAdornment>
            )
          }}
          onChange={handleQueryChange}
          placeholder="Search entities"
          value={query}
          variant="outlined"
        />
        <Box flexGrow={1} />
        <TextField
          label="Sort By"
          name="sort"
          onChange={handleSortChange}
          select
          SelectProps={{ native: true }}
          value={sort}
          variant="outlined"
        >
          {sortOptions.map((option) => (
            <option
              key={option.value}
              value={option.value}
            >
              {option.label}
            </option>
          ))}
        </TextField>
      </Box>
      <PerfectScrollbar>
        <Box minWidth={700}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  Entity Name
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedEntities.map((entity) => (
                <TableRow
                  hover
                  key={entity.entity_uid}
                >
                  <TableCell>
                    <Box
                      display="flex"
                      flexDirection={aboveSmallBreakpoint ? 'row' : 'column'}
                      justifyContent="flex-start"
                      alignItems={aboveSmallBreakpoint ? 'center' : 'flex-start'}
                    >
                      <div>
                        <Link
                          color="inherit"
                          to={`/entities/${entity.entity_uid}`}
                          variant="h6"
                        >
                          {`${transformEntityName(entity.name)} (${entity.state_code})` }
                        </Link>
                      </div>
                      { showUsers ? (
                        <Typography variant="body2">
                          <strong>
                            {'- '}
                            Users:
                            {' '}
                          </strong>
                          {entity.associated_users.replace(/.*\{|\}/gi, '')}
                        </Typography>
                      ) : <EntityShareButton entity={entity} /> }
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={entities.length}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card>
  )
}

EntityLinkTable.defaultProps = {
  entities: []
}

export default EntityLinkTable
