// src/utils/history.js

// adds error logging
import LogRocket from 'logrocket'
import setupLogRocketReact from 'logrocket-react'

export default function initLogRocket () {
  if (process.env.NODE_ENV === 'production') {
    LogRocket.init(process.env.REACT_APP_LOG_ROCKET_APP_ID)
    setupLogRocketReact(LogRocket)
  }
}

// after calling LogRocket.init()
