import React from 'react'
import { useLocation } from 'react-router-dom'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import signupLogo from '../../../assets/ESH-logomark.png'
import { isDemo } from '../../../utils/routes'
import { useAuth0 } from '../../../react-auth0-spa'

export default function SignIn () {
  const { isAuthenticated, loginWithRedirect } = useAuth0()
  const location = useLocation()
  const demo = isDemo(location)
  const classes = useStyles()

  if (isAuthenticated || demo) {
    return null
  }
  return (
    <Paper elevation={4} className={classes.root}>
      <Typography align="center">
        <img
          style={{ 'width': 90 }}
          alt="logo"
          src={signupLogo}
        />
      </Typography>
      <br />
      <Button
        className={classes.button}
        onClick={() => loginWithRedirect({})}
        size="large"
        variant="contained"
      >
        Log In
      </Button>
    </Paper>
  )
}

const useStyles = makeStyles((theme) => ({
  button: {
    align: 'center',
    margin: theme.spacing(1, 6),
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    background: theme.palette.success.main,
    width: 180,
    '&:hover': {
      backgroundColor: theme.palette.success.active,
    }
  },
  link: {
    fontWeight: 'bold',
    color: theme.palette.success.main
  },
  root: {
    padding: theme.spacing(2),
    width: 300
  }
}))
