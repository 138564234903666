import React from 'react'
import _ from 'lodash'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import TextField from '@material-ui/core/TextField'
import { Box } from '@material-ui/core'
import useMediaQuery from '@material-ui/core/useMediaQuery'

// TODO: add a test?
export default function ProvidersDropdown ({ layerIds, providers, selectedProvider, onSelectedProvider, map, setUnconnectedNoInternetChecked }) {
  const theme = useTheme()
  const aboveSmallBreakpoint = useMediaQuery(theme.breakpoints.up('sm'))
  const useStyles = makeStyles(() => ({
    formControl: {
      minWidth: 165,
      maxWidth: aboveSmallBreakpoint ? 300 : 220
    }
  }))
  const classes = useStyles()

  const selectValue = (selectedProvider && selectedProvider.doing_business_as) || ''
  if (!providers || providers.length === 0) return null
  return (
    <Box>
      <FormControl variant="filled" className={classes.formControl}>
        <TextField
          label="Filter by provider"
          name="filter-providers"
          select
          value={selectValue}
          variant="outlined"
          InputLabelProps={{
            style: { color: theme.palette.primary.dark }
          }}
        >
          {
            renderDropdownItems({ providers, onSelectedProvider, map, layerIds, setUnconnectedNoInternetChecked })
          }
        </TextField>
      </FormControl>
    </Box>
  )
}

function renderDropdownItems ({ providers, onSelectedProvider, map, layerIds, setUnconnectedNoInternetChecked }) {
  return (
    providers.map((provider) => {
      const doingBusinessAs = provider.doing_business_as
      return (
        <MenuItem
          key={`${provider.frn}-${doingBusinessAs}`}
          value={doingBusinessAs}
          onClick={() => onDropdownButtonClick({ layerIds, map, onSelectedProvider, provider, setUnconnectedNoInternetChecked })}
        >
          {`${doingBusinessAs} ${provider.service_types[0] ? `(${_.uniq(provider.service_types).join(', ')})` : ''}`}
        </MenuItem>
      )
    })
  )
}

const onDropdownButtonClick = ({ layerIds, map, onSelectedProvider, provider, setUnconnectedNoInternetChecked }) => {
  layerIds.forEach((layerID) => hideAllExceptSelectedProvidersLayers({ provider, layerID, map, setUnconnectedNoInternetChecked }))
  onSelectedProvider(provider)
}

const hideAllExceptSelectedProvidersLayers = ({ provider, layerID, map, setUnconnectedNoInternetChecked }) => {
  if (map.getLayer(layerID)) {
    map.setLayoutProperty(
      layerID,
      'visibility',
      layerID.includes(provider.service_provider_id) ? 'visible' : 'none'
    )
  }
  map.setLayoutProperty('unconnected-point', 'visibility', 'none')
  setUnconnectedNoInternetChecked(false)
}
