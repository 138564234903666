/* eslint-disable max-lines */
/* eslint-disable no-dupe-keys */
import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Button, Card, CardContent, CardHeader, CircularProgress, Divider, Grid, TextField, MenuItem, FormHelperText } from '@material-ui/core'
import * as Yup from 'yup'
import { Formik } from 'formik'
import { useSelector } from 'react-redux'
import { isEmpty } from 'lodash'
import { fetchEntityToEdit, editEntity } from '../../../../data/fetchData'
import { statesInfoNoTerritories, entityTypes } from '../../../../utils/constants'
import AddressSearchTypeahead from '../../../typeaheads/addressSearchTypeahead'
import { selectUserInfo } from '../../../../redux/selectors'
import EntitytNameTypeahead from '../../../typeaheads/entityNameTypeahead'

const ncesValidator = (val) => {
  if (!val) return true
  return val.length === 7
}

export default function EditEntities ({ accessToken }) {
  const userInfo = useSelector(selectUserInfo)
  const classes = useStyles()
  const [typeahedEntity, setTypeaheadEntity] = useState({})

  const onSelectEntity = async (entity, setFieldValue) => {
    setTypeaheadEntity(entity)
    if (isEmpty(entity)) {
      setFieldValue('entityType', '')
      setFieldValue('ncesCode', '')
      setFieldValue('stateCode', '')
      setFieldValue('entityUID', '')
      setFieldValue('name', '')
      setFieldValue('coordinates', [])
      setFieldValue('optInExchange', false)
      setFieldValue('digitalEquity', false)
      setFieldValue('entityId', '')
      setFieldValue('hideDashboard', '')
    } else {
      const result = await fetchEntityToEdit({ entityUID: entity.entity_uid, accessToken })
      setFieldValue('entityType', result.entity_type)
      setFieldValue('ncesCode', result.nces_code)
      setFieldValue('stateCode', result.state_code)
      setFieldValue('optInExchange', result.opt_in_exchange)
      setFieldValue('digitalEquity', result.digital_equity)
      setFieldValue('entityId', result.entity_id)
      setFieldValue('entityUID', result.entity_uid)
      setFieldValue('name', result.name)
      setFieldValue('coordinates', [result.longitude, result.latitude])
      setFieldValue('hideDashboard', result.hide_dashboard)
    }
  }

  return (
    <Formik
      initialValues={{ name: '', ncesCode: '', stateCode: '', entityType: '', entityId: '', entityUID: '', name: '', optInExchange: false, digitalEquity: false, coordinates: [], hideDashboard: false }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required('An entity name is required'),
        ncesCode: Yup.string().nullable().test('len', 'NCES code must be exactly 7 characters', (val) => ncesValidator(val)),
        stateCode: Yup.string().required('A state code is required'),
        entityType: Yup.string().required('An entity type is required'),
        coordinates: Yup.array().length(2).required('A valid entity address is required')
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting, resetForm }) => {
        try {
          const entity = {
            name: values.name,
            ncesCode: values.ncesCode,
            coordinates: values.coordinates,
            stateCode: values.stateCode,
            entityType: values.entityType,
            optInExchange: values.optInExchange,
            digitalEquity: values.digitalEquity,
            entityUID: values.entityUID,
            hideDashboard: values.hideDashboard
          }
          await editEntity(entity, accessToken)
          setTypeaheadEntity({})
          setStatus({ success: true })
          setSubmitting(false)
          resetForm()
        } catch (err) {
          /* eslint no-console: "off" */
          console.error(err);
          setStatus({ success: false });
          setErrors({ submit: err.message });
          setSubmitting(false);
        }
      }}
    >
      {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, setFieldValue, touched, values, submitCount }) => (
        <Card raised>
          <CardHeader title="Edit an existing entity" />
          <Divider />
          <CardContent>
            {isSubmitting ? (
              <Box
                display="flex"
                justifyContent="center"
                my={5}
              >
                <CircularProgress className={classes.circularProgress} />
              </Box>
            ) : (
              <form onSubmit={handleSubmit}>
                <Grid
                  container
                  spacing={2}
                >
                  <Grid
                    item
                    md={12}
                    xs={12}
                  >
                    <EntitytNameTypeahead setValue={onSelectEntity} value={typeahedEntity} handleBlur={handleBlur} setFieldValue={setFieldValue} touched={touched} userInfo={userInfo} errors={errors} values={values} submitCount={submitCount} />
                  </Grid>
                  <Grid
                    item
                    md={6}
                    xs={12}
                  >
                    {values.entityUID && (
                    <TextField
                      fullWidth
                      label="New entity name"
                      name="name"
                      onBlur={handleBlur}
                      value={values.name}
                      onChange={handleChange('name')}
                      variant="outlined"
                    />
                    )}
                  </Grid>
                  <Grid
                    item
                    md={6}
                    xs={12}
                  >
                    {
                    values.entityUID && values.entityType === 'district' && (
                    <TextField
                      error={Boolean(touched.ncesCode && errors.ncesCode)}
                      fullWidth
                      helperText={touched.ncesCode && errors.ncesCode}
                      label="New nces code"
                      name="ncesCode"
                      onBlur={handleBlur}
                      value={values.ncesCode}
                      onChange={handleChange('ncesCode')}
                      variant="outlined"
                    />
                    )
                  }
                  </Grid>
                  <Grid item md={12}>
                    {
                    values.entityUID && values.entityType === 'district' && (
                    <AddressSearchTypeahead
                      entityUID={values.entityUID}
                      accessToken={accessToken}
                      setValue={(coordinates) => {
                        setFieldValue('coordinates', coordinates)
                      }}
                    />
                    )
                    }
                    {values.entityUID && errors.coordinates && touched.coordinates && <FormHelperText error={Boolean(errors.coordinates)} id="my-helper-text">Must select an address</FormHelperText>}
                  </Grid>
                  {
                    values.entityUID && (
                    <Grid container spacing={4}>
                      <Grid item md={2} xs={2}>
                        <TextField
                          id="stateCode"
                          select
                          label="State Code"
                          className={classes.textField}
                          error={Boolean(values.entityUID && touched.stateCode && errors.stateCode)}
                          value={values.stateCode}
                          onChange={handleChange('stateCode')}
                          margin="normal"
                          fullWidth
                          variant="filled"
                        >
                          {Object.keys(statesInfoNoTerritories).map((stateCode) => (
                            <MenuItem key={stateCode} value={stateCode}>
                              {stateCode}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid item md={2} xs={2}>
                        <TextField
                          id="entityType"
                          select
                          label="Entity Type"
                          error={Boolean(values.entityUID && touched.entityType && errors.entityType)}
                          className={classes.textField}
                          value={values.entityType}
                          onChange={handleChange('entityType')}
                          margin="normal"
                          fullWidth
                          variant="filled"
                        >
                          {entityTypes.map((entityType) => (
                            <MenuItem key={entityType.label} value={entityType.value}>
                              {entityType.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid item md={2} xs={2}>
                        <TextField
                          id="optInExchange"
                          select
                          label="Opt Into Data Exchange"
                          className={classes.textField}
                          value={values.optInExchange}
                          onChange={handleChange('optInExchange')}
                          margin="normal"
                          fullWidth
                          variant="filled"
                        >
                          {[{ label: 'TRUE', value: true }, { label: 'FALSE', value: false }].map((optInExchange) => (
                            <MenuItem key={optInExchange.label} value={optInExchange.value}>
                              {optInExchange.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid item md={2} xs={2}>
                        <TextField
                          id="digitalEquity"
                          select
                          label="Opt Into Digital Equity"
                          className={classes.textField}
                          value={values.digitalEquity}
                          onChange={handleChange('digitalEquity')}
                          margin="normal"
                          fullWidth
                          variant="filled"
                        >
                          {[{ label: 'TRUE', value: true }, { label: 'FALSE', value: false }].map((digitalEquity) => (
                            <MenuItem key={digitalEquity.label} value={digitalEquity.value}>
                              {digitalEquity.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid item md={2} xs={2}>
                        <TextField
                          id="entityId"
                          label="Entity ID"
                          className={classes.textField}
                          value={values.entityId}
                          margin="normal"
                          fullWidth
                          variant="filled"
                        />
                      </Grid>
                      <Grid item md={2} xs={2}>
                        <TextField
                          id="hideDashboard"
                          select
                          label="Hide Dashboard"
                          className={classes.textField}
                          value={values.hideDashboard}
                          onChange={handleChange('hideDashboard')}
                          margin="normal"
                          fullWidth
                          variant="filled"
                        >
                          {[{ label: 'TRUE', value: true }, { label: 'FALSE', value: false }].map((hideDashboard) => (
                            <MenuItem key={hideDashboard.label} value={hideDashboard.value}>
                              {hideDashboard.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                    </Grid>
                    )
                }
                </Grid>

                <Box mt={2}>
                  <Button
                    className={classes.submitButton}
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    EDIT ENTITY
                  </Button>
                </Box>
              </form>
            )}
          </CardContent>
        </Card>
      )}
    </Formik>
  )
}

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(3),
    paddingTop: theme.spacing(5)
  },
  textField: {
    fontSize: '18px'
  },
  labelText: {
    color: 'rgba(0, 0, 0, 0.54)',
    margin: 0,
    padding: 0
  },
  error: {
    color: theme.palette.error.main
  },
  submitButton: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.success.active,
    }
  },
  circularProgress: {
    color: theme.palette.success.main
  }
}))
