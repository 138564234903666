import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Box, Button, Grid, Paper } from '@material-ui/core'
import SaveAltIcon from '@material-ui/icons/SaveAlt'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import EntityPageInfo from '../entityPage/EntityPageInfo'
import Number1Yellow from '../../../assets/Number-1-circle_Yellow.svg'
import Number2Yellow from '../../../assets/Number-2-circle_Yellow.svg'
import { selectUserInfo } from '../../../redux/selectors'

const useStyles = makeStyles((theme) => ({
  dataExchangePanel: {
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(12),
    paddingBottom: theme.spacing(8),
    borderRadius: '25px'
  },
  headerTitle: {
    fontSize: '26px',
    fontWeight: 'bold',
    marginBottom: theme.spacing(2),
    zIndex: 3,
    position: 'relative',
  },
  titleText: {
    marginBottom: theme.spacing(4),
    marginRight: theme.spacing(10)
  },
  regText: {
    marginRight: theme.spacing(10)
  },
  boldText: {
    fontSize: '14px',
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(4),
    fontWeight: 'bold',
    marginRight: theme.spacing(10)
  },
  topNumber: {
    width: '75px',
    height: '75px',
    top: '26px',
    right: '46px',
    position: 'relative',
    opacity: '50%'
  },
  smallNumber: {
    width: '40px',
    height: '40px',
    top: '20px',
    right: '20px',
    position: 'relative',
  },
  smallNumberImg: {
    position: 'absolute',
    zIndex: 3
  },
  link: {
    color: theme.palette.success.main,
    fontWeight: 'bold'
  },
  buttonDownloadTemplate: {
    color: theme.palette.primary.main,
    background: theme.palette.info.main,
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    '&:hover': {
      backgroundColor: theme.palette.info.active,
      color: theme.palette.primary.main,
    }
  },
  templateTitleText: {
    marginBottom: theme.spacing(2),
    fontWeight: 'bold',
    fontSize: '14px',
  },
  templateText: {
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(1),
    fontSize: '14px',
  }
}))

export default function Step1 () {
  document.title = 'K12 Bridge to Broadband: Prepare Data'
  const classes = useStyles()
  const userInfo = useSelector(selectUserInfo)
  if (!userInfo.num_data_exchange_entities) {
    return null
  }

  return (
    <div style={{ 'minHeight': '75vh' }}>
      <Paper elevation={3} className={classes.dataExchangePanel}>
        <img alt="number1" src={Number1Yellow} className={classes.topNumber} />
        <Grid container spacing={1}>
          <Grid item xs={12} sm={9}>
            <Typography className={classes.headerTitle}>
              Prepare students’ addresses for the Data Exchange
            </Typography>
            <Typography className={classes.titleText}>
              Use our
              {' '}
              <a className={classes.link} href="https://digital-bridge-assets.s3.us-west-1.amazonaws.com/Student-Address-Data-Exchange-CSV-Template.csv">data upload template</a>
              {' '}
              to organize your school district&apos;s student addresses into a CSV (comma separated values) document.
              <br />
              Please read before preparing your data:
            </Typography>
            <Typography>
              <strong className={classes.link}>1.</strong>
              {' '}
              <strong>Remove the sample data (in rows 2 to 4) before submitting your file</strong>
            </Typography>
            <Typography>
              <strong className={classes.link}>2.</strong>
              {' '}
              <strong>Do not remove or change the name of the columns. These 6 columns must be included in the file:</strong>
            </Typography>
            <ul>
              <Typography variant="body1" className={classes.regText}>
                <li>Primary ID</li>
                <li>Street Address</li>
                <li>Unit (the column is required but a value is optional)</li>
                <li>City</li>
                <li>State (2 letter code)</li>
                <li>ZIP</li>
              </Typography>
            </ul>
            <Typography>
              <strong className={classes.link}>3.</strong>
              {' '}
              <strong>The Primary ID should be a key you can use to associate the address with a student in your School Information System. Never use the student&apos;s actual student ID as the Primary ID.</strong>
            </Typography>
            <Typography className={classes.boldText}>
              To request help formatting your data, create a ticket on our
              {' '}
              <a className={classes.link} href="https://educationsuperhighway.zendesk.com/hc/en-us">support page</a>
              .
            </Typography>
            <div>
              <div className={classes.smallNumber}>
                <img alt="number2" src={Number2Yellow} className={classes.smallNumberImg} />
              </div>
              <Box
                border={1}
                height={60}
                width={690}
                display="flex"
                justifyContent="center"
                alignItems="center"
                borderRadius={15}
                p={3}
              >
                <Typography>
                  Already have a .csv file ready to go in this format? Continue to the
                  {' '}
                  <Link to="/dataExchange/Step2" style={{ color: '#019297', fontWeight: 'bold' }}>
                    UPLOAD DATA
                  </Link>
                  {' '}
                  page.
                </Typography>
              </Box>
            </div>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Button
              href="https://digital-bridge-assets.s3.us-west-1.amazonaws.com/Student-Address-Data-Exchange-CSV-Template.csv"
              size="large"
              variant="contained"
              className={classes.buttonDownloadTemplate}
              endIcon={<SaveAltIcon />}
            >
              DOWNLOAD TEMPLATE
            </Button>
            <Typography className={classes.templateTitleText}>
              Avoid common upload errors
            </Typography>
            <ul>
              <Typography>
                <li className={classes.templateText}>Always provide the actual location for the student&apos;s home. PO Boxes cannot be used for the data exchange.</li>
                <li className={classes.templateText}>Always use an abbreviation that is recognized by the US Post Office. Many rural roads have multiple designations, e.g.: County Highway, State Highway, County Road, etc. </li>
                <li className={classes.templateText}>Do not remove the “Unit” column from the file even if your student addresses have no Apartment or Unit number. Simply leave this column blank.</li>
              </Typography>
            </ul>
          </Grid>
        </Grid>
      </Paper>
      <EntityPageInfo />
    </div>
  )
}
