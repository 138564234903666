import React from 'react'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Link from '@material-ui/core/Link'
import { makeStyles } from '@material-ui/core/styles'
import eshLogo from '../../../assets/ESH_Logo_Horizontal.png'

export default function Footer () {
  const useStyles = makeStyles((theme) => ({
    footer: {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.dark,
      height: 'auto',
      minHeight: '220px',
      marginTop: theme.spacing(3),
      paddingTop: theme.spacing(3),
      boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)'
    },
    grid: {
      paddingTop: theme.spacing(2)
    },
    link: {
      color: theme.palette.success.main
    },
    linkLeft: {
      color: theme.palette.primary.dark
    }
  }))
  const classes = useStyles()

  return (
    <footer className={classes.footer}>
      <Grid container spacing={3} justify="center" alignItems="center">
        <Grid className={classes.grid} container direction="column" justify="flex-start" alignItems="center" item xs={12} sm={6}>
          <Typography variant="h6" style={{ color: '#f26b21' }}>
            CONNECT
          </Typography>
          <Grid item>
            <Typography variant="body1">
              <Link href="https://twitter.com/EdSuperHighway/" className={classes.linkLeft}>
                Twitter
              </Link>
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1">
              <Link href="https://www.linkedin.com/company/2641118/admin/" className={classes.linkLeft}>
                LinkedIn
              </Link>
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1">
              <Link href="https://www.facebook.com/EducationSuperHighway" className={classes.linkLeft}>
                Facebook
              </Link>
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1">
              <Link href="mailto:info@educationsuperhighway.org" className={classes.linkLeft}>
                Contact
              </Link>
            </Typography>
          </Grid>
        </Grid>
        <Grid container item className={classes.grid} direction="column" justify="center" alignItems="center" xs={12} sm={6}>
          <Grid item>
            <Link href="https://educationsuperhighway.org">
              <img style={{ height: 50 }} alt="esh-logo-children-holding-hands" src={eshLogo} />
            </Link>
          </Grid>
          <Grid item>
            <Typography variant="body1">
              <Link href="https://www.educationsuperhighway.org/privacy/" className={classes.link}>
                Privacy Policy
              </Link>
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1">
              <Link href="https://www.educationsuperhighway.org/terms-of-service/" className={classes.link}>
                Terms of Use
              </Link>
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1">
              © 2021 EducationSuperHighway
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </footer>
  )
}
