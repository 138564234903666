/* eslint-disable max-lines */
import React from 'react'
import { Link } from 'react-router-dom'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import SaveAltIcon from '@material-ui/icons/SaveAlt'
import Box from '@material-ui/core/Box'
import useMediaQuery from '@material-ui/core/useMediaQuery'

const useStyles = makeStyles((theme) => ({
  buttonUploadPage: {
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.success.main,
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.success.active,
      color: theme.palette.primary.main,
    }
  },
  buttonDownloadTemplate: {
    color: theme.palette.primary.main,
    background: theme.palette.info.main,
    marginBottom: theme.spacing(2),
    '&:hover': {
      backgroundColor: theme.palette.info.active,
      color: theme.palette.primary.main,
    }
  },
  link: {
    color: theme.palette.success.main,
    fontWeight: 'bold'
  },
  text: {
    marginBottom: theme.spacing(2)
  }
}))

export function UploadPageButton ({ isUserValidated }) {
  const classes = useStyles()
  if (!isUserValidated) return null
  return (
    <Button
      className={classes.buttonUploadPage}
      size="large"
      variant="contained"
      component={Link}
      to="/upload"
    >
      DISTRICT DATA UPLOADER
    </Button>
  )
}

export function UploadTemplateBody () {
  const classes = useStyles()
  return (
    <>
      <Typography variant="body1">
        Use our
        {' '}
        <a className={classes.link} href="https://digital-bridge-assets.s3-us-west-1.amazonaws.com/Student-Address-Upload-CSV-Template.csv">data upload template</a>
        {' '}
        to organize your school district&apos;s student addresses into a CSV (comma separated values) document.
      </Typography>
      <br />
      <Button
        href="https://digital-bridge-assets.s3-us-west-1.amazonaws.com/Student-Address-Upload-CSV-Template.csv"
        size="large"
        variant="contained"
        className={classes.buttonDownloadTemplate}
        endIcon={<SaveAltIcon />}
      >
        DOWNLOAD TEMPLATE
      </Button>
      <Typography variant="body1">
        Please read before preparing your data:
      </Typography>
      <br />
      <Typography>
        <strong className={classes.link}>1.</strong>
        {' '}
        <strong>Remove the sample data (in rows 2 to 4) before submitting your file</strong>
      </Typography>
      <Typography>
        <strong className={classes.link}>2.</strong>
        {' '}
        <strong>Do not remove or change the name of the columns. These 6 columns must be included in the file:</strong>
      </Typography>
      <ul className={classes.text}>
        <Typography variant="body1">
          <li>Primary ID</li>
          <li>Street Address</li>
          <li>Unit (the column is required but a value is optional)</li>
          <li>City</li>
          <li>State (2 letter code)</li>
          <li>ZIP</li>
        </Typography>
      </ul>
      <Typography>
        <strong className={classes.link}>3.</strong>
        {' '}
        <strong>Values in these columns are optional (if unknown, leave blank):</strong>
      </Typography>
      <ul>
        <Typography variant="body1">
          <li>School</li>
          <li>Teacher</li>
          <li>Grade</li>
          <li>Device (Yes, No, True or False)</li>
          <li>Sufficient Internet (Yes, No, True or False)</li>
        </Typography>
      </ul>
      <Typography>
        <strong className={classes.link}>3.</strong>
        {' '}
        <strong>The Primary ID should be a key you can use to associate the address with a student in your School Information System.</strong>
        <br />
        <strong>Never use the student&apos;s actual student ID as the Primary ID.</strong>
      </Typography>
      <br />
      <Typography variant="body1">
        Need help with your upload?
        {' '}
        <a className={classes.link} href="https://educationsuperhighway.zendesk.com/hc/en-us">Contact a member of our team.</a>
        .
      </Typography>
      <br />
    </>
  )
}

export default function CSVTemplateDownload ({ isUserValidated }) {
  const theme = useTheme()
  const belowMdBreakPoint = useMediaQuery(theme.breakpoints.down('md'))
  const aboveLgBreakpoint = useMediaQuery(theme.breakpoints.up('xl'))
  const useStyles = makeStyles((theme) => ({
    root: {
      margin: theme.spacing(5),
      height: (aboveLgBreakpoint || belowMdBreakPoint) ? '70vh' : '50vh'
    },
    title: {
      marginBottom: theme.spacing(3),
      marginTop: theme.spacing(3),
      paddingTop: theme.spacing(5)
    }
  }))
  const classes = useStyles()
  return (
    <div style={{ 'minHeight': '75vh' }}>
      <Box className={classes.root}>
        <Typography variant="h4" className={classes.title}>Find Broadband Coverage</Typography>
        <Typography variant="body1">
          Upload student address data for your district to view providers who can serve your unconnected students.
        </Typography>
        <br />
        <UploadPageButton
          isUserValidated={isUserValidated}
          text="Get Started"
        />
      </Box>
    </div>
  )
}
