import mapboxgl from 'mapbox-gl'
import { addPopupOnClick } from './AddPopupBehaviour'

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN

// herein you'll find the layers added to our Map
const AddLayers = ({ map, providers, insufficientInternetExpression, noDeviceExpression, disguiseClusters }) => {
  // set up the colors for individual points
  const circleColorUnclustered = ['case',
    insufficientInternetExpression, '#d63447',
    noDeviceExpression, '#158acf',
    '#000'
  ]
  // set up colors for clusters. note that we duplicate the above if we're hiding the clusters
  const circleColorClustered = disguiseClusters ? circleColorUnclustered : ['case',
    ['>', ['get', 'insufficientInternet'], 0], '#ba8d93',
    '#000'
  ]
  addLayersForAllStudents({ map, circleColorClustered, circleColorUnclustered, disguiseClusters })
  addLayersForISPs({ map, providers, circleColorClustered, circleColorUnclustered, disguiseClusters })
}

const addLayersForAllStudents = ({ map, circleColorClustered, circleColorUnclustered, disguiseClusters }) => {
  const clusterFilter = ['has', 'point_count']
  const clusterId = 'clusters'
  const visibility = 'visible'
  const count = ['get', 'point_count']
  addClusterLayer({ map, clusterFilter, clusterId, visibility, count, circleColorClustered, disguiseClusters })
  const textField = '{point_count_abbreviated}'
  // only display numbers if we're not hiding the clusters
  if (!disguiseClusters) {
    const clusterCountId = 'cluster-count'
    addClusterCountLayer({ map, clusterFilter, clusterCountId, visibility, textField })
  }
  const unclusteredFilter = ['!', ['has', 'point_count']]
  const unclusteredId = 'unclustered-point'
  addUnclusteredPoint({ map, unclusteredFilter, unclusteredId, visibility, circleColorUnclustered })
  addPopupOnClick({ map, layerId: unclusteredId, disguiseClusters })
  addPopupOnClick({ map, layerId: clusterId, disguiseClusters })
}

const addClusterLayer = ({ map, clusterFilter, clusterId, visibility, count, circleColorClustered, disguiseClusters }) => {
  if (!map.getLayer(clusterId)) {
    map.addLayer({
      id: clusterId,
      type: 'circle',
      source: 'students',
      filter: clusterFilter,
      layout: { 'visibility': visibility },
      // draw clusters as points if they are meant to be disguised
      paint: disguiseClusters ? (
        {
          'circle-color': circleColorClustered,
          'circle-opacity': 0.6,
          'circle-radius': 4,
          'circle-stroke-width': 1,
          'circle-stroke-color': '#fff'
        }
      ) : (
        {
          'circle-color': circleColorClustered,
          'circle-opacity': 0.4,
          'circle-radius': [
            'step', count, 20, 100, 30, 750, 40
          ]
        }
      )
    })
  }
}

const addClusterCountLayer = ({ map, clusterFilter, clusterCountId, visibility, textField }) => {
  if (!map.getLayer(clusterCountId)) {
    map.addLayer({
      id: clusterCountId,
      type: 'symbol',
      source: 'students',
      filter: clusterFilter,
      layout: {
        'visibility': visibility,
        'text-field': textField,
        'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
        'text-size': 12
      }
    })
  }
}

const addUnclusteredPoint = ({ map, unclusteredFilter, unclusteredId, visibility, circleColorUnclustered }) => {
  if (!map.getLayer(unclusteredId)) {
    map.addLayer({
      id: unclusteredId,
      type: 'circle',
      source: 'students',
      filter: unclusteredFilter,
      layout: { 'visibility': visibility },
      paint: {
        'circle-color': circleColorUnclustered,
        'circle-opacity': 0.6,
        'circle-radius': 4,
        'circle-stroke-width': 1,
        'circle-stroke-color': '#fff'
      }
    })
  }
}

const addLayersForISPs = ({ map, providers, circleColorClustered, circleColorUnclustered, disguiseClusters }) => {
  const serviceProviderIds = providers.map((provider) => provider.service_provider_id)
  serviceProviderIds.forEach((serviceProviderId) => {
    serviceProviderId = serviceProviderId.toString()
    const clusterFilter = ['all', ['>', ['get', serviceProviderId], 0], ['==', ['get', 'cluster'], true]]
    const clusterId = `clusters-${serviceProviderId}`
    const visibility = 'none'
    const count = ['get', serviceProviderId]
    addClusterLayer({ map, clusterFilter, clusterId, visibility, count, circleColorClustered, disguiseClusters })
    const textField = ['number-format', ['get', serviceProviderId], {}]
    // only display numbers when not disguising clusters as points
    if (!disguiseClusters) {
      const clusterCountId = `cluster-count-${serviceProviderId}`
      addClusterCountLayer({ map, clusterFilter, clusterCountId, visibility, textField })
    }
    const unclusteredFilter = ['all', ['!', ['has', 'point_count']], ['in', Number(serviceProviderId), ['get', 'serviceProviderIds']]]
    const unclusteredId = `unclustered-point-${serviceProviderId}`
    addUnclusteredPoint({ map, unclusteredFilter, unclusteredId, visibility, circleColorUnclustered })
    addPopupOnClick({ map, layerId: unclusteredId, disguiseClusters })
  })
}

export default AddLayers
