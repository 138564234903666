import _ from 'lodash'
import { findErrors } from './validators'
import { findLocationParamValue } from '../../../utils/routes'

const validateRow = (student, indx, data, invalidRows) => {
  const csvRow = indx + 2
  let errorMessage = findErrors({ student })
  if (!errorMessage) return
  errorMessage = `Error on CSV row ${csvRow}: ${errorMessage}`
  invalidRows.push(errorMessage)
}

export const findCsvErrors = (data) => {
  const csvErrors = []
  const oldKeysToNewKeys = findOldKeysToNewKeys(Object.keys(data[0]))
  data = data.map((row) => transformKeys(row, oldKeysToNewKeys))
  data.forEach((student, indx) => {
    validateRow(student, indx, data, csvErrors)
  })
  return csvErrors
}

export const findEntityUIDFromURL = () => {
  const windowLocationSearch = window.location.search
  const targetParam = 'entityUID'
  return findLocationParamValue({ windowLocationSearch, targetParam })
}

const transformKeys = (row, oldKeysToNewKeys) => _.transform(row, (result, value, oldKey) => {
  result[oldKeysToNewKeys[oldKey]] = value
  return result
}, {})

const findOldKeysToNewKeys = (keys) => keys.reduce((memo, current) => {
  let newKey = current.trim().toLowerCase().replace(/\W/g, '_')
  if (newKey.includes('zip')) newKey = 'zip'
  memo[current] = newKey
  return memo
}, {})
