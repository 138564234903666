/* eslint-disable react/jsx-no-bind */
/* eslint-disable max-lines */
import React, { useState } from 'react'
import { withStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import {
  Typography,
  Dialog,
  IconButton,
  makeStyles,
  FormHelperText,
  Button,
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
  FormControlLabel,
  Grid
} from '@material-ui/core'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import LogRocket from 'logrocket'
import Box from '@material-ui/core/Box'
import FormControl from '@material-ui/core/FormControl'
import Checkbox from '@material-ui/core/Checkbox'
import FormGroup from '@material-ui/core/FormGroup'
import { Formik, Field } from 'formik'
import * as Yup from 'yup'
import { updateUser } from '../../data/fetchData'
import { statesInfoNoTerritories } from '../../utils/constants'
import theme from '../../theme'
import TermsAndConditionsAgreement from './agreements/termsAndConditionsAgreement'
import { checkIsUserFirstTime } from '../pages/userLandingPage'

const renderStateOptionTags = () => Object.keys(statesInfoNoTerritories).map((stateCode) => (
  <MenuItem key={`stateCodeDropdownOption-${stateCode}`} value={stateCode}>
    {statesInfoNoTerritories[stateCode]}
  </MenuItem>
))

const titleStyles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
})

const DialogTitle = withStyles(titleStyles)((props) => {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent)

const useStyles = makeStyles((theme) => ({
  select: {
    fontWeight: 'bold'
  },
  greyText: {
    color: 'grey',
  },
  termsText: {
    color: 'grey',
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(3)
  },
  formControl: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(3)
  },
  link: {
    color: theme.palette.success.main,
    fontWeight: 'bold'
  },
  readySubmitButton: {
    marginTop: theme.spacing(3),
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    backgroundColor: theme.palette.success.main,
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.success.active,
    }
  },
  submitButton: {
    marginTop: theme.spacing(3),
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6)
  }
}))

export default function StateSelectionAndTermsDialog ({ open, setOpen, accessToken, userId, usersStateCode, agreedToTerms }) {
  const readTermsAgreementText = 'I have read and agree to the Terms of Service.'
  const classes = useStyles()
  const [userStateSelection, setUserStateSelection] = useState(usersStateCode)

  const handleUpdateUser = async (values) => {
    const body = {
      state_code: values.stateSelection,
      agreed_to_terms: values.agreedToTerms
    }
    const response = await updateUser(userId, body, accessToken)
    if (response.status !== 200) throw (await response.json())
  }
  // eslint-disable-next-line react/no-unstable-nested-components
  function StateSelection ({ form }) {
    const classes = useStyles()
    return (
      <FormControl required component="fieldset" variant="outlined" fullWidth="true" margin="normal" className={classes.formControl}>
        <InputLabel id="select-state-label">State</InputLabel>
        <Select
          className={classes.select}
          labelId="select-state-label"
          id="state-select"
          value={userStateSelection}
          onChange={(e) => {
            form.setFieldValue('stateSelection', e.target.value)
            setUserStateSelection(e.target.value)
          }}
          label="State"
        >
          { renderStateOptionTags() }
        </Select>
        {Boolean(form.touched.stateSelection && form.errors.stateSelection) && (
        <FormHelperText error>
          {form.errors.stateSelection}
        </FormHelperText>
        )}
      </FormControl>
    )
  }
  return (
    <Formik
      initialValues={{
        agreedToTerms,
        submit: null,
        stateSelection: usersStateCode
      }}
      validationSchema={Yup.object().shape({
        stateSelection: Yup.string().required('Please select a state to continue'),
        agreedToTerms: Yup.boolean().oneOf([true], 'You must check the box to agree to the terms and conditions')
      })}
      onSubmit={async (values, {
        setErrors,
        setStatus,
        setSubmitting
      }) => {
        try {
          setOpen(false)
          await handleUpdateUser(values)
          window.location.reload()
        } catch (err) {
          /* eslint no-console: "off" */
          console.error(err);
          setStatus({ success: false });
          setErrors({ submit: err.message });
          setSubmitting(false);
          LogRocket.captureException(err)
        }
      }}
    >
      {({ errors, handleChange, handleSubmit, values, isSubmitting, touched }) => (
        <Dialog
          onClose={() => setOpen(false)}
          aria-labelledby="modal-to-update-user-state"
          open={open || checkIsUserFirstTime({ stateCode: usersStateCode, agreedToTerms })}
          PaperProps={{
            style: {
              borderRadius: 15,
              padding: theme.spacing(2)
            }
          }}
          scroll="body"
          fullHeight
          maxHeight="lg"
          fullScreen="true"
        >

          <DialogTitle id="modal-to-update-user-state" onClose={() => setOpen(false)}>
            <Box fontWeight="fontWeightBold">
              Your State
            </Box>
          </DialogTitle>
          <DialogContent>
            <Box>
              <form onSubmit={handleSubmit}>
                <Typography variant="body1" className={classes.greyText}>
                  Please choose the state where you work. This helps us provide a tailored experience for you.
                </Typography>
                <Field name="stateSelection" placeholder="state" component={StateSelection} className={classes.stateSelection} />
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={4}>
                    <Typography variant="body1" className={classes.termsText}>
                      The information you receive via the K-12 Bridge to Broadband data sharing platform (dashboard, map, and CSV file) is for your school district&apos;s use only and covered by the Data Privacy Agreement. Please remember that
                      {' '}
                      <Box display="inline" fontWeight="bold">confidential information cannot be shared outside of the school district.</Box>
                      {' '}
                      School districts should not re-share information that specifically names ISPs or information related to a named ISP’s subscribers or end-users - even where this is aggregated.
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <TermsAndConditionsAgreement />
                  </Grid>
                </Grid>
                {!agreedToTerms && (
                  <Box mt={2}>
                    <FormControl required component="fieldset">
                      <FormGroup>
                        <FormControlLabel
                          control={<Checkbox checked={values.agreedToTerms} onChange={handleChange} name="agreedToTerms" />}
                          label={readTermsAgreementText}
                        />
                      </FormGroup>
                      {Boolean(touched.agreedToTerms && errors.agreedToTerms) && (
                      <FormHelperText error>
                        {errors.agreedToTerms}
                      </FormHelperText>
                      )}
                    </FormControl>
                  </Box>
                )}
                <a className={classes.link} href="https://digital-bridge-assets.s3.us-west-1.amazonaws.com/ESH_Terms_Of_Service.pdf">Download Terms of Service</a>
                <Box mt={2}>
                  <Button
                    className={(values.stateSelection === null || values.agreedToTerms === false || errors.agreedToTerms) ? classes.submitButton : classes.readySubmitButton}
                    disabled={isSubmitting}
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    SUBMIT
                  </Button>
                </Box>
              </form>
            </Box>
          </DialogContent>
        </Dialog>
      )}
    </Formik>
  )
}
