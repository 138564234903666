import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useParams } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { fetchStudents, editStudents } from '../../../data/fetchData'
import { titleize, createAddress } from '../../../utils/stringUtils'
import RadioButtons from './RadioButtons'

/* eslint-disable max-lines */
export function MuiTable ({ rows, columns }) {
  const useStyles = makeStyles({
    root: {
      width: '100%'
    },
    container: {
      maxHeight: 540
    }
  })
  const classes = useStyles()
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
              <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                {columns.map((column) => {
                  const value = row[column.id]
                  return (
                    <TableCell key={column.id} align={column.align}>
                      {column.format && typeof value === 'number' ? column.format(value) : value}
                    </TableCell>
                  )
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  )
}

const updateStudentValues = async (e, studentsToUpdate, entityUID, accessToken) => {
  e.preventDefault()
  await editStudents({ studentsToUpdate, entityUID }, accessToken)
  window.location.reload()
}

function EditsTable ({ students, columnsToDisplay, studentsToUpdate, setStudentsToUpdate }) {
  const columns = columnsToDisplay.map((column) => ({
    label: titleize(column),
    id: column,
    minWidth: 100
  }))
  const rows = students.map((student) => ({
    ...student,
    device: (
      <RadioButtons
        student={student}
        field="device"
        studentsToUpdate={studentsToUpdate}
        setStudentsToUpdate={setStudentsToUpdate}
      />
    ),
    sufficient_internet: (
      <RadioButtons
        student={student}
        field="sufficient_internet"
        studentsToUpdate={studentsToUpdate}
        setStudentsToUpdate={setStudentsToUpdate}
      />
    )
  }))
  return <MuiTable rows={rows} columns={columns} />
}

export default function DirectEditsTable ({ accessToken }) {
  const [students, setStudents] = useState([])
  const [studentsToUpdate, setStudentsToUpdate] = useState([])
  const { entityUID } = useParams()
  const useStyles = makeStyles((theme) => ({
    button: {
      background: theme.palette.success.main,
      color: theme.palette.primary.main,
      fontWeight: 'bold',
      '&:hover': {
        backgroundColor: theme.palette.success.active,
      }
    },
    box: {
      marginTop: theme.spacing(2)
    }
  }))
  const classes = useStyles()

  useEffect(() => {
    if (entityUID && accessToken) {
      fetchStudents({ accessToken, entityUID, setStudents })
    }
  }, [accessToken, entityUID, setStudents])

  if (!students) {
    return <CircularProgress size="small" color="secondary" />
  }
  if (students && !students.length) return null
  const columnsToDisplay = ['address_uid', 'student_id', 'address', 'device', 'sufficient_internet']
  const studentsColumnsSubset = students.map((student) => {
    student.address = createAddress(student)
    return _.pick(student, columnsToDisplay)
  })
  return (
    <div>
      <Box className={classes.box}>
        <Typography variant="body2">
          To use
          {' '}
          <strong>Direct Edit</strong>
          , choose the appropriate True or False answer option next to the corresponding student to modify their device or connection status. When you’ve completed edits, click the Save button.
        </Typography>
      </Box>
      <br />
      {studentsToUpdate.length > 0 && (
        <Button
          size="large"
          variant="contained"
          className={classes.button}
          onClick={(e) => updateStudentValues(e, studentsToUpdate, entityUID, accessToken)}
        >
          SAVE
        </Button>
      )}
      <EditsTable
        students={studentsColumnsSubset}
        columnsToDisplay={columnsToDisplay}
        studentsToUpdate={studentsToUpdate}
        setStudentsToUpdate={setStudentsToUpdate}
      />
    </div>
  )
}
