import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import LogRocket from 'logrocket'
import { useDispatch } from 'react-redux'
import { makeStyles, useTheme, ThemeProvider } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Container from '@material-ui/core/Container'
import CssBaseline from '@material-ui/core/CssBaseline'
import Routes from './features/routes'
import Footer from './features/pageDecorations/footer'
import { useAuth0 } from './react-auth0-spa'
import { fetchUser } from './data/fetchData'
import NavBar from './features/pageDecorations/navBar'
import ErrorPage from './features/pages/errorPages'
import { setInitial } from './redux/reducers/userInfoSlice'
import theme from './theme'
import 'bootstrap/dist/css/bootstrap.min.css'
import LoadingScreen from './features/pageDecorations/loadingScreen'

const setData = (user, userInfoFromAPI, accessToken, dispatch) => {
  dispatch(setInitial({
    userInfoData: {
      ...userInfoFromAPI,
      accessToken,
      name: user.name,
      email_verified: user.email_verified,
      picture: user.picture
    }
  }))
}

const fetchUserData = async (user, setErrored, getTokenSilently, dispatch, setLoadingUserData) => {
  const { email, name } = user
  try {
    if (email) {
      const accessToken = await getTokenSilently({
        audience: process.env.REACT_APP_API_IDENTIFIER
      })
      const userInfoFromAPI = await fetchUser(accessToken)
      // This identifies the user by their email address for easier tracking
      LogRocket.identify(email, { email, name })
      setData(user, userInfoFromAPI, accessToken, dispatch)
    }
  } catch (e) {
    if (process.env.REACT_APP_ENV === 'production') {
      LogRocket.captureException(e)
    }
    /* eslint no-console: "off" */
    console.error('Error fetching user data from api', e)
    setErrored(true)
  }
  setLoadingUserData(false)
}

function App () {
  const { loading: loadingAuth0, user, getTokenSilently } = useAuth0()
  const dispatch = useDispatch()
  const [errored, setErrored] = useState(false)
  const [loadingUserData, setLoadingUserData] = useState(true)
  const themeValues = useTheme()
  const belowMdBreakPoint = useMediaQuery(themeValues.breakpoints.down('md'))
  const useStyles = makeStyles((theme) => ({
    app: {
      marginTop: belowMdBreakPoint ? theme.spacing(25) : 92
    },
    loadingBar: {
      height: '100vh'
    }
  }))
  const classes = useStyles()

  useEffect(() => {
    if (loadingAuth0) return
    fetchUserData(user, setErrored, getTokenSilently, dispatch, setLoadingUserData)
  }, [user, loadingAuth0, setErrored, getTokenSilently, dispatch, setLoadingUserData])

  if (loadingAuth0 || loadingUserData) {
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className={classes.loadingBar}>
          <LoadingScreen />
        </div>
      </ThemeProvider>
    )
  }
  if (errored) {
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <ErrorPage />
        <Footer />
      </ThemeProvider>
    )
  }
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className={classes.app}>
        <Router>
          <NavBar />
          <Container>
            <Routes />
          </Container>
          <Footer />
        </Router>
      </div>
    </ThemeProvider>
  )
}

export default App
