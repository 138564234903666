import { createMuiTheme } from '@material-ui/core/styles'

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#fff', /* white */
      light: '#fbfbfb', /* lightGray */
      dark: '#222' /* black */
    },
    secondary: {
      main: '#5E083E', /* purple */
      light: '#d7dada' /* gray */
    },
    info: {
      main: '#5E083E', /* purple */
      light: '#158acf', /* skyBlue */
      active: '#47062f' /* purple: 1 shade darker */
    },
    success: {
      main: '#019297', /* mutedBlue */
      light: '#FBBB0B', /* yellow */
      active: '#016164' /* mutedBlue: 2 shades darker */
    },
    error: {
      main: '#b00' /* red */
    },
    text: {
      secondary: '#565656',
      tertiary: '#666'
    }
  },
  typography: {
    fontFamily: 'Lato'
  }
})

export default theme
