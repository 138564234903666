/* eslint-disable max-lines */
import React from 'react'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Typography,
  Dialog,
  IconButton,
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
  Box,
  Button,
  FormControlLabel,
  FormHelperText,
  FormControl,
  Checkbox,
  FormGroup,
  CircularProgress
} from '@material-ui/core'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom';
import { selectUserInfo } from '../../redux/selectors'
import DataSharingAgreement from './agreements/DataSharingAgreement';
import { fetchEntityUploadLink, uploadCSVToAWS, editEntity, handleUserUploadIngestion } from '../../data/fetchData';

/* eslint-disable max-lines */

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
})

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent)

export default function LegalAuthoritiesDialog (props) {
  const { setOpen, open, csvUploadFormValues, program } = props
  const userInfo = useSelector(selectUserInfo)
  const classes = useStyles()
  const history = useHistory()

  if (userInfo.isStateExceptionUser || !userInfo.accessToken) return null

  return (
    <Dialog onClose={() => setOpen(false)} aria-labelledby="modal-to-share-entity-information" open={open}>
      <DialogTitle id="modal-to-share-entity-information" onClose={() => setOpen(false)}>
        {(program === 'digital_equity') ? 'Authority to Upload Data' : 'Submit Your Data'}
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        {(program === 'digital_equity') && (
          <>
            <Typography variant="body1">
              Because the data you will be uploading to this tool is sensitive and personally identifiable information,
              we are required to assure that you have the authority to share the data with EducationSuperHighway.
            </Typography>
            <br />
          </>
        )}
        <Typography className={classes.formSubmissionDetails}>
          <strong>District Name: </strong>
          {' '}
          {csvUploadFormValues.name}
        </Typography>
        <Typography className={classes.formSubmissionDetails}>
          <strong>First Name: </strong>
          {' '}
          {csvUploadFormValues.firstName}
        </Typography>
        <Typography className={classes.formSubmissionDetails}>
          <strong>Last Name: </strong>
          {' '}
          {csvUploadFormValues.lastName}
        </Typography>
        <Typography className={classes.formSubmissionDetails}>
          <strong>Email: </strong>
          {' '}
          {userInfo.email}
        </Typography>
        <Formik
          initialValues={{
            legalAuthorityAgreed: false,
            submit: null
          }}
          validationSchema={program === 'digital_equity' ? Yup.object().shape({
            legalAuthorityAgreed: Yup.boolean().oneOf([true], 'You must check the box to agree to the data sharing agreement')
          }) : null}
          onSubmit={async (values, {
            resetForm,
            setErrors,
            setStatus,
            setSubmitting
          }) => {
            try {
              const csvURL = await fetchEntityUploadLink({ entityUID: csvUploadFormValues.entityUID, accessToken: userInfo.accessToken })
              await uploadCSVToAWS(csvURL, csvUploadFormValues.csvFile)
              const entity = { entityUID: csvUploadFormValues.entityUID }
              if (program === 'digital_equity') entity.digitalEquity = true
              if (program === 'data_exchange') entity.optInExchange = true
              await editEntity(entity, userInfo.accessToken)
              await handleUserUploadIngestion({ entityUID: csvUploadFormValues.entityUID, accessToken: userInfo.accessToken, districtName: csvUploadFormValues.name })
              resetForm()
              setStatus({ success: true })
              setSubmitting(false)
              setOpen(false)
              history.push('/uploadProcessing')
            } catch (err) {
              /* eslint no-console: "off" */
              console.error(err);
              setStatus({ success: false });
              setErrors({ submit: err.message });
              setSubmitting(false);
            }
          }}
        >
          {({ errors, handleChange, handleSubmit, values, isSubmitting, touched }) => (
            <Box className={classes.legalAgreementCard}>
              {isSubmitting ? (
                <Box
                  display="flex"
                  justifyContent="center"
                  my={5}
                >
                  <CircularProgress className={classes.circularProgress} />
                </Box>
              ) : (
                <form onSubmit={handleSubmit}>
                  <AgreementOrDataExchangeText program={program} values={values} handleChange={handleChange} touched={touched} errors={errors} classes={classes} />
                  <Box mt={2}>
                    <Button
                      className={classes.submitButton}
                      disabled={isSubmitting}
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      SUBMIT
                    </Button>
                  </Box>
                </form>
              )}
            </Box>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  )
}

function AgreementOrDataExchangeText ({ program, values, handleChange, touched, errors, classes }) {
  if (program === 'digital_equity') {
    const readAgreementText = 'By submitting this form I am acknowledge that I have read and agree to the Data Sharing Agreement between the above named school district and EducationSuperHighway. I also state that I am authorized by the above named school district to enter into this agreement.'
    return (
      <>
        <DataSharingAgreement />
        <Box mt={2}>
          <FormControl required component="fieldset" className={classes.formControl}>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox checked={values.legalAuthorityAgreed} onChange={handleChange} name="legalAuthorityAgreed" />}
                label={readAgreementText}
              />
            </FormGroup>
            {Boolean(touched.legalAuthorityAgreed && errors.legalAuthorityAgreed) && (
              <FormHelperText error>
                {errors.legalAuthorityAgreed}
              </FormHelperText>
            )}
          </FormControl>
        </Box>
      </>
    )
  }
  return (
    <Box mt={2}>
      <Typography>We&apos;ve checked the CSV file and it&apos;s ready for upload. Once you click the submit button below we&apos;ll begin processing the addresses and matching them with the list of Internet Service Providers who can potentially provide service. In some cases we may encounter an address that can&apos;t be found. We&apos;ll share a list of those addresses with you once we&apos;ve finished processing. If you need to upload additional addresses at a later date, feel free to return and add them using the same template and upload process.</Typography>
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  legalAgreementText: {
    width: '100%'
  },
  legalAgreementCard: {
    marginTop: theme.spacing(2)
  },
  dialogContent: {
    padding: theme.spacing(3)
  },
  formSubmissionDetails: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  submitButton: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.success.active,
    }
  },
  circularProgress: {
    color: theme.palette.success.main
  }
}))
