import React from 'react'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import SecurityIcon from '../../../assets/Security_Teal.svg'
import DataPrivacyIcon from '../../../assets/Key-Lock_Yellow.svg'
import ComplianceIcon from '../../../assets/Warning_Eggplant.svg'

export default function EntityPageInfo () {
  const useStyles = makeStyles((theme) => ({
    description: {
      color: theme.palette.text.secondary,
      marginTop: '12px'
    },
    pageInfoItem: {
      padding: theme.spacing(3),
      borderRadius: '25px',
    },
    pageInfoPanel: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6)
    },
    securityLink: {
      color: theme.palette.success.main,
      fontWeight: 'bold'
    },
    privacyLink: {
      color: theme.palette.success.light,
      fontWeight: 'bold'
    },
    complianceLink: {
      color: theme.palette.secondary.main,
      fontWeight: 'bold'
    },
  }))
  const classes = useStyles()
  return (
    <Grid container spacing={3} className={classes.pageInfoPanel}>
      <Grid item xs={12} sm={4}>
        <Paper elevation={3} square={false} className={classes.pageInfoItem}>
          <Grid container spacing={3}>
            <Grid item xs={3}>
              <img alt="security" src={SecurityIcon} />
            </Grid>
            <Grid item xs={9}>
              <Typography variant="h6">SECURITY</Typography>
              <Typography variant="body2" className={classes.description}>
                Learn how we secure the data you share with us. Learn more
                {' '}
                <a className={classes.securityLink} href="https://www.educationsuperhighway.org/security">here</a>
                .
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Paper elevation={3} square={false} className={classes.pageInfoItem}>
          <Grid container spacing={3}>
            <Grid item xs={3}>
              <img alt="security" src={DataPrivacyIcon} />
            </Grid>
            <Grid item xs={9}>
              <Typography variant="h6">DATA PRIVACY</Typography>
              <Typography variant="body2" className={classes.description}>
                Student data privacy is our top priority. Learn more
                {' '}
                <a className={classes.privacyLink} href="https://www.educationsuperhighway.org/privacy">here</a>
                .
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Paper elevation={3} square={false} className={classes.pageInfoItem}>
          <Grid container spacing={3}>
            <Grid item xs={3}>
              <img alt="security" src={ComplianceIcon} />
            </Grid>
            <Grid item xs={9}>
              <Typography variant="h6">COMPLIANCE</Typography>
              <Typography variant="body2" className={classes.description}>
                We help schools meet their compliance obligations. Learn more
                {' '}
                <a className={classes.complianceLink} href="https://www.educationsuperhighway.org/compliance">here</a>
                .
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  )
}
