import React from 'react'
import { Paper, Typography } from '@material-ui/core/'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  errorPaper: {
    padding: theme.spacing(15),
    borderRadius: '25px'
  },
  link: {
    fontWeight: 'bold',
    color: theme.palette.success.main
  },
}))

export default function ErrorPage () {
  const classes = useStyles()

  return (
    <div style={{ 'minHeight': '75vh' }}>
      <Paper elevation={3} className={classes.errorPaper}>
        <Typography variant="h6">
          Uh oh! It seems we&apos;ve encountered an error fetching your data. Please try again later. If this error persists please
          {' '}
          <a className={classes.link} href="https://educationsuperhighway.zendesk.com/hc/en-us">contact us</a>
          {' '}
          for support.
        </Typography>
      </Paper>
    </div>
  )
}
