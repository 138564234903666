import React, { useEffect } from 'react';
import NProgress from 'nprogress';
import {
  Box,
  LinearProgress,
  makeStyles,
  Typography
} from '@material-ui/core';
import FileCopyRoundedIcon from '@material-ui/icons/FileCopyRounded'

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    minHeight: '100%',
    padding: theme.spacing(8)
  },
  processingText: {
    marginBottom: theme.spacing(4)
  }
}));

function SmallLoadingScreen () {
  const classes = useStyles();

  useEffect(() => {
    NProgress.start();

    return () => {
      NProgress.done();
    };
  }, []);

  return (
    <div className={classes.root}>
      <Typography className={classes.processingText}>
        <FileCopyRoundedIcon style={{ color: '#5E083E' }} />
        {' '}
        Verifying file. Please do not close this window.
      </Typography>
      <Box width={600}>
        <LinearProgress color="secondary" />
      </Box>
    </div>
  );
}

export default SmallLoadingScreen;
