import React from 'react'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { updateUser } from '../../../data/fetchData'
import { statesInfoNoTerritories } from '../../../utils/constants'

const attributeToOptionsMappings = {
  role: {
    admin: 'Admin',
    eshAdmin: 'ESH Admin',
    stateAdmin: 'State Admin',
    mapAdmin: 'Map Admin',
    mapUser: 'Map User'
  },
  state_code: statesInfoNoTerritories,
  validation_status: {
    pending: 'Pending',
    validated: 'Validated'
  }
}

const renderOptionTags = (options) => Object.keys(options).map((option) => (
  <MenuItem
    key={`dropdownOption-${option}`}
    value={option}
  >
    {options[option]}
  </MenuItem>
))

export default function DropDownMenu ({ subjectUserId, subjectUserRole, userToUpdate, setUserToUpdate, accessToken, attribute }) {
  const onSelect = (e) => {
    setUserToUpdate({ ...userToUpdate, [attribute]: e.target.value })
    const body = {
      [attribute]: e.target.value,
      subjectUserId,
      subjectUserRole
    }
    updateUser(userToUpdate.user_id, body, accessToken)
  }
  const options = attributeToOptionsMappings[attribute]
  return (
    <Select
      style={{ marginLeft: 6 }}
      labelId={`select-label-${attribute}`}
      id={`select-${attribute}`}
      value={userToUpdate[attribute]}
      onChange={onSelect}
    >
      { renderOptionTags(options) }
    </Select>
  )
}
