import React from 'react'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  button: {
    maxWidth: '150px'
  }
}))

const clearAll = ({ onSelectedProvider, map, layerIds }) => {
  const defaultLayerIds = ['clusters', 'cluster-count', 'unclustered-point']
  layerIds.forEach((layerID) => {
    if (map.getLayer(layerID)) {
      map.setLayoutProperty(
        layerID,
        'visibility',
        defaultLayerIds.includes(layerID) ? 'visible' : 'none'
      )
    }
  })
  onSelectedProvider(null)
}

export default function ClearAllButton ({ map, selectedProvider, onSelectedProvider, layerIds }) {
  const classes = useStyles()
  if (selectedProvider) {
    return (
      <Button
        variant="outlined"
        className={classes.button}
        onClick={() => clearAll({ onSelectedProvider, map, layerIds })}
      >
        Clear Filter
      </Button>
    )
  }
  return null
}
