import LogRocket from 'logrocket'

export const postRequest = (data, accessToken) => ({
  method: 'POST',
  mode: 'cors',
  body: JSON.stringify(data),
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${accessToken}`,
    'Access-Control-Request-Method': 'POST',
    'Access-Control-Request-Headers': 'Authorization, Content-Type'
  }
})

export const putRequest = (data, accessToken) => ({
  method: 'PUT',
  mode: 'cors',
  body: JSON.stringify(data),
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${accessToken}`,
    'Access-Control-Request-Method': 'PUT',
    'Access-Control-Request-Headers': 'Authorization, Content-Type'
  }
})

export const deleteRequest = (accessToken) => ({
  method: 'DELETE',
  mode: 'cors',
  headers: {
    Authorization: `Bearer ${accessToken}`,
    'Access-Control-Request-Method': 'DELETE',
    'Access-Control-Request-Headers': 'Authorization, Content-Type'
  }
})

export const handleErrorLogRocket = (err) => {
  if (process.env.REACT_APP_ENV === 'production') {
    LogRocket.captureException(err)
  }
  /* eslint-disable no-console */
  console.error(err.message)
}
