import React from 'react'
import { Box, makeStyles } from '@material-ui/core'
import ArrowLeft from '@material-ui/icons/ArrowLeft'
import ArrowRight from '@material-ui/icons/ArrowRight'
import { titleize } from '../../../utils/stringUtils'

// these are the only properties we want to display in the popup
const propertiesForDisplay = ['identifier', 'address', 'name', 'student_id', 'device', 'sufficient_internet', 'number_of_students']

/**
 * This util function filters out values which are nullish, or the string, "null", replacing them
 * with the string "unknown", returning a well-formatted string
 *
 * @param {String} value - a value to check the nullishness of
 * @returns either the original value, or the string "unknown"
 */
export const formatValue = (() => {
  // these are the potential values which we do NOT want to show
  const nullishValues = ['null', null, undefined]

  return (value) => {
    if (nullishValues.includes(value)) {
      return 'Unknown'
    }
    /*
     here, we replace a comma followed by an arbitrary amount of space characters (" ") with a
     the same, but with exactly one space. this sounds more complicated than it is, I promise.

     "hi mom"       ->     "hi mom"
     "1, 2, 3"      ->     "1, 2, 3"
     "4,5,6"        ->     "4, 5, 6"
     "7,  8,   9"   ->     "7, 8, 9"

     */
    return value.toString().replace(/,\s*/g, ', ')
  }
})()

/**
 * this function is for use with the sort method on Arrays. it performs the same sort as the
 * default, but instead of using elements of the array, it uses the first element of each subarray
 */
export const displayPropertiesSort = ([keyA], [keyB]) => (keyA > keyB ? 1 : -1)

/**
 * A popup component which is displayed for points or clusters of collocated points on the map
 *
 * @param {React.props} props
 */
export default function Popup ({ features, addressIndex }) {
  // we'll transform features into this
  let displayProperties = []
  // we're going to transform the address index so that it can't go outside the bounds of the array
  let adjustedIndex = 0

  // if we're generating a popup for a cluster, we want to display slightly different things
  const isCluster = features[addressIndex]?.layer?.id !== 'unclustered-point'

  if (isCluster) {
    const initialValue = []

    // transform the features object into an array of collocated properties with unique addresses
    const properties = features.reduce(
      (previous, current) => {
        // look for an existing entry which shares the index
        const index = previous.findIndex((element) => element.address === current.properties.address)
        // if the address has not already been added, add it!
        if (index === -1) {
          // we'll transform the student id into an array of ids
          const { student_id } = current.properties
          // and, so long as there is an id, add the object in
          if (student_id) {
            previous.push({
              ...current.properties,
              hiddenNumberOfStudents: 1,
              student_id: [current.properties.student_id]
            })
          }
        } else {
        // if the address has already been added to the properties, add the new student IDs to it
          previous[index].student_id.push(current.properties.student_id)
          // maintain a hidden count of students at this address
          previous[index].hiddenNumberOfStudents += 1
          // and if that number is above a threshold, add it in as a property which IS displayed
          if (previous[index].hiddenNumberOfStudents > 2) {
            previous[index].number_of_students = previous[index].hiddenNumberOfStudents
          }
        }
        return previous
      },
      initialValue
    )

    // set up the properties object for display purposes
    displayProperties = (
      properties
        // separate the objects therein into 2-element arrays of their keys and values
        .map(Object.entries)
        .map((element) => element.filter(([key]) => propertiesForDisplay.includes(key)))
        // and then sort those arrays, so we ensure consistency of display
        .map((element) => element.sort(displayPropertiesSort))
    )

    /*
     here, we transform any number into an index within the limits of the length of the array.

     in order to achieve this, we will first compute addressIndex % displayProperties.length. for
     brevity, let's call these i and p.length (so, i % p.length).

     in order to get a number between positive and negative p.length. we only want to deal with the
     positive range, which is easy enough to get! just add p.lenth again. now we have
     (i % p.length) + p.length. perfect!

     but now we need a different process for negative and positive modulos, which won't do. we can
     can alleviate this by adding ONE more step. see, if we have a positive modulo, and we add
     p.length, we're no longer within the bounds of the array!

     luckily, all we need to do is slap on another modulo operation, and we're done!

     ((i % p.length) + p.length) % p.length

     thank you for coming to my TED talk.
     */
    adjustedIndex = ((addressIndex % displayProperties.length) + displayProperties.length) % (displayProperties.length)
  } else {
    // if we're NOT dealing with a cluster, the process is much simpler (thank goodness)
    const { properties } = features[addressIndex]
    displayProperties = (
      [
        Object
          .entries(properties)
          .filter(([key]) => propertiesForDisplay.includes(key))
          .sort(displayPropertiesSort)
      ]
    )
  }

  const classes = useStyles()

  return (
    <div className="Popup">
      {
        displayProperties.length && displayProperties[adjustedIndex]
          .map(([key, value], index) => {
            const reactKey = key + value + index
            const valueToDisplay = formatValue(value)
            return (
              <div className={classes.field} key={reactKey}>
                <div className={classes.title}>
                  {titleize(key)}
                  :
                </div>
                {valueToDisplay}
              </div>
            )
          })
      }
      {
        /*
         if there is more than one property to be displayed, we're looking at a tooltip for a
         cluster with more than one address within, then we want to display buttons to
         navigate between them
         */
        displayProperties.length > 1 && (
          <Box textAlign="center">
            {/*
              using regular buttons here in place of MUI buttons due to that this component is
              rendered to an html string, and inserted. MUI's button uses React.useLayoutEffect,
              which throws all sorts of console errors when used in this manner.
            */}
            <button type="button" className={classes.addressButtons} id="left-button">
              <ArrowLeft />
            </button>
            {' - '}
            <div className={classes.pageNumber}>
              {adjustedIndex + 1}
            </div>
            {' - '}
            <button type="button" className={classes.addressButtons} id="right-button">
              <ArrowRight />
            </button>
          </Box>
        )
      }
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.text.secondary,
    lineHeight: 1.6,
    fontSize: 12,
  },
  field: () => ({
    '&:not(:first-child)': {
      marginTop: '10px',
    },
    fontSize: 14,
  }),
  addressButtons: {
    backgroundColor: 'white',
    border: '1px solid #bbb',
    borderRadius: '4px',
    margin: '10px 5px 0 5px',
    '&:hover': {
      border: '1px solid black',
    },
  },
  pageNumber: {
    minWidth: '15px',
    display: 'inline-block'
  }
}))
