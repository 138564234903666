import React, { useState } from 'react'
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete'
import { TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { fetchEntityToEdit, searchAddress } from '../../../data/fetchData'

export default function AddressSearchTypeahead ({ setValue, entityUID, accessToken }) {
  const classes = useStyles()
  const [options, setOptions] = useState([])

  const handleSearch = async (query) => {
    if (query) {
      const items = await searchAddress(query)
      const options = items.features.map((i) => ({
        address: i.place_name,
        coordinates: i.geometry.coordinates
      }))
      setOptions(options)
    } else {
      setOptions([])
    }
  }

  const limitOfDisplayedOptions = 20
  const filterOptions = createFilterOptions({ limit: limitOfDisplayedOptions })

  return (
    <Autocomplete
      options={options}
      filterOptions={filterOptions}
      getOptionLabel={(option) => (option.address)}
      getOptionSelected={(option, value) => option.entityUID === value.entityUID}
      className={classes.entityNameTypeahead}
      onChange={async (_event, value) => {
        if (value) {
          setValue(value.coordinates)
        } else {
          const result = await fetchEntityToEdit({ entityUID, accessToken })
          setValue([result.longitude, result.latitude])
        }
      }}
      onInputChange={(_event, value) => handleSearch(value)}
      name="address"
      id="address"
      renderInput={(params) => <TextField {...params} label="Address" variant="outlined" />}
    />
  )
}

const useStyles = makeStyles((theme) => ({
  typeahead: {
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(1),
    width: '560px'
  },
  labelText: {
    color: 'rgba(0, 0, 0, 0.54)',
    margin: 0,
    padding: 0
  }
}))
