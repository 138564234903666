import React from 'react'
import { useSelector } from 'react-redux'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { selectUserInfo } from '../../../redux/selectors'
import { UploadTemplateBody } from '../csvTemplateDownload'
import CSVUploadForm from './CSVUploadForm'

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(3),
    paddingTop: theme.spacing(5)
  },
  formField: {
    fontSize: '18px',
    marginBottom: theme.spacing(2)
  },
  textField: {
    fontSize: '18px'
  },
  labelText: {
    color: 'rgba(0, 0, 0, 0.54)',
    margin: 0,
    padding: 0
  },
  error: {
    color: theme.palette.error.main
  }
}))

export default function CSVUploader () {
  document.title = 'Home Digital Access Map: Upload'
  const userInfo = useSelector(selectUserInfo)
  const classes = useStyles()

  if (!userInfo || userInfo.validation_status !== 'validated' || userInfo.isStateExceptionUser) return null

  return (
    <>
      <Typography variant="h4" className={classes.title}>Upload Your District&apos;s Data</Typography>
      <UploadTemplateBody />
      <CSVUploadForm program="digital_equity" />
    </>
  )
}
