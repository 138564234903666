/* eslint-disable no-console */
import React, { useState } from 'react'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import SaveAltIcon from '@material-ui/icons/SaveAlt'
import { Document, Page, pdfjs } from 'react-pdf'

import { useStyles } from './dashboard'
import CSVDownload from '../../csv/csvDownload'
import { TabPanel } from '../../pageDecorations/editDataTabs'
import csvImage from '../../../assets/csv.png'

export default function DownloadTab ({ entityUID, accessToken, program, currentTab }) {
  const [csvDataLink, setCsvDataLink] = useState()
  const [numPages, setNumPages] = useState(null)
  const [pageNumber, setPageNumber] = useState(0)
  const classes = useStyles()
  if (!entityUID || !accessToken) return null

  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  const pdfUrl = 'https://digital-bridge-assets.s3.us-west-1.amazonaws.com/K12B2B-User-Guide_How-to-use-the-data.pdf'
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages)
    setPageNumber(1)
  }
  const changePage = (offset) => setPageNumber((prevPageNumber) => prevPageNumber + offset)

  return (
    <TabPanel value={currentTab} index={2}>
      <Paper elevation={3} square={false} className={classes.dashboardPanel}>
        <Grid container spacing={4} alignItems="stretch">
          <Grid item xs={12} sm={5}>
            <Typography className={classes.sectionTitle} variant="h6">CONNECTIVITY DATA</Typography>
            <Grid container direction="column" spacing={2} alignItems="center">
              <Grid item><img width={400} alt="csv-file" src={csvImage} /></Grid>
              <Grid item>
                <CSVDownload
                  entityUID={entityUID}
                  accessToken={accessToken}
                  csvDataLink={csvDataLink}
                  setCsvDataLink={setCsvDataLink}
                  program={program}
                />
              </Grid>
              <Grid item>
                <br />
                <Typography variant="body2">
                  This CSV file contains the data that powers your K-12 Bridge to Broadband dashboard. The file only has the unconnected student addresses. You should expect to see some duplicate addresses (with unique student IDs) that reflect unconnected addresses where multiple students live. The User Guide provides additional guidance for making use of this data.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid container item xs={12} sm={7}>
            <Grid item xs={12}>
              <Typography className={classes.sectionTitle} variant="h6">USER GUIDE</Typography>
              <Grid container direction="column" spacing={2} alignItems="center">
                <Grid item>
                  <Document
                    file={pdfUrl}
                    onLoadSuccess={onDocumentLoadSuccess}
                    className={classes.pdfDocument}
                    onClick={() => { window.location = pdfUrl }}
                  >
                    <Page pageNumber={pageNumber} width={350} />
                  </Document>
                  { pageNumber > 0 && (
                  <div>
                    <Typography>
                      Preview
                      {' '}
                      Page
                      {' '}
                      {pageNumber || (numPages ? 1 : '--')}
                      {' '}
                      of
                      {' '}
                      {numPages || '--'}
                    </Typography>
                    <ButtonGroup color="secondary">
                      <Button
                        disabled={pageNumber <= 1}
                        onClick={() => changePage(-1)}
                      >
                        Previous
                      </Button>
                      <Button
                        disabled={pageNumber >= numPages}
                        onClick={() => changePage(1)}
                      >
                        Next
                      </Button>
                    </ButtonGroup>
                  </div>
                  )}
                </Grid>
                <Grid item>
                  <Button
                    size="medium"
                    variant="contained"
                    className={classes.downloadButton}
                    endIcon={<SaveAltIcon />}
                    href={pdfUrl}
                  >
                    Download User Guide
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </TabPanel>
  )
}
