import ReactDOMServer from 'react-dom/server'
import React from 'react'
import PieChart, {
  Series,
  Tooltip,
  Label,
  Connector,
} from 'devextreme-react/pie-chart'
import { formatNumberForDisplay } from '../../../../utils/stringUtils'

export default function CoverageBreakdownChart ({ serviceabilitySeries, studentCounts }) {
  // const numParticipatingProviders = formattedProviders.filter((provider) => !provider.form477).length
  const coverageMetrics = getCoverageMetrics({ studentCounts })
  return (
    <PieChart
      id="pie"
      type="doughnut"
      legend={{ visible: false }}
      palette={serviceabilitySeries.map((key) => key.color)}
      dataSource={coverageMetrics}
      startAngle={150}
    >
      <Series argumentField="studentType">
        <Label
          visible
          customizeText={({ valueText, percentText }) => `${percentText}  (${formatNumberForDisplay(valueText)})`}
          font={{
            family: 'Lato',
            weight: 600,
            size: 13
          }}
        >
          <Connector visible />
        </Label>
      </Series>
      <Tooltip
        enabled
        zIndex={1}
        customizeTooltip={(arg) => customizeTooltip(arg, coverageMetrics)}
      />
    </PieChart>
  )
}

function getCoverageMetrics ({ studentCounts }) {
  return [
    { studentType: 'Unconnected Students: Internet Available', val: parseInt(studentCounts.num_internet_available_students, 10), addressVal: parseInt(studentCounts.num_internet_available_addresses, 10) },
    { studentType: 'Unconnected Students: No Internet Available', val: parseInt(studentCounts.num_unconnected_students, 10), addressVal: parseInt(studentCounts.num_unconnected_addresses, 10) },
    { studentType: 'Connected Students', val: parseInt(studentCounts.num_connected_students, 10), addressVal: parseInt(studentCounts.num_connected_addresses, 10) },
  ]
}

function customizeTooltip (arg, coverageMetrics) {
  const { addressVal } = coverageMetrics.find((coverageMetric) => coverageMetric.studentType === arg.argumentText)
  return {
    html: ReactDOMServer.renderToString(CustomizeTooltip(arg, addressVal))
  }
}

function CustomizeTooltip (arg, addressVal) {
  const { argumentText, valueText } = arg
  return (
    <>
      <h6 style={{ fontFamily: 'Lato' }}><strong>{argumentText}</strong></h6>
      <p>
        <pre style={{ fontFamily: 'Lato', fontSize: '1em' }}>
          {generateTextForDisplay({ argumentText, valueText, addressVal })}
        </pre>
      </p>
    </>
  )
}

const generateTextForDisplay = ({ argumentText, valueText, addressVal }) => {
  const starterText = `${formatNumberForDisplay(valueText)} students (living in ${formatNumberForDisplay(addressVal)} households)`
  if (argumentText.toUpperCase().includes('UNCONNECTED STUDENTS: INTERNET AVAILABLE')) return `${starterText} can be served by one or more provider.`
  if (argumentText.toUpperCase().includes('UNCONNECTED STUDENTS: NO INTERNET AVAILABLE')) return `${starterText} cannot be served by any of the participating providers.`
  return `${starterText} have active service.`
}
