import React from 'react'
import ReactDOMServer from 'react-dom/server'
import mapboxgl from 'mapbox-gl'
import Popup from '../popup/index'
import { coordinateTolerance } from '../../../utils/constants'

/**
 * this util function takes a set of features (as defined by MapBox) and determines whether they're
 * collocated within a given tolerance.
 *
 * @param {Array} features – the features array provided by MapBox
 * @param {Number} tolerance - a number of degrees longitude or latitude within which we're say
 * that points are samey
 * @returns {Boolean} – saminess of the coordinates
 */
export const coordsAreSamey = (features, tolerance = coordinateTolerance) => {
  if (!Array.isArray(features[0]?.geometry?.coordinates)) return false
  /*
   we'll use this value as the initial value for a reduce in a few lines. the basic idea is to
   provide preliminary values for the extremes of our coordinates.
   */
  const initialValue = {
    xMin: features[0].geometry.coordinates[0],
    xMax: features[0].geometry.coordinates[0],
    yMin: features[0].geometry.coordinates[1],
    yMax: features[0].geometry.coordinates[1]
  }

  // destructure out our extremes from what's to follow
  const { xMin, xMax, yMin, yMax } = features
    // first, map each element to the coords, since that's all we care about
    .map((feature) => feature.geometry.coordinates)
    /*
     and then we'll reduce the array of coords into an object (destructured above) based on the
     min/maxes as we iterate through
     */
    .reduce(
      ({ xMin, xMax, yMin, yMax }, [x, y]) => ({
        xMin: Math.min(xMin, x),
        xMax: Math.max(xMax, x),
        yMin: Math.min(yMin, y),
        yMax: Math.max(yMax, y),
      }),
      initialValue
    )

  // having collected our extremes, we determine the largest difference between the x and y coords
  const maxDiff = Math.max(xMax - xMin, yMax - yMin)

  // determine and return if that difference is within our tolerance
  return maxDiff < tolerance
}

/**
 * this function triggers the creation of a popup on our MapBox map
 *
 * @param {Object} options – options that dictate the behaviour of the popup
 */
export function addPopupOnClick ({ map, layerId, disguiseClusters }) {
  const popupElement = new mapboxgl.Popup({ closeButton: false, closeOnMove: false, closeOnZoom: true })

  // on mouse-over, change the cursor to demonstrate that an action can be taken
  map.on('mouseenter', layerId, () => {
    map.getCanvas().style.cursor = 'pointer'
  })
  map.on('mouseleave', layerId, () => {
    map.getCanvas().style.cursor = ''
  })

  /*
   this on-click handler function addresses 3 possibilities:

   the click was on...
     1. a cluster representing several sets of unique coordinates – the map zooms in.
     2. a cluster representing only a single set of unique coordinates – a popup is shown
     3. a point – a popup is shown
   */
  map.on('click', layerId, ({ features, point }) => {
    // we use cluster-ness to determine behaviour
    const isCluster = features[0]?.properties?.cluster
    // we'll use the coordinates of the point for placement of the popup
    const coordinates = features[0].geometry.coordinates.slice()

    if (isCluster) {
      /*
       the offset is how far from the center of the point we want the tooltip to show. for a
       cluster, we want to offset the tooltip a bit further in order to not obstruct the number
       therein (and also for aesthetic purposes).
       */
      const offset = disguiseClusters ? 6 : 12
      /*
       we query for cluster features in order to get the cluster ID, in turn to get the "leaves"
       of said cluster. the "leaves" are all the points within the cluster.
       */
      const clusterFeatures = map.queryRenderedFeatures(point, { layers: ['clusters'] })
      const clusterId = clusterFeatures[0].properties.cluster_id
      const clusterSource = map.getSource('students')
      //* note how getClusterLeaves uses a callback function. this part runs asynchronously.
      clusterSource.getClusterLeaves(clusterId, 1000, 0, (error, data) => {
        const features = data

        /*
         there are some spots which are located at ALMOST the same coordinates. changing our
         clustering to ameliorate this issue makes the map look worse, so instead, we determine if
         the points are in RELATIVELY the same place, and, if so, display a popup.
         */
        if (coordsAreSamey(features)) {
          addPopupToMap({ map, popupElement, offset, coordinates, features })
        } else {
          // otherwise, we zoom in!
          map.getSource('students').getClusterExpansionZoom(
            clusterId,
            (err, zoom) => {
              if (err) return
              map.flyTo({
                center: features[0].geometry.coordinates.slice(),
                zoom,
                speed: 0.75
              })
            }
          )
        }
      })
    } else {
      /*
       as mentioned above, this is the offset from the center of our coords to start the popup.
       here, it's mostly aesthetic.
       */
      const offset = 6
      addPopupToMap({ map, popupElement, offset, coordinates, features })
    }
  })
}

const addPopupToMap = (() => {
  /*
   * note how this function is wrapped in an IIFE. doing this allows us to have a "global"
   * variable, addressIndex, without polluting the real global scope. fun!
   */
  let addressIndex = 0
  let previousCoordinates

  return ({ map, popupElement, offset, coordinates, features }) => {
    if (previousCoordinates && previousCoordinates.toString() !== coordinates.toString()) {
      addressIndex = 0
    }

    previousCoordinates = coordinates

    popupElement
      .setLngLat(coordinates)
      .setOffset(offset)
      .setHTML(buildPopupHTML(features, addressIndex))
      .addTo(map)

    // declare some buttons in the parent scope, so the'll be in the closure for our handler below
    let leftButt
    let rightButt

    /**
     * A handler to change the addressIndex value
     *
     * @param {Number} increment – an amount to change the addressIndex by
     */
    const changeAddressIndex = (increment) => {
      // if it's not a number, we want to bail
      if (typeof increment !== 'number') return

      /*
       we'll be removing and recreating the popup below, and so our existing event listeners won't
       work anymore. first, let's remove existing event listeners as to not pollute the DOM.
       */
      leftButt.removeEventListener('click', decrementAddressIndex)
      rightButt.removeEventListener('click', incrementAddressIndex)

      // increment (or decrement!) the index
      addressIndex += increment
      // as the index has changed, we'll re-set the html of the popup with the new deets
      popupElement.setHTML(buildPopupHTML(features, addressIndex))

      // now that we have new DOM elements, we can add new event listeners!
      leftButt = document.getElementById('left-button')
      rightButt = document.getElementById('right-button')
      leftButt.addEventListener('click', decrementAddressIndex)
      rightButt.addEventListener('click', incrementAddressIndex);

      /*
       this little cutie maintains "focus" on the clicked button, even though we've recreated it.
       this is the default behaviour of web browsers, and is important for maintaining
       accessibility (even if MapBox is not the most accessible...)
       */
      (increment === -1 ? leftButt : rightButt).focus()
    }

    // simplified functions to handle the above events
    const decrementAddressIndex = () => changeAddressIndex(-1)
    const incrementAddressIndex = () => changeAddressIndex(1)

    /*
     this code will only be reached the first time a popup is created, hence the somewhat
     duplicated behaviour
     */
    leftButt = document.getElementById('left-button')
    rightButt = document.getElementById('right-button')
    // I say somewhat, since these get to be optionally-chained
    leftButt?.addEventListener('click', decrementAddressIndex)
    rightButt?.addEventListener('click', incrementAddressIndex)
    // more aesthetics – the newly created left button is focussed on, so let's stop that
    leftButt?.blur()
  }
})()

export const buildPopupHTML = (features, addressIndex) => ReactDOMServer.renderToString(<Popup features={features} addressIndex={addressIndex} />)
