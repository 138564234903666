/* eslint-disable max-lines */
import React, { useState } from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import {
  Box,
  Card,
  IconButton,
  InputAdornment,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  makeStyles
} from '@material-ui/core'
import { Edit as EditIcon, Search as SearchIcon } from 'react-feather'
import PerfectScrollbar from 'react-perfect-scrollbar'
import UserDialog from '../../../dialogs/UserDialog/index'
import { compareObjectValues } from '../../../../utils/objectUtils'

export default function UsersTable ({ userInfo, users }) {
  const isDataLoading = !users
  if (isDataLoading) return <div style={{ 'minHeight': '75vh' }}><CircularProgress color="secondary" /></div>
  return (
    <AdminTable
      users={users}
      subjectUserId={userInfo.user_id}
      subjectUserRole={userInfo.role}
      accessToken={userInfo.accessToken}
    />
  )
}

function AdminTable ({ users, subjectUserId, subjectUserRole, accessToken }) {
  const classes = useStyles()
  const [page, setPage] = useState(0)
  const [limit, setLimit] = useState(25)
  const [query, setQuery] = useState('')
  const [sort, setSort] = useState(sortOptions[0].value)
  const [open, setOpen] = useState(false)
  const [dialogUser, setDialogUser] = useState()

  const handleQueryChange = (event) => {
    event.persist()
    setQuery(event.target.value)
  }
  const handleSortChange = (event) => {
    event.persist()
    setSort(event.target.value)
  }
  const handlePageChange = (_, newPage) => {
    setPage(newPage)
  }
  const handleLimitChange = (event) => {
    // eslint-disable-next-line radix
    setLimit(parseInt(event.target.value))
  }
  const filteredUsers = applyFilters(users, query)
  const sortedUsers = applySort(filteredUsers, sort)
  const paginatedUsers = applyPagination(sortedUsers, page, limit)

  return (
    <Card className={classes.root}>
      { dialogUser && (
        <UserDialog
          subjectUserId={subjectUserId}
          subjectUserRole={subjectUserRole}
          accessToken={accessToken}
          open={open}
          setOpen={setOpen}
          dialogUser={dialogUser}
          setDialogUser={setDialogUser}
        />
      )}
      <Box
        p={2}
        minHeight={56}
        display="flex"
        alignItems="center"
      >
        <TextField
          className={classes.queryField}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SvgIcon
                  fontSize="small"
                  color="action"
                >
                  <SearchIcon />
                </SvgIcon>
              </InputAdornment>
            )
          }}
          onChange={handleQueryChange}
          placeholder="Search users"
          value={query}
          variant="outlined"
        />
        <Box flexGrow={1} />
        <TextField
          label="Sort By"
          name="sort"
          onChange={handleSortChange}
          select
          SelectProps={{ native: true }}
          value={sort}
          variant="outlined"
        >
          {sortOptions.map((option) => (
            <option
              key={option.value}
              value={option.value}
            >
              {option.label}
            </option>
          ))}
        </TextField>
      </Box>
      <PerfectScrollbar>
        <Box minWidth={700}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={classes.tHeader}>
                  User Name
                </TableCell>
                <TableCell className={classes.tHeader}>
                  Email
                </TableCell>
                <TableCell className={classes.tHeader}>
                  Validation Status
                </TableCell>
                <TableCell className={classes.tHeader}>
                  State
                </TableCell>
                <TableCell align="right" className={classes.tHeader}>
                  Edit
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedUsers.map((user) => (
                <TableRow hover key={user.email}>
                  <TableCell>
                    {user.user_name}
                  </TableCell>
                  <TableCell>
                    {user.email}
                  </TableCell>
                  <TableCell>
                    {user.validation_status}
                  </TableCell>
                  <TableCell>
                    {user.state_code}
                  </TableCell>
                  <TableCell align="right">
                    <IconButton onClick={() => {
                      setDialogUser(user)
                      setOpen(!open)
                    }}
                    >
                      <SvgIcon fontSize="small">
                        <EditIcon color="#000" />
                      </SvgIcon>
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={users.length}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[10, 25, 100]}
      />
    </Card>
  )
}

const sortOptions = [
  {
    value: 'validation_status|asc',
    label: 'Validation Status'
  },
  {
    value: 'state_code|asc',
    label: 'State'
  },
  {
    value: 'user_name|asc',
    label: 'Name'
  }
]

const applyFilters = (users, query) => users.filter((user) => {
  let matches = true
  if (query) {
    const properties = ['email']
    let containsQuery = false
    properties.forEach((property) => {
      if (user[property].toLowerCase().includes(query.toLowerCase())) {
        containsQuery = true
      }
    })
    if (!containsQuery) {
      matches = false
    }
  }
  return matches
})

const applyPagination = (users, page, limit) => users.slice(page * limit, page * limit + limit)

const applySort = (users, sort) => {
  const [key, order] = sort.split('|')
  return users.sort(compareObjectValues(key, order))
}

const useStyles = makeStyles((theme) => ({
  root: {},
  queryField: {
    width: 500
  },
  tHeader: {
    fontWeight: 'bold'
  },
  bulkOperations: {
    position: 'relative'
  },
  bulkActions: {
    paddingLeft: 4,
    paddingRight: 4,
    marginTop: 6,
    position: 'absolute',
    width: '100%',
    zIndex: 2,
    backgroundColor: theme.palette.background.default
  },
  bulkAction: {
    marginLeft: theme.spacing(2)
  }
}))
