import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import { makeStyles } from '@material-ui/core/styles'
import { Tab, Tabs } from '@material-ui/core'
import { setMapTab } from '../../../../redux/reducers/mapTabSlice'
import { TabPanel, idProps } from '../../../pageDecorations/editDataTabs'
import TopProvidersChart from './TopProvidersChart'
import CoverageBreakdownChart from './CoverageBreakdownChart'
import ServiceProvidersTable from './ServiceProvidersTable.tsx'
import Map from '../../../map'

export default function Dashboard ({ currentTab, entity, entityDataExchange, entityDataDigitalEquity, formattedProviders, toggle }) {
  const classes = useStyles()
  // eslint-disable-next-line react/no-unstable-nested-components
  function ServiceabilityKey () {
    return (
      <>
        <Typography className={classes.sectionKeyTitle} variant="subtitle1">KEY</Typography>
        { serviceabilitySeries.map((key) => (
          <Grid item container className={classes.keys} key={key.label}>
            <Grid item xs={1}>
              <FiberManualRecordIcon style={{ color: key.color }} />
            </Grid>
            <Grid>
              <Typography xs={11} variant="body2" color="inherit">{key.label}</Typography>
            </Grid>
          </Grid>
        ))}
      </>
    )
  }
  const serviceabilitySeries = [
    { label: 'Unconnected Students: Internet Available', color: '#FBBB0B' },
    { label: 'Unconnected Students: No Internet Available', color: '#494949' },
    { label: 'Connected Students', color: '#019297' },
  ]
  const { studentCounts } = computeStudentCounts({ entityDataExchange })

  /*
   For the Service Providers Table: We only want to show providers that are not in the data exchange program and those who can serve
   a significant number of students, so we will filter out providers that are form477 false and
   those that can serve less than 1% of students
   */
  const providersNotInExchange = formattedProviders.filter(
    (provider) => Boolean(provider.form477) && ((Number(provider.number_of_students_served) / studentCounts.num_students) >= 0.05)
  )

  return (
    <>
      <TabPanel value={currentTab} index={0}>
        <Paper elevation={3} square={false} className={classes.dashboardPanel}>
          <Grid container spacing={3} alignItems="stretch">
            <Grid item xs={12} sm={5}>
              <Typography className={classes.sectionTitle} variant="h6">CURRENT SERVICE STATUS</Typography>
              <CoverageBreakdownChart serviceabilitySeries={serviceabilitySeries} studentCounts={studentCounts} />
              <ServiceabilityKey classes={classes} />
            </Grid>
            <Grid container item xs={12} sm={7}>
              <Grid item xs={12}>
                <Typography className={classes.sectionTitle} variant="h6">WHO CAN SERVE YOUR UNCONNECTED STUDENTS?</Typography>
              </Grid>
              <Grid item xs={12} className={classes.topProviderChart}>
                <TopProvidersChart providers={formattedProviders} entityName={entity.name} numUnconnectedInternetAvailableStudents={studentCounts.num_internet_available_students} />
              </Grid>
            </Grid>
            {providersNotInExchange.length > 0 && (
            <Grid item xs={12} className={classes.internetProviderTable}>
              <Typography className={classes.sectionTitle} variant="h6">INTERNET PROVIDERS THAT HAVE NOT PARTICIPATED IN THE DATA EXCHANGE</Typography>
              <ServiceProvidersTable providers={providersNotInExchange} numStudentsInDistrict={studentCounts.num_students} />
            </Grid>
            )}
          </Grid>
        </Paper>
      </TabPanel>
      <TabPanel value={currentTab} index={1}>
        { entity.center[0] !== 0 ?
          <MapTabs entity={entity} entityDataExchange={entityDataExchange} entityDataDigitalEquity={entityDataDigitalEquity} toggle={toggle} />
          : <NoMapText classes={classes} /> }
      </TabPanel>
    </>
  )
}

export const computeStudentCounts = ({ entityDataExchange }) => {
  const studentCounts = entityDataExchange.studentCounts[0]
  const numUnconnectedNoAvailableFromTotal = parseInt(studentCounts.num_students, 10) - (parseInt(studentCounts.num_connected_students, 10) + parseInt(studentCounts.num_internet_available_students, 10))
  const numUnconnectedNoAvailable = Math.max(parseInt(studentCounts.num_unconnected_students, 10), numUnconnectedNoAvailableFromTotal)
  const totalUnconnectedStudents = numUnconnectedNoAvailable + parseInt(studentCounts.num_internet_available_students, 10)
  return { studentCounts, totalUnconnectedStudents }
}

function MapTabs ({ entity, entityDataExchange, entityDataDigitalEquity, toggle }) {
  const [currentMapTab, setCurrentMapTab] = useState(0)
  const dispatch = useDispatch()
  const handleTabsChange = (_, value) => {
    setCurrentMapTab(value)
    dispatch(setMapTab({ value }))
  }
  if (toggle) {
    return (
      <>
        <Tabs value={currentMapTab} onChange={handleTabsChange} aria-label="dashboard-map-tabs">
          <Tab label="Data Exchange Map" {...idProps(0)} />
          <Tab label="Digital Equity Map" {...idProps(1)} />
        </Tabs>
        <TabPanel value={currentMapTab} index={0}>
          <Map entity={{ ...entity, ...entityDataExchange }} program="data_exchange" />
        </TabPanel>
        <TabPanel value={currentMapTab} index={1}>
          <Map entity={{ ...entity, ...entityDataDigitalEquity }} program="digital_equity" />
        </TabPanel>
      </>
    )
  }
  return <Map entity={{ ...entity, ...entityDataExchange }} program="data_exchange" />
}

function NoMapText ({ classes }) {
  return (
    <Paper className={classes.dashboardPanel}>
      <Typography variant="body1">
        Maps are only available at a district level. If you are authorized to view a specific district, return to your home page and choose from your list of districts to visit the district dashboard. From there you can toggle to view a map.
      </Typography>
    </Paper>
  )
}

export const useStyles = makeStyles((theme) => ({
  dashboardPanel: {
    padding: theme.spacing(3),
    borderRadius: '0px 0px 25px 25px'
  },
  topProviderChart: {
    alignSelf: 'flex-end'
  },
  sectionTitle: {
    color: theme.palette.text.secondary,
    fontWeight: '500',
    letterSpacing: theme.spacing(0.2),
    marginBottom: theme.spacing(2),
    textAlign: 'center'
  },
  sectionKeyTitle: {
    color: 'grey'
  },
  internetProviderTable: {
    marginTop: theme.spacing(4)
  },
  pdfDocument: {
    cursor: 'pointer'
  },
  downloadButton: {
    color: theme.palette.primary.main,
    background: theme.palette.info.main,
    marginTop: theme.spacing(2),
    width: 320,
    '&:hover': {
      backgroundColor: theme.palette.info.active,
      color: theme.palette.primary.main
    }
  }
}))
