/* eslint-disable max-lines */
import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import transform from 'lodash/transform'
import { useSelector } from 'react-redux'
import {
  Typography,
  Dialog,
  IconButton,
  TextField,
  Grid,
  Checkbox,
  makeStyles,
  Button,
  DialogTitle as MuiDialogTitle,
  DialogActions as MuiDialogActions,
  DialogContent as MuiDialogContent,
  ButtonGroup
} from '@material-ui/core'
import { selectUserInfo } from '../../redux/selectors'
import { addUserEntities } from '../../data/fetchData'

/* eslint-disable max-lines */

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
})

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 'auto',
    fontSize: theme.typography.h6.fontSize,
    color: theme.palette.info.light,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions)

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent)

const useStyles = makeStyles((theme) => ({
  formHeader: {
    textAlign: 'center'
  },
  formCheckbox: {
    justifyContent: 'center'
  },
  form: {
    marginTop: theme.spacing(5)
  },
  buttonGroup: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5)
  }
}))

const formatUserEntities = (userEntities) => {
  const transformedUserEntities = transform(userEntities, (memo, value, key) => {
    if (value.email === '') return memo
    memo[key] = value
    return memo
  }, {})
  return Object.values(transformedUserEntities)
}

export default function EntityShareDialog (props) {
  const { setOpen, open, entity } = props
  const userInfo = useSelector(selectUserInfo)
  const classes = useStyles()

  const [state, setState] = React.useState({
    personA: { coOwner: false, email: '' },
    personB: { coOwner: false, email: '' },
    personC: { coOwner: false, email: '' }
  })

  const onCancel = () => {
    setOpen(false)
  }

  const onSubmit = async () => {
    setOpen(false)
    const userEntities = formatUserEntities(state)
    const { accessToken } = userInfo
    const body = {
      userEntities,
      entity
    }
    await addUserEntities(body, accessToken)
  }

  const handleChangeType = (event) => {
    setState({ ...state, [event.target.name]: { ...state[event.target.name], email: event.target.value } })
  }

  const handleChangeCheck = (event) => {
    setState({ ...state, [event.target.name]: { ...state[event.target.name], coOwner: event.target.checked } })
  }

  return (
    <Dialog onClose={() => setOpen(false)} aria-labelledby="modal-to-share-entity-information" open={open}>
      <DialogTitle id="modal-to-share-entity-information" onClose={() => setOpen(false)}>
        Share with Colleagues
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1">
          Enter email addresses of colleagues with whom you wish to share this map.
          We&apos;ll send them a link to sign up and create an account.
          By sharing, you are authorizing your colleagues to access the student information you have provided.
          Co-Owners may edit data on the map as well as view it.
        </Typography>

        <Grid container className={classes.form} spacing={3}>
          <Grid item sm={6}>
            <Typography variant="body1">Email</Typography>
          </Grid>
          <Grid item sm={6}>
            <Typography className={classes.formHeader} variant="body1">Co-Owner</Typography>
          </Grid>
          <Grid item sm={6}>
            <TextField
              fullWidth
              margin="dense"
              name="personA"
              label="Email Address"
              type="email"
              onChange={handleChangeType}
            />
          </Grid>
          <Grid container item sm={6} className={classes.formCheckbox}>
            <Checkbox checked={state.checkedA} onChange={handleChangeCheck} name="personA" />
          </Grid>
          <Grid item sm={6}>
            <TextField
              fullWidth
              onChange={handleChangeType}
              margin="dense"
              name="personB"
              label="Email Address"
              type="email"
            />
          </Grid>
          <Grid container item sm={6} className={classes.formCheckbox}>
            <Checkbox checked={state.checkedB} onChange={handleChangeCheck} name="personB" />
          </Grid>
          <Grid item sm={6}>
            <TextField
              fullWidth
              onChange={handleChangeType}
              margin="dense"
              name="personC"
              label="Email Address"
              type="email"
            />
          </Grid>
          <Grid container item sm={6} className={classes.formCheckbox}>
            <Checkbox checked={state.checkedC} onChange={handleChangeCheck} name="personC" />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <ButtonGroup className={classes.buttonGroup} color="secondary" aria-label="submit-or-cancel-button-group-for-sharing-entity-info-with-others">
          <Button onClick={onCancel}>
            Cancel
          </Button>
          <Button onClick={onSubmit}>
            Submit
          </Button>
        </ButtonGroup>
      </DialogActions>
    </Dialog>
  )
}
