import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import { Typography } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import EntityPageInfo from '../entityPage/EntityPageInfo'
import Number3Yellow from '../../../assets/Number-3-circle_Yellow.svg'
import { selectUserInfo } from '../../../redux/selectors'
import ServiceProvidersTable from '../entityPage/dashboard/ServiceProvidersTable.tsx'
import { computeStudentCounts } from '../entityPage/dashboard'
import { fetchEntity, fetchEntityData } from '../../../data/fetchData'
import useIsMountedRef from '../../../utils/useIsMountedRef'
import LoadingScreen from '../../pageDecorations/loadingScreen'

const useStyles = makeStyles((theme) => ({
  dataExchangePanel: {
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(12),
    paddingBottom: theme.spacing(8),
    borderRadius: '25px'
  },
  headerTitle: {
    fontSize: '26px',
    fontWeight: 'bold',
    marginBottom: theme.spacing(2),
    zIndex: 3,
    position: 'relative',
  },
  titleText: {
    marginBottom: theme.spacing(8),
    marginRight: theme.spacing(10)
  },
  topNumber: {
    width: '75px',
    height: '75px',
    top: '26px',
    right: '46px',
    position: 'relative',
    opacity: '50%'
  }
}))

export default function Step3 () {
  document.title = 'K12 Bridge to Broadband'
  const userInfo = useSelector(selectUserInfo)
  const classes = useStyles()
  const [entity, setEntity] = useState()
  const [entityDataExchange, setEntityDataExchange] = useState()
  const { associatedentities: associatedEntities } = userInfo
  const { entityUID } = associatedEntities[0]
  const accessToken = userInfo ? userInfo.accessToken : ''
  const isDataLoading = (!userInfo || !entity || (entity && entity.opt_in_exchange && !entityDataExchange))
  const isMountedRef = useIsMountedRef()

  useEffect(() => {
    if (entityUID && accessToken) {
      if (!entity) fetchEntity({ entityUID, accessToken, isMountedRef, setEntity })
      if (entity && entity.opt_in_exchange) fetchEntityData({ entityUID, accessToken, isMountedRef, setEntityData: setEntityDataExchange, program: 'data_exchange' })
    }
  }, [entityUID, accessToken, isMountedRef, entity, setEntityDataExchange])

  if (isDataLoading) {
    return (
      <div style={{ 'minHeight': '75vh' }}>
        <LoadingScreen />
      </div>
    )
  }

  if (!userInfo.num_data_exchange_entities) {
    return null
  }

  if (entityDataExchange) {
    if (entityDataExchange.studentCounts === undefined || !entityDataExchange.studentCounts.length) {
      return (
        <Redirect to="/uploadProcessing" />
      )
    }
    const { studentCounts } = computeStudentCounts({ entityDataExchange })
    return (
      <div style={{ 'minHeight': '75vh' }}>
        <Paper elevation={3} className={classes.dataExchangePanel}>
          <img alt="number3" src={Number3Yellow} className={classes.topNumber} />
          <Grid container>
            <Typography className={classes.headerTitle}>
              Congratulations! Your student address data was successfully uploaded
            </Typography>
            <Typography className={classes.titleText}>
              Below is a list of Internet Service Providers who may be able to offer coverage for your unconnected students. The percentage of potential adresses served refers to the service area each provider has reported that they cover to the FCC. Some addresses in this coverage area will not actually be served by the provider, so we ask each provider to verify their service for each address.
            </Typography>
          </Grid>
          <ServiceProvidersTable providers={entityDataExchange.providers} numStudentsInDistrict={studentCounts.num_students} />
        </Paper>
        <EntityPageInfo />
      </div>
    )
  }
}
