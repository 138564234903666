import React, { useState } from 'react'

// exporting this function to test seperately
export const updateStudents = ({ e, studentsToUpdate, student, field }) => {
  let studentToUpdate = studentsToUpdate.filter((s) => s.student_id === student.student_id)[0]
  if (studentToUpdate) {
    studentToUpdate = { ...studentToUpdate, [field]: e.target.value }
    return studentsToUpdate.map((s) => (s.student_id === student.student_id ? studentToUpdate : s))
  }
  student[field] = e.target.value
  return studentsToUpdate.concat(student)
}

export default function RadioButtons ({ student, field, studentsToUpdate, setStudentsToUpdate }) {
  const [isActive, setIsActive] = useState(student[field] === 'TRUE')
  const [isActiveFalse, setIsActiveFalse] = useState(student[field] === 'FALSE')

  const onChangeTrue = (e) => {
    setIsActive(true)
    setIsActiveFalse(false)
    setStudentsToUpdate(updateStudents({ e, studentsToUpdate, student, field }))
  }

  const onChangeFalse = (e) => {
    setIsActive(false)
    setIsActiveFalse(true)
    setStudentsToUpdate(updateStudents({ e, studentsToUpdate, student, field }))
  }

  return (
    <form>
      <div>
        <input
          type="radio"
          id={`studentTrue${student.student_id}${field}`}
          value="TRUE"
          checked={isActive}
          onChange={onChangeTrue}
        />
        <label>TRUE</label>
      </div>
      <div>
        <input
          type="radio"
          id={`studentFalse${student.student_id}${field}`}
          value="FALSE"
          checked={isActiveFalse}
          onChange={onChangeFalse}
        />
        <label>FALSE</label>
      </div>
    </form>
  )
}
