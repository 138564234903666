const qs = require('qs')

export function isDemo (location) {
  return location.pathname === '/demo'
}

export function findLocationParamValue ({ windowLocationSearch, targetParam }) {
  const queryString = qs.parse(windowLocationSearch)
  return queryString[`?${targetParam}`]
}
