import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import Typography from '@material-ui/core/Typography'
import MuiDialogActions from '@material-ui/core/DialogActions'
import MuiDialogContent from '@material-ui/core/DialogContent'
import { makeStyles, Link, Box } from '@material-ui/core'
import theme from '../../theme'

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
})

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 'auto',
    fontSize: theme.typography.h6.fontSize,
    color: theme.palette.info.light,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions)

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent)

const useStyles = makeStyles((theme) => ({
  link: {
    color: theme.palette.success.main,
    fontWeight: 'bold',
    '&:hover': {
      color: '#016164',
    }
  },
  downloadText: {
    fontWeight: 'bold',
    color: theme.palette.primary.dark
  }
}))

export default function CSVDownloadDialog (props) {
  const { setOpen, open, csvDataLink, program } = props
  const classes = useStyles()

  const onClick = () => {
    setOpen(false)
  }

  return (
    <Dialog
      onClose={onClick}
      aria-labelledby="modal-to-offer-information-about-downloading-provider-data"
      open={open}
      PaperProps={{
        style: {
          borderRadius: 15,
          padding: theme.spacing(4)
        }
      }}
    >
      <DialogTitle id="modal-to-offer-information-about-downloading-provider-data" onClose={onClick}>
        DOWNLOAD PROVIDER COVERAGE DATA
      </DialogTitle>
      <DialogContent>
        {program === 'data_exchange' ? (
          <Typography variant="body1">
            We&apos;ve shared a list of your students&apos; addresses with providers in your area. Providers have examined the addresses to determine where they can provide service. This file contains list of all of unconnected student addresses and the providers who have verified they can serve those addresses.
          </Typography>
        ) : (
          <Typography variant="body1">
            We&apos;ve generated a provider coverage data file that shows which providers in your area may be able to serve your students&apos; addresses with a broadband connection. The source is coverage data that service providers share with the FCC with their Form 477 filling each year.
          </Typography>
        )}
        <br />
        <Box
          border={1}
          display="flex"
          justifyContent="center"
          alignItems="center"
          borderRadius={15}
          p={3}
        >
          <Typography>
            This csv contains
            {' '}
            <Box display="inline" fontWeight="bold">confidential information that cannot be shared outside of the school district.</Box>
            {' '}
            For the avoidance of doubt, do not re-share information that specifically names ISPs or information related to a named ISP’s subscribers or end-users.
          </Typography>
        </Box>
      </DialogContent>
      <br />
      <DialogActions>
        <Typography variant="body1" className={classes.downloadText}>
          Download:
        </Typography>
        <Link href={csvDataLink} onClick={onClick} color="inherit" className={classes.link}>
          serviceability.csv
        </Link>
      </DialogActions>
      <br />
    </Dialog>
  )
}
