/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import EntityBanner from '../../pageDecorations/entityBanner'
import Map from '../../map'
import { fetchEntityDemo, fetchEntityDataDemo } from '../../../data/fetchData'
import useIsMountedRef from '../../../utils/useIsMountedRef'
import LoadingScreen from '../../pageDecorations/loadingScreen'

export default function DemoPage () {
  const [entity, setEntity] = useState()
  const [entityDataDigitalEquity, setEntityDataDigtialEquity] = useState()
  const isMountedRef = useIsMountedRef()
  const isDataLoading = (!entity || (entity && entity.digital_equity && !entityDataDigitalEquity))
  if (!isDataLoading) document.title = 'Home Digital Access Map: Hogwarts'

  useEffect(() => {
    if (!entity) fetchEntityDemo(isMountedRef, setEntity)
    if (entity) fetchEntityDataDemo(isMountedRef, setEntityDataDigtialEquity)
  }, [entity, isMountedRef, setEntity, setEntityDataDigtialEquity])

  if (isDataLoading) {
    return (
      <div style={{ 'minHeight': '75vh' }}>
        <LoadingScreen />
      </div>
    )
  }
  if (entityDataDigitalEquity) {
    return (
      <Grid container direction="column" spacing={4}>
        <Grid item>
          <EntityBanner
            entityName={entity.name}
            studentCounts={entityDataDigitalEquity.studentCounts[0]}
            providers={entityDataDigitalEquity.providers}
            adminOrOwnerScoped
          />
          <Map entity={{ ...entity, ...entityDataDigitalEquity }} program="digital_equity" />
        </Grid>
      </Grid>
    )
  }
}
