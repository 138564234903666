export const isEmpty = (object) => !Object.values(object).some((x) => (x !== null && x !== ''))

export function compareObjectValues (key, order = 'asc') {
  return function innerSort (a, b) {
    // eslint-disable-next-line no-prototype-builtins
    if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
      // property doesn't exist on either object
      return 0
    }

    const varA = (typeof a[key] === 'string')
      ? a[key].toUpperCase() : a[key]
    const varB = (typeof b[key] === 'string')
      ? b[key].toUpperCase() : b[key]

    let comparison = 0
    if (varA > varB) {
      comparison = 1
    } else if (varA < varB) {
      comparison = -1
    }
    return (
      (order === 'desc') ? (comparison * -1) : comparison
    )
  }
}

export const populateObjectWithKeyValues = (values) => values.reduce((obj, value) => {
  obj[value] = [value]
  return obj
}, [])

export const flatten = (arr) => arr.reduce((acc, val) => acc.concat(val), [])

export const countOccurrences = (arr, key) => {
  const counts = {}
  for (let i = 0; i < arr.length; i += 1) {
    const value = arr[i][key]
    counts[value] = counts[value] ? counts[value] + 1 : 1
  }
  return counts
}
