import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme, makeStyles } from '@material-ui/core/styles'
import eshLogo from '../../../assets/ESH_Logo_Horizontal.png'
import AccountDropdown from '../accountDropdown'
import { selectUserInfo } from '../../../redux/selectors'

export default function NavBar () {
  const theme = useTheme()
  const aboveSmallBreakpoint = useMediaQuery(theme.breakpoints.up('sm'))
  const userInfo = useSelector(selectUserInfo)
  const location = useLocation()
  const optInExchange = Number(userInfo.num_data_exchange_entities) > 0 || (location.pathname.includes('/dataExchange'))

  const useStyles = makeStyles(() => ({
    topLeft: {
      paddingBottom: '6px',
      cursor: 'pointer'
    },
    spanText: {
      'fontSize': 12.5, 'lineHeight': 0.5
    },
    gridESHImage: {
      textAlign: aboveSmallBreakpoint ? 'right' : 'left'
    },
    divider: {
      borderLeft: '2px solid lightgray',
      height: 42,
      margin: '0 9px',
      paddingRight: 9
    }
  }))
  const classes = useStyles()
  const history = useHistory()

  return (
    <AppBar>
      <Toolbar>
        <Grid container item justify="space-evenly" alignItems="center">
          <Grid item xs={12} sm={6}>
            <Grid container spacing={2} alignItems="center">
              <Grid item>
                <a target="_blank" rel="noopener noreferrer" href="https://www.educationsuperhighway.org/">
                  <img style={{ height: 54 }} alt="esh-logo-children-holding-hands" src={eshLogo} />
                </a>
              </Grid>
              <div className={classes.divider} />
              <Grid item>
                <Typography variant="h6" className={classes.topLeft} onClick={() => history.push('/')}>
                  {optInExchange ? (
                    <>
                      <span className={classes.spanText}>K-12 BRIDGE TO BROADBAND</span>
                      <br />
                    </>
                  ) : null}
                  {optInExchange ? <strong>DATA EXCHANGE</strong> : <strong>HOME DIGITAL ACCESS MAP</strong>}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid className={classes.gridESHImage} container item xs={12} sm={6} justify="flex-end">
            <AccountDropdown />
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  )
}
