import { combineReducers } from '@reduxjs/toolkit'
import userInfo from './reducers/userInfoSlice'
import entityInfo from './reducers/entityInfoSlice'
import mapTab from './reducers/mapTabSlice'

const rootReducer = combineReducers({
  userInfo,
  entityInfo,
  mapTab
})

export default rootReducer
