/* eslint-disable max-lines */
import React, { useState } from 'react'
import _ from 'lodash'
import { useParams } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Collapse from '@material-ui/core/Collapse'
import IconButton from '@material-ui/core/IconButton'
import Table from '@material-ui/core/Table'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
import Grid from '@material-ui/core/Grid'
import { formatNumberForDisplay } from '../../../../utils/stringUtils'
import CSVDownload from '../../../csv/csvDownload'

const useRowStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      borderBottom: 'none',
      padding: '2px'
    }
  },
  show: {
    color: theme.palette.info.light,
    cursor: 'pointer',
    fontSize: '12px',
    fontWeight: 'bold'
  },
  header: {
    fontSize: '16px',
    fontWeight: 'bold'
  },
  cell: {
    fontWeight: 'bold',
    padding: '2px'
  },
  cellTitle: {
    padding: '2px'
  }
}))

function RenderTableBody ({ rows, classes, open, setOpen }) {
  return rows.map((row) => {
    if (row.number < 4) {
      const center = true
      return renderRow({ classes, row, center })
    }
    if (row.number === 4) {
      return (
        <TableRow key={row.number} className={classes.root}>
          <TableCell onClick={() => setOpen(!open)} style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={2} className={classes.show}>
            <IconButton className={classes.show} aria-label="expand row" size="small">
              {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
            </IconButton>
            SHOW ALL...
            {renderCollapse({ classes, row, open })}
          </TableCell>
        </TableRow>
      )
    }
    return (
      <TableRow key={row.number} className={classes.root}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={2} className={classes.root}>
          {renderCollapse({ classes, row, open })}
        </TableCell>
      </TableRow>
    )
  })
}

const renderCollapse = ({ classes, row, open }) => (
  <Collapse in={open} timeout="auto" unmountOnExit>
    <Box>
      <Table size="small" aria-label="top-providers-contd">
        <TableBody className={classes.root}>
          {renderRow({ classes, row })}
        </TableBody>
      </Table>
    </Box>
  </Collapse>
)

const renderRow = ({ classes, row, center }) => (
  <TableRow className={classes.root} key={row.number}>
    <TableCell align="left" className={classes.cellTitle}>{formatNumberForDisplay(row.doing_business_as)}</TableCell>
    <TableCell align={center ? 'center' : 'right'} className={classes.cell}>{formatNumberForDisplay(row.number_of_students_served)}</TableCell>
  </TableRow>
)

export default function TopProviders ({ providers, accessToken }) {
  const [open, setOpen] = useState(false)
  const [csvDataLink, setCsvDataLink] = useState()
  const classes = useRowStyles()
  const { entityUID } = useParams()

  if (!providers || !providers.length) return null
  const rows = providers.map((provider, i) => ({
    doing_business_as: `${provider.doing_business_as} (${_.uniq(provider.service_types).join(', ')})`,
    number_of_students_served: parseInt(provider.number_of_students_served, 10),
    number: i
  }))
  return (
    <Grid container direction="column" justify="space-around" alignItems="flex-end" spacing={3}>
      <Grid item>
        <TableContainer>
          <Table stickyHeader aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell align="left" className={classes.header}>TOP PROVIDERS</TableCell>
                <TableCell align="center" className={classes.header}>
                  Number of Students
                  <br />
                  In provider&apos;s Service Area
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <RenderTableBody rows={rows} classes={classes} open={open} setOpen={setOpen} />
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item>
        <CSVDownload entityUID={entityUID} accessToken={accessToken} csvDataLink={csvDataLink} setCsvDataLink={setCsvDataLink} program="digital_equity" />
      </Grid>
    </Grid>
  )
}
