import React from 'react'

import ReactDOM from 'react-dom'
import './index.css'
import { Provider } from 'react-redux'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { Auth0Provider } from './react-auth0-spa'
import history from './utils/history'
import initLogRocket from './utils/logRocket'
import store from './redux/store'

initLogRocket()

// TODO: A function that routes the user to the right place
// after login. We should send them to their userLanding page.
const onRedirectCallback = (appState) => {
  history.push(
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  )
}

ReactDOM.render(
  <Auth0Provider
    domain={process.env.REACT_APP_AUTH0_DOMAIN}
    client_id={process.env.REACT_APP_AUTH0_CLIENT}
    redirect_uri={window.location.origin}
    onRedirectCallback={onRedirectCallback}
    audience={process.env.REACT_APP_API_IDENTIFIER}
    scope="read:current_user"
    useRefreshTokens
  >
    <Provider store={store}>
      <App />
    </Provider>
  </Auth0Provider>,

  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
