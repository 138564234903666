import React from 'react'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import { Typography } from '@material-ui/core'
import DoubleArrowRoundedIcon from '@material-ui/icons/DoubleArrowRounded'
import { Link } from 'react-router-dom'
import { selectUserInfo } from '../../../redux/selectors'
import Checkmark from '../../../assets/Checkmark-circle.svg'
import Number1Yellow from '../../../assets/Number-1-circle_Yellow.svg'
import Number2Yellow from '../../../assets/Number-2-circle_Yellow.svg'
import Number3Yellow from '../../../assets/Number-3-circle_Yellow.svg'
import EntityShareButton from '../userLandingPage/loggedInLandingPage/EntityShareButton'

const useStyles = makeStyles((theme) => ({
  dataExchangePanel: {
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(12),
    paddingBottom: theme.spacing(4),
    borderRadius: '25px'
  },
  stepContainer1: {
    margin: theme.spacing(2),
    marginBottom: theme.spacing(4)
  },
  stepContainers: {
    margin: theme.spacing(2)
  },
  entityNameHeader: {
    fontSize: '32px',
    fontWeight: 'bold',
    color: theme.palette.primary.dark,
    marginBottom: theme.spacing(2)
  },
  headerTitle: {
    fontSize: '26px',
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
    zIndex: 3,
    position: 'relative'
  },
  titleText: {
    fontSize: '14px',
    marginBottom: theme.spacing(4)
  },
  headerStepsTitle: {
    fontSize: '32px',
    fontWeight: 'bold',
    color: theme.palette.primary.dark,
    '&:hover': {
      color: '#222',
    }
  },
  cannotProceedTitle: {
    color: 'grey',
    fontSize: '32px',
    fontWeight: 'bold',
    '&:hover': {
      color: 'grey',
    }
  },
  cannotProceedText: {
    color: 'grey'
  },
  cannotProceedNumber: {
    opacity: '50%'
  },
  checkmark: {
    width: '75px',
    height: '75px',
    top: '26px',
    right: '46px',
    position: 'relative'
  }
}))

export default function DataExchangeLandingPageConnected () {
  document.title = 'K12 Bridge to Broadband: Data Exchange'
  const userInfo = useSelector(selectUserInfo)
  return (
    <DataExchangeLandingPage
      userInfo={userInfo}
    />
  )
}

export const getStepColorsAndLinks = ({ step, step3Enabled, dashboardViewable, isStateExceptionUser, numDataExchangeEntities, classes, associatedEntities }) => {
  const colorOfStepTitle = step === 3 ? (step3Enabled ? classes.headerStepsTitle : classes.cannotProceedTitle) : (!isStateExceptionUser ? classes.headerStepsTitle : classes.cannotProceedTitle)
  const colorOfStepText = step === 3 ? (step3Enabled ? null : classes.cannotProceedText) : (!isStateExceptionUser ? null : classes.cannotProceedText)
  const colorOfStepNumber = step === 3 ? (step3Enabled ? null : classes.cannotProceedNumber) : (!isStateExceptionUser ? null : classes.cannotProceedNumber)
  const stepLink = step === 3 ? (step3Enabled ? (Number(numDataExchangeEntities) === 1 ? (dashboardViewable ? `/entities/${associatedEntities[0].entityUID}` : '/dataExchange/Step3') : '/?from=dataExchange') : '/#') : (!isStateExceptionUser ? `/dataExchange/Step${step}` : '/#')
  return { colorOfStepTitle, colorOfStepText, colorOfStepNumber, stepLink }
}

export function DataExchangeLandingPage ({ userInfo }) {
  const classes = useStyles()
  const { data_exchange_dashboard_viewable: dashboardViewable, num_data_exchange_entities: numDataExchangeEntities, associatedentities: associatedEntities, isStateExceptionUser, data_exchange_uploaded: dataExchangeUploaded } = userInfo
  const step3Enabled = numDataExchangeEntities > 0 && dataExchangeUploaded
  const { colorOfStepTitle: colorOfStep3Title, colorOfStepText: colorOfStep3Text, colorOfStepNumber: colorOfStep3Number, stepLink: step3Link } = getStepColorsAndLinks({ step: 3, step3Enabled, isStateExceptionUser, numDataExchangeEntities, classes, associatedEntities, dashboardViewable })
  const { colorOfStepTitle: colorOfStep1And2Title, colorOfStepText: colorOfStep1And2Text, colorOfStepNumber: colorOfStep1And2Number, stepLink: step2Link } = getStepColorsAndLinks({ step: 2, step3Enabled, isStateExceptionUser, numDataExchangeEntities, classes, associatedEntities, dashboardViewable })
  const { stepLink: step1Link } = getStepColorsAndLinks({ step: 1, isStateExceptionUser, numDataExchangeEntities, classes, associatedEntities, dashboardViewable })

  // reformatted entity info to match the keys needed for POST request when share button is used
  const formattedEntity = {
    name: associatedEntities[0].entityName,
    entity_uid: associatedEntities[0].entityUID
  }

  if (!numDataExchangeEntities) {
    return null
  }

  return (
    <div>
      { associatedEntities && associatedEntities.length === 1 &&
        (
          <Typography className={classes.entityNameHeader}>
            { associatedEntities[0].entityName }
          </Typography>
        ) }
      <div style={{ 'minHeight': '75vh' }}>
        <Paper elevation={3} className={classes.dataExchangePanel}>
          <img alt="checkmark" src={Checkmark} className={classes.checkmark} />
          <Typography className={classes.headerTitle}>
            Welcome to the K-12 Bridge to Broadband Data Exchange
          </Typography>
          <Typography className={classes.titleText}>
            Complete the steps below to get a comprehensive list of Internet Service Providers that can connect your unconnected students for remote learning.
          </Typography>
          <Grid container spacing={2} className={classes.stepContainer1}>
            <Grid item xs={1}>
              <img alt="number1" src={Number1Yellow} className={colorOfStep1And2Number} />
            </Grid>
            <Grid item xs={11}>
              <Typography className={colorOfStep1And2Title} component={Link} to={step1Link}>
                Prepare your students’ address data for upload
                <DoubleArrowRoundedIcon />
              </Typography>
              <Typography className={colorOfStep1And2Text}>
                Use our data upload template to create a list of your students’ addresses.
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2} className={classes.stepContainers}>
            <Grid item xs={1}>
              <img alt="number2" src={Number2Yellow} className={colorOfStep1And2Number} />
            </Grid>
            <Grid item xs={11}>
              <Typography className={colorOfStep1And2Title} component={Link} to={step2Link}>
                Upload your students’ address data
                <DoubleArrowRoundedIcon />
              </Typography>
              <Typography className={colorOfStep1And2Text}>
                Submit the completed template to exchange data with Internet Service Providers in your area.
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2} className={classes.stepContainers}>
            <Grid item xs={1}>
              <img alt="number3" src={Number3Yellow} className={colorOfStep3Number} />
            </Grid>
            <Grid item xs={11}>
              <Typography className={colorOfStep3Title} component={Link} to={step3Link}>
                Review the results
                <DoubleArrowRoundedIcon />
              </Typography>
              <Typography className={colorOfStep3Text}>
                Results will be available once Internet Service Providers have verified service availability for your students’ addresses.
              </Typography>
              { step3Link.includes('entities') &&
                  (
                    <EntityShareButton entity={formattedEntity} />
                  ) }
            </Grid>
          </Grid>
        </Paper>
      </div>
    </div>
  )
}
