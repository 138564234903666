import React, { useEffect, useState } from 'react'
import Typography from '@material-ui/core/Typography'
import { fetchEntities } from '../../../../data/fetchData'
import { maxEntityCountBeforeFiltering } from '../../../../utils/constants'
import EntityLinkTable from './EntityLinkTable'
import CSVTemplateDownload, { UploadPageButton } from '../../../csv/csvTemplateDownload'
import LoadingScreen from '../../../pageDecorations/loadingScreen'

export default function LoggedInLandingPageConnected ({ userInfo, isUserValidated, isStateExceptionUser }) {
  const [entities, setEntites] = useState()
  const { accessToken } = userInfo
  const isDataLoading = !entities

  useEffect(() => {
    fetchEntities({ setEntites, accessToken })
  }, [accessToken, setEntites])

  if (!userInfo) return null
  if (isDataLoading) {
    return (
      <div style={{ 'minHeight': '75vh' }}>
        <LoadingScreen />
      </div>
    )
  }
  return (
    <LoggedInLandingPage
      entities={entities}
      isUserValidated={isUserValidated}
      isStateExceptionUser={isStateExceptionUser}
    />
  )
}

export function LoggedInLandingPage ({ entities, isUserValidated, isStateExceptionUser }) {
  const entityCount = entities.length
  const availableMapsText = findEntityLinksHeader({ entityCount })
  if (isStateExceptionUser && entityCount === 0) {
    return (
      <div style={{ 'minHeight': '75vh' }}>
        <h4>{availableMapsText}</h4>
      </div>
    )
  }
  if (!isStateExceptionUser && entityCount === 0) {
    return <CSVTemplateDownload isUserValidated={isUserValidated} />
  }

  return (
    <div style={{ 'minHeight': '75vh' }}>
      <div>
        <h4>{availableMapsText}</h4>
        <div>
          <EntityLinkTable entities={entities} />
        </div>
        {isUserValidated && !isStateExceptionUser && (
          <>
            <br />
            <Typography varint="body1">To upload additional student address data:</Typography>
            <br />
            <UploadPageButton isUserValidated={isUserValidated} />
          </>
        )}
      </div>
    </div>
  )
}

const findEntityLinksHeader = ({ entityCount }) => {
  if (entityCount === 0) return 'A link to your District Map will be displayed here once available.'
  if (entityCount === 1) return 'Your District Map'
  if (entityCount > 1 && entityCount < maxEntityCountBeforeFiltering) return 'Available District Maps'
  return ''
}
