import mapboxgl from 'mapbox-gl'
import AddLayers from './AddLayer'
import AddLayersUnconnected from './AddLayerUnconnected'

const _ = require('lodash')

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN

const MapboxSetup = ({ mapContainer, center, defaultZoom, geojsonURL, geojsonUnconnectedURL, setMap, studentAddressCount, providers }) => {
  if (!studentAddressCount || !providers || !center) return
  const map = initializeMap({ mapContainer, center, defaultZoom })
  /*
   in order to fulfil the request that smaller districts not cluster, but also meet the need to
   display a popup for multiple coincident points, we disguise clusters as single points, and set
   the threshold for cluster creation to be very high. to determine whether or not to do this, we
   set the following boolean. here, 7500 is the number of students below which we want to disguise
   clustering
   */
  const disguiseClusters = studentAddressCount < 7500;
  const insufficientInternetExpression = ['==', ['get', 'sufficient_internet'], false]
  const noDeviceExpression = ['==', ['get', 'device'], false]
  map.on('load', () => {
    addSource({ map, geojsonURL, providers, insufficientInternetExpression, disguiseClusters })
    AddLayers({ map, providers, insufficientInternetExpression, noDeviceExpression, disguiseClusters })
    addSourceUnconnected({ map, geojsonUnconnectedURL })
    AddLayersUnconnected({ map, disguiseClusters })
    setMap(map)
  })
}

const initializeMap = ({ mapContainer, center, defaultZoom }) => {
  const map = new mapboxgl.Map({
    container: mapContainer.current,
    style: process.env.REACT_APP_MAPBOX_STYLE_URL,
    center,
    zoom: defaultZoom
  })
  map.addControl(new mapboxgl.ScaleControl({ position: 'bottom-left', unit: 'imperial' }))
  map.addControl(new mapboxgl.NavigationControl(), 'top-left')
  map.dragRotate.disable()
  map.touchZoomRotate.disableRotation()
  return map
}
const addSource = ({ map, geojsonURL, providers, insufficientInternetExpression, disguiseClusters }) => {
  const providerExpressions = createProviderExpressions({ providers })
  const sourceSettings = createSourceSettings({ geojsonURL, providerExpressions, insufficientInternetExpression, disguiseClusters })
  map.addSource('students', sourceSettings)
}

const addSourceUnconnected = ({ map, geojsonUnconnectedURL }) => {
  const sourceSettings = {
    type: 'geojson',
    data: geojsonUnconnectedURL
  }
  map.addSource('studentsUnconnected', sourceSettings)
}

const createSourceSettings = ({ geojsonURL, providerExpressions, insufficientInternetExpression, disguiseClusters }) => {
  // create separate counts for each service provider and whether or not at least one student has insufficient internet
  const clusterProperties = _.transform(providerExpressions, (memo, value, key) => {
    memo[key] = ['+', ['case', value, 1, 0]]
    return memo
  }, {})
  clusterProperties.insufficientInternet = ['+', ['case', insufficientInternetExpression, 1, 0]]

  return {
    type: 'geojson',
    data: geojsonURL,
    cluster: true,
    clusterRadius: disguiseClusters ? 3 : 50,
    clusterProperties,
    clusterMaxZoom: 18,
    maxzoom: 18,
  }
}

const createProviderExpressions = ({ providers }) => providers.reduce((accum, current) => {
  accum[current.service_provider_id] = ['in', current.service_provider_id, ['get', 'serviceProviderIds']]
  return accum
}, {})

export default MapboxSetup
