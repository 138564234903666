import React, { useEffect } from 'react'
import { Route, Redirect, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useAuth0 } from '../../../react-auth0-spa'
import { checkShouldRedirectToProfile, checkIsUserValidated } from '../../pages/userLandingPage'
import { selectUserInfo } from '../../../redux/selectors'

export default function PrivateRoute ({ path, children }) {
  const location = useLocation()
  const userInfo = useSelector(selectUserInfo)
  const isProfilePage = location.pathname === '/profile'
  const isDataExchangeSignUpPage = location.pathname === '/dataExchange/signUp'
  const { loading, isAuthenticated, loginWithRedirect } = useAuth0()
  const isUserValidated = checkIsUserValidated(userInfo.validation_status)
  const shouldRedirectToProfile = checkShouldRedirectToProfile({ userInfo, isUserValidated })

  useEffect(() => {
    const loginWithRedirectParameters = {
      appState: { targetUrl: window.location.pathname }
    }
    if (isDataExchangeSignUpPage) {
      loginWithRedirectParameters.screen_hint = 'signup'
    }
    if (loading || isAuthenticated) {
      return
    }
    const fn = async () => {
      await loginWithRedirect(loginWithRedirectParameters)
    }
    fn()
  }, [loading, isAuthenticated, loginWithRedirect, path, isDataExchangeSignUpPage])

  if (!isAuthenticated) {
    return <div style={{ height: '80vh' }} />
  }
  if (shouldRedirectToProfile && !isProfilePage) {
    return <Redirect to="/profile" />
  }
  return (
    <Route path={path}>
      {children}
    </Route>
  )
}
