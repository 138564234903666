import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import { Typography } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import EntityPageInfo from '../entityPage/EntityPageInfo'
import Number2Yellow from '../../../assets/Number-2-circle_Yellow.svg'
import CSVUploadForm from '../../csv/csvUploader/CSVUploadForm'
import { selectUserInfo } from '../../../redux/selectors'

const useStyles = makeStyles((theme) => ({
  dataExchangePanel: {
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(12),
    paddingBottom: theme.spacing(8),
    borderRadius: '25px'
  },
  headerTitle: {
    fontSize: '26px',
    fontWeight: 'bold',
    marginBottom: theme.spacing(2),
    zIndex: 3,
    position: 'relative',
  },
  titleText: {
    marginBottom: theme.spacing(4),
    marginRight: theme.spacing(10)
  },
  topNumber: {
    width: '75px',
    height: '75px',
    top: '26px',
    right: '46px',
    position: 'relative',
    opacity: '50%'
  }
}))

export default function Step2 () {
  document.title = 'K12 Bridge to Broadband: Upload'
  const classes = useStyles()
  const userInfo = useSelector(selectUserInfo)
  if (!userInfo.num_data_exchange_entities) {
    return null
  }

  return (
    <>
      <Paper elevation={3} className={classes.dataExchangePanel}>
        <img alt="number2" src={Number2Yellow} className={classes.topNumber} />
        <Typography className={classes.headerTitle}>
          Upload your addresses to the Data Exchange
        </Typography>
        <Typography className={classes.titleText}>
          Please ensure you have formatted your student address data as outlined in
          {' '}
          <Link to="/dataExchange/Step1" style={{ color: '#019297', fontWeight: 'bold' }}>
            Step 1
            {' '}
          </Link>
          {' '}
          and checked for common upload errors.
        </Typography>
        <Typography className={classes.titleText}>
          <strong>Ready to Exchange?</strong>
          <br />
          Complete this form to upload the data and initiate the data exchange with Internet Service Providers.
        </Typography>
        <CSVUploadForm program="data_exchange" />
      </Paper>
      <EntityPageInfo />
    </>
  )
}
