import numeral from 'numeral'

export function formatNumber (number, format = '0,0') {
  return number && numeral(number).format(format)
}

export function formatNumberForDisplay (number) {
  if (number > 0) {
    return formatNumber(number)
  }
  if (Number(number) === 0) {
    return 'N/A'
  }
  if (Number.isNaN(number)) {
    return 'Unknown'
  }
  return number
}

export function formatPercent (decimal) {
  if (decimal < 0.05) return '< 1%'
  return decimal && `${(decimal * 100).toFixed(0)}%`
}

/**
 * This function takes in a string, and returns the same converted to lower-case, with only the
 * first letter capitalized
 * @param {String} string a string to adjust the capitalization of
 * @returns the parameter string with its capitalization adjusted
 */
export const titleizeWord = function (string) {
  if (typeof string !== 'string') return string
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
}

/**
 * This function expands on the titleizeWord function, also replacing common separators "-" and "_"
 * with spaces (" ")
 *
 * @param {String} sentence – a string to split on the usual separators
 * @returns the parameter string, with separators replaced with spaces, and the pieces titleized
 */
export function titleize (sentence) {
  // make sure we're dealing with an a string
  if (!sentence?.split) return sentence
  // this regex splits our sentence on the characters, "-", "_", and " "
  return sentence
    .split(/[-, _, \s]/)
    .map(titleizeWord)
    .join(' ')
}

export function createAddress (addressComponents) {
  const streetAddress = (`${addressComponents.number || ''} ${addressComponents.street || ''} ${addressComponents.unit || ''}`).trim()
  return (`${streetAddress}, ${addressComponents.city || ''}, ${addressComponents.state || ''} ${addressComponents.zip || ''}`).trim()
}
