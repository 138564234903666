import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { AppBar, Tab, Tabs, Grid, useMediaQuery } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined'
import RoomTwoToneIcon from '@material-ui/icons/RoomTwoTone'
import LogRocket from 'logrocket'
import SaveAltIcon from '@material-ui/icons/SaveAlt'
import { fetchEntity, fetchEntityData } from '../../../data/fetchData'
import useIsMountedRef from '../../../utils/useIsMountedRef'
import { transformEntityName } from '../../../utils/constants'
import { selectUserInfo, selectEntityInfo, selectMapTab } from '../../../redux/selectors'
import { setInitial } from '../../../redux/reducers/entityInfoSlice'
import { setMapTab } from '../../../redux/reducers/mapTabSlice'
import LoadingScreen from '../../pageDecorations/loadingScreen'
import { idProps } from '../../pageDecorations/editDataTabs'
import Map from '../../map'
import EntityBanner from '../../pageDecorations/entityBanner'
import EntityPageBanner from './EntityPageBanner'
import Dashboard from './dashboard'
import DownloadTab from './DownloadTab'
import EntityPageHowItWorks from './EntityPageHowItWorks'
import ForbiddenErrorPage from '../errorPages/forbiddenError'

export default function EntityPage () {
  const [entity, setEntity] = useState()
  const [entityDataDigitalEquity, setEntityDataDigtialEquity] = useState()
  const [entityDataExchange, setEntityDataExchange] = useState()
  const [currentTab, setCurrentTab] = useState(0)
  const [errored, setErrored] = useState(false)
  const { entityUID } = useParams()
  const userInfo = useSelector(selectUserInfo)
  const entityInfo = useSelector(selectEntityInfo)
  const isMountedRef = useIsMountedRef()
  const dispatch = useDispatch()
  const isDataLoading = (!userInfo || !entity || (entity && entity.opt_in_exchange && !entityDataExchange) || (entity && entity.digital_equity && !entityDataDigitalEquity))
  const accessToken = userInfo ? userInfo.accessToken : ''
  const mapTab = useSelector(selectMapTab)
  const program = mapTab.dataExchange.open ? 'data_exchange' : 'digital_equity'
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))
  const below660px = useMediaQuery('(max-width:825px)')
  const useStyles = makeStyles((theme) => ({
    appBar: {
      marginTop: theme.spacing(4),
      borderRadius: '25px 25px 0px 0px'
    },
    labelSpan: {
      paddingLeft: '4px',
      whiteSpace: 'nowrap'
    },
    selectedLabelSpan: {
      paddingLeft: '4px',
      fontWeight: 'bold',
      color: theme.palette.secondary.main,
      whiteSpace: 'nowrap',
    },
    dashboardLabel: {
      display: 'flex',
      alignItems: 'center'
    },
    appBarContainer: {
      flexDirection: isMobile ? 'column' : 'row',
      alignContent: below660px ? 'space-around' : 'stretch',
      justifyContent: below660px ? 'space-around' : 'end'
    },
    downloadTab: {
      minWidth: '285px',
      width: '285px'
    }
  }))
  const classes = useStyles()

  useEffect(() => {
    if (entityUID && accessToken) {
      if (entityInfo.entity_uid && entityInfo.entity_uid === entityUID) {
        setEntity(entityInfo)
        return
      }
    }
    const fetchData = async (setEntity) => {
      try {
        const entityFromAPI = await fetchEntity({ entityUID, accessToken, isMountedRef, setEntity })
        setEntity(entityFromAPI)
        setEntitySlice({ entity, dispatch })
      } catch (e) {
        if (process.env.REACT_APP_ENV === 'production') {
          LogRocket.captureException(e)
        }
        setErrored(true)
      }
    }
    fetchData(setEntity)
    if (entity && entity.digital_equity) fetchEntityData({ entityUID, accessToken, isMountedRef, setEntityData: setEntityDataDigtialEquity, program: 'digital_equity' })
    if (entity && entity.opt_in_exchange) fetchEntityData({ entityUID, accessToken, isMountedRef, setEntityData: setEntityDataExchange, program: 'data_exchange' })
  }, [entityUID, accessToken, isMountedRef, entity, entityInfo, dispatch, setEntityDataDigtialEquity, setEntityDataExchange, setEntity, setErrored])

  if (errored) {
    return <ForbiddenErrorPage />
  }

  if (isDataLoading) {
    return (
      <div style={{ 'minHeight': '75vh' }}>
        <LoadingScreen />
      </div>
    )
  }
  if (entity.name) {
    document.title = userInfo.num_data_exchange_entities > 0 ? `K12 Bridge to Broadband: ${entity.name}` : `Home Digital Access Map: ${entity.name}`
  }
  const toggle = entity.digital_equity && entity.opt_in_exchange
  const entityName = `${transformEntityName(entity.name)} (${entity.stateCode})`
  if (!toggle && entity.digital_equity && entityDataDigitalEquity) {
    return (
      <Grid container direction="column">
        <EntityBanner
          entityName={entityName}
          studentCounts={entityDataDigitalEquity.studentCounts[0]}
          providers={entityDataDigitalEquity.providers}
          adminOrOwnerScoped={entity.adminOrOwnerScoped}
          accessToken={accessToken}
        />
        <Map entity={{ ...entity, ...entityDataDigitalEquity }} program="digital_equity" />
      </Grid>
    )
  }

  const handleTabsChange = (_, value) => {
    setCurrentTab(value)
    if (!value) dispatch(setMapTab({ value }))
  }

  if (entityDataExchange) {
    return (
      <>
        <EntityPageBanner entity={{ ...entity, ...entityDataExchange }} entityUID={entityUID} accessToken={accessToken} program="data_exchange" />
        <AppBar position="static" className={classes.appBar}>
          <Grid container spacing={1} className={classes.appBarContainer}>
            <Grid item xs={12}>
              <Tabs value={currentTab} onChange={handleTabsChange} aria-label="dashboard-tabs" variant="fullWidth">
                <Tab label={<DashboardLabel classes={classes} labelText="dashboard" currentTab={currentTab} />} {...idProps(0)} />
                <Tab label={<DashboardLabel classes={classes} labelText="map" currentTab={currentTab} />} {...idProps(1)} />
                {!isMobile && <Tab label={<DashboardLabel classes={classes} labelText="download" currentTab={currentTab} />} {...idProps(2)} className={classes.downloadTab} /> }
              </Tabs>
            </Grid>
          </Grid>
        </AppBar>
        <Dashboard
          currentTab={currentTab}
          entity={entity}
          entityDataExchange={entityDataExchange}
          entityDataDigitalEquity={entityDataDigitalEquity}
          formattedProviders={entityDataExchange && entityDataExchange.providers}
          toggle={toggle}
        />
        {!isMobile && <DownloadTab entityUID={entityUID} accessToken={accessToken} program={program} currentTab={currentTab} /> }
        <EntityPageHowItWorks />
      </>
    )
  }
}

const setEntitySlice = ({ entity, dispatch }) => {
  if (entity) {
    dispatch(setInitial({
      entityInfoData: {
        ...entity
      }
    }))
  }
}

function DashboardLabel ({ classes, labelText, currentTab }) {
  const shouldApplySelectedClass = (currentTab === 0 && labelText === 'dashboard') || (currentTab === 1 && labelText === 'map') || (currentTab === 2 && labelText === 'download')
  return (
    <div className={classes.dashboardLabel}>
      {labelText === 'dashboard' ? <AssessmentOutlinedIcon /> : labelText === 'map' ? <RoomTwoToneIcon /> : <SaveAltIcon /> }
      <span className={shouldApplySelectedClass ? classes.selectedLabelSpan : classes.labelSpan}>
        {labelText === 'dashboard' ? 'Dashboard' : labelText === 'map' ? 'Map of Unconnected Students' : 'Download Connectivity Data'}
      </span>
    </div>
  )
}
