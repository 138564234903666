import { createSlice } from '@reduxjs/toolkit'

export const initialState = {}

const slice = createSlice({
  name: 'entityInfo',
  initialState,
  reducers: {
    setInitial: (state, action) => {
      const { entityInfoData } = action.payload
      Object.assign(state, entityInfoData)
    }
  }
})

export const {
  setInitial
} = slice.actions

export default slice.reducer
