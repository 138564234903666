import React, { useState, useEffect } from 'react'
import LogRocket from 'logrocket'
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete'
import { TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { findEntityUIDFromURL } from '../../csv/csvUploader/handlers'
import { searchEntitiesForTypeahead } from '../../../data/fetchData'

const setValueFromURL = ({ setValue, entities, submitCount, setFieldValue }) => {
  if (submitCount > 0) return
  const entityUID = findEntityUIDFromURL()
  const selected = entities.find((entity) => entity.entity_uid === entityUID)
  if (selected) {
    setValue(selected)
    setFieldValue('entityUID', selected.entity_uid)
    setFieldValue('name', selected.name)
  }
}

export default function EntityNameTypeahead ({ setFieldValue, handleBlur, touched, userInfo, errors, values, submitCount, setValue, value }) {
  /* eslint camelcase: "off" */

  const { accessToken } = userInfo
  const [options, setOptions] = useState([])

  const classes = useStyles()
  useEffect(() => {
    const fetchData = async () => {
      try {
        const entities = await searchEntitiesForTypeahead(accessToken)
        setOptions(entities)
        setValueFromURL({ setValue, entities, submitCount, setFieldValue })
      } catch (e) {
        if (process.env.REACT_APP_ENV === 'production') {
          LogRocket.captureException(e)
        }
        /* eslint no-console: "off" */
        console.error('error', e)
      }
    }
    fetchData()
  }, [accessToken, submitCount, setFieldValue, setValue, value])

  const limitOfDisplayedOptions = 20
  const filterOptions = createFilterOptions({ limit: limitOfDisplayedOptions })

  return (
    <Autocomplete
      options={options}
      value={value}
      filterOptions={filterOptions}
      getOptionLabel={(option) => (option.name ? `${option.name} (${option.state_code})` : '')}
      getOptionSelected={(option, value) => option.entityUID === value.entityUID}
      className={classes.entityNameTypeahead}
      onChange={(_event, value) => (value ? setValue(value, setFieldValue) : setValue({}, setFieldValue))}
      name="entityUID"
      id="entityUID"
      renderInput={(params) => (
        <TextField
          {...params}
          error={Boolean(touched.entityUID && errors.entityUID)}
          fullWidth
          helperText={touched.entityUID && errors.entityUID}
          label="Entity Name"
          name="entityName"
          onBlur={handleBlur}
          type="entityName"
          value={values.entityName}
          variant="outlined"
        />
      )}
    />
  )
}

const useStyles = makeStyles((theme) => ({
  typeahead: {
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(1),
    width: '560px'
  },
  labelText: {
    color: 'rgba(0, 0, 0, 0.54)',
    margin: 0,
    padding: 0
  },
  entityNameTypeahead: {
    marginBottom: theme.spacing(2)
  }
}))
