import React from 'react'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

export default function EntityPageHowItWorks () {
  const theme = useTheme()
  const belowXsBreakPoint = useMediaQuery(theme.breakpoints.down('xs'))
  const below660px = useMediaQuery('(max-width:825px)'); // based on dev console - width was 660px
  const useStyles = makeStyles((theme) => ({
    description: {
      color: theme.palette.text.secondary,
      marginTop: '12px'
    },
    greenText: {
      color: theme.palette.success.main
    },
    yellowText: {
      color: theme.palette.success.light
    },
    purpleText: {
      color: theme.palette.secondary.main
    },
    studentLearningImage: {
      width: belowXsBreakPoint ? '300px' : '350px',
      position: 'relative',
      bottom: below660px ? '0px' : '80px'
    },
    howItWorksPanel: {
      marginTop: theme.spacing(8),
      padding: theme.spacing(4),
      borderRadius: '25px',
      maxHeight: below660px ? 'none' : '200px'
    },
    howItWorksContainer: {
      flexDirection: below660px ? 'column' : 'row',
      alignContent: below660px ? 'center' : 'stretch'
    }
  }))
  const classes = useStyles()
  return (
    <Paper elevation={3} className={classes.howItWorksPanel}>
      <Grid container spacing={3} className={classes.howItWorksContainer}>
        <Grid item xs={12} sm={7}>
          <Typography variant="h5">HOW IT WORKS</Typography>
          <Typography variant="body2" className={classes.description}>
            After you upload student addresses, we anonymize and share them with Internet Service Providers that offer coverage in your area. They return these addresses, marking them as
            {' '}
            <strong className={classes.greenText}>active service</strong>
            ,
            {' '}
            <strong className={classes.yellowText}>service available</strong>
            {' '}
            , or
            {' '}
            <strong>service not available</strong>
            . A downloadable file with all the coverage options for each address you submit is available by clicking the
            {' '}
            <strong className={classes.purpleText}>Download Connectivity Data</strong>
            {' '}
            button above.
          </Typography>
        </Grid>
        <Grid item xs={12} sm={5}>
          <img className={classes.studentLearningImage} alt="child learning" src="https://digital-bridge-assets.s3-us-west-1.amazonaws.com/Circle+Image_Boy+WiFi.svg" />
        </Grid>
      </Grid>
    </Paper>
  )
}
