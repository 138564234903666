/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react'
import isEmpty from 'lodash.isempty'
import { useSelector } from 'react-redux'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Hidden, List, Paper } from '@material-ui/core/'
import { Link } from 'react-router-dom'
import { selectUserInfo } from '../../../redux/selectors'
import StateSelection from './StateSelection'

const useStyles = makeStyles((theme) => ({
  picture: {
    borderRadius: '50%',
    height: '150px',
    margin: '0 auto'
  },
  accountInfo: {
    listStyle: 'none',
    lineHeight: 2,
    margin: theme.spacing(3, 0),
    padding: 0
  },
  profilePanel: {
    marginTop: theme.spacing(18),
    padding: theme.spacing(8),
    borderRadius: '25px'
  },
  displayUserNextActionPromptPaper: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(6),
    borderRadius: '15px',
    backgroundColor: '#efe6ec', // 90% less of shade theme.palette.secondary.main
    border: '1px solid #bf9cb2', // 60% less of shade theme.palette.secondary.main
    overflowWrap: 'break-word'
  },
  profileTitle: {
    marginBottom: theme.spacing(4),
    color: 'grey',
    fontSize: '32px'
  }
}))

export default function ProfilePagePageConnected () {
  const userInfo = useSelector(selectUserInfo)
  return (
    <ProfilePage
      userInfo={userInfo}
    />
  )
}

export function ProfilePage ({ userInfo }) {
  const classes = useStyles()
  if (isEmpty(userInfo)) return null
  document.title = userInfo.num_data_exchange_entities > 0 ? 'K12 Bridge to Broadband: Profile' : 'Home Digital Access Map: Profile'
  const displayUserNextActionPrompt = ({ userInfo }) => {
    if (!isEmpty(userInfo) && !userInfo.email_verified) {
      return (
        <Paper variant="outlined" className={classes.displayUserNextActionPromptPaper}>
          <Typography variant="body1">
            <strong>
              Verify your email address
            </strong>
          </Typography>
          <Typography variant="body1">
            Please check your email inbox for a verification link. Click the verification link to activate your account.
          </Typography>
        </Paper>
      )
    }
    if (userInfo && !isEmpty(userInfo) && userInfo.email_verified && userInfo.validation_status === 'pending') {
      return (
        <Paper variant="outlined" className={classes.displayUserNextActionPromptPaper}>
          <Typography variant="body1">
            <strong>
              Account Status: Pending
            </strong>
          </Typography>
          <Typography variant="body1">Your account has not yet been activated by an administrator. Before activating we verify that your email address is associated with a government agency, a school district or school, or other state education entity. If you signed up more than 24 hours ago and you are still seeing this message, please contact us at tech.team@educationsuperhighway.org for assistance.</Typography>
        </Paper>
      )
    }
    return null
  }

  return (
    <div style={{ 'minHeight': '75vh' }}>
      <Paper elevation={3} className={classes.profilePanel}>
        <Grid item xs={12}>
          {displayUserNextActionPrompt({ userInfo })}
        </Grid>
        <Typography className={classes.profileTitle}>
          PROFILE
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <div>
              <img src={userInfo.picture} alt="profilePicture" className={classes.picture} />
            </div>
            <List className={classes.accountInfo}>
              <Typography variant="body1">
                <strong>Name:</strong>
                {' '}
                {userInfo.name}
              </Typography>
              <br />
              <Typography variant="body1">
                <strong>Email:</strong>
                {' '}
                {userInfo.email}
              </Typography>
              <br />
              <Typography variant="body1">
                <strong>Email Verified:</strong>
                {' '}
                {userInfo.email_verified.toString()}
              </Typography>
              <br />
              <Typography variant="body1">
                <strong>Account Status:</strong>
                {' '}
                {userInfo.validation_status}
              </Typography>
              <br />
              <Typography variant="body1">
                <StateSelection usersStateCode={userInfo && userInfo.state_code} userId={userInfo && userInfo.user_id} isModalOpen={userInfo && (!userInfo.state_code || !userInfo.agreed_to_terms)} accessToken={userInfo && userInfo.accessToken} agreedToTerms={userInfo && userInfo.agreed_to_terms} />
              </Typography>
            </List>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Grid item xs={12}>
              <List className={classes.accountInfo}>
                <Hidden xsDown>
                  <div className={classes.picture} />
                </Hidden>
                <Grid container item xs={12} spacing={3}>
                  <Grid item xs={12} sm={3}>
                    <Typography variant="body1">
                      <strong>Associated Entities:</strong>
                      {' '}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={9}>
                    <div>
                      {userInfo.associatedentities.map(({ entityName, entityUID, hide, opt_in_exchange, num_data_exchange_providers }) => (
                        <div key={entityUID}>
                          {(hide || (opt_in_exchange && num_data_exchange_providers < 2)) ? <Link to="/dataExchange/step3" color="inherit">{entityName}</Link>
                            : <Link to={`/entities/${entityUID}`} color="inherit">{entityName}</Link>}
                        </div>
                      ))}
                    </div>
                  </Grid>
                </Grid>
                <br />
                <Typography variant="body1">
                  <strong>Role:</strong>
                  {' '}
                  {userInfo.role}
                </Typography>
              </List>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </div>
  )
}
